import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TopSection from '../../components/TopSection';
import { sendEmail } from '../../services/usersServives';
import client from '../../utils/client';

function EmailPassword(props) {
  const [email, setEmail] = useState('');

  const [emailError, setEmailError] = useState('');
  const [confirm, setConfirm] = useState(false);

  if (client.token !== undefined) {
    props.history.push('/');
  }

  async function handleSubmit() {
    let reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (email === '' || reg.test(email) === false) {
      if (!email) {
        setEmailError('Email is required');
      } else if (reg.test(email) === false) {
        setEmailError('Email is incorrect');
      } else {
        setEmailError(null);
      }
    } else {
      setEmailError(null);
      submitMessage();
    }
  }
  async function submitMessage() {
    const validationCode = uuidv4();
    const validationCodeDate = new Date();
    sendEmail(email, validationCode, validationCodeDate)
      .then((user) => {
        setEmail('');
        setConfirm(true);
      })
      .catch((error) => {
        if (error.response.data.error) {
          setEmailError(error.response.data.error);
        }
      });
  }

  return (
    <>
      <TopSection heading="Forgot password" />
      <div className="contact">
        <div className="container">
          <div className="row justify-content-center sign-in-box">
            <div className="col-md-8 col-12">
              <div className="php-email-form">
                <div className="form-row">
                  <div>
                    {confirm && (
                      <p className="confirm_details">
                        Please follow the <span>Reset</span> link in your email
                        to update your password.
                      </p>
                    )}
                  </div>
                  <div className="col-12 form-group">
                    <label>
                      Email<i className="required-detail">*</i>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name={email}
                      placeholder="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    {emailError ? (
                      <p className="errorMessage"> {emailError}</p>
                    ) : null}
                  </div>
                </div>

                <div className="">
                  <button onClick={() => handleSubmit()} type="submit">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailPassword;
