import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import client from '../../utils/client';
import { Pagination } from '../../components/pagination/index';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function ViewEachDocumentBank({
  children,
  ui,
  ui2,
  documentBank,
  perPage,
  total,
  ...props
}) {
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * perPage;
  const pageCount = Math.ceil(total / perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="col-sm-12">
      <div className="row">
        {documentBank.length ? (
          <>
            {documentBank
              .slice(pageVisited, pageVisited + perPage)
              .map((details) => (
                <div
                  key={details._id}
                  className="doc_main pgBtSp col-md-12 col-12"
                >
                  <Link
                    to={{
                      pathname: client.token
                        ? `/document-download/${details._id}/${details.subCategory.name}`
                        : '/sign-in',
                      search: `?status=${details.subCategory._id}`,
                      SubCategory: details.subCategory.name,
                    }}
                  >
                    <div className="row doc_sub_main">
                      <div className="col-2">
                        <i className="fas fa-file-alt"></i>
                        <h5>
                          {details.price === 'Free' ? (
                            <span>{details.price}</span>
                          ) : (
                            <span>
                              {details.price ? (
                                <>
                                  {' '}
                                  <sup>{t('dCurrency')}</sup>
                                  {details.price}
                                </>
                              ) : (
                                <span className="doc_free">{t('pFree')}</span>
                              )}
                            </span>
                          )}
                        </h5>
                      </div>
                      <div className="col-10">
                        <h4 className="doc_title">{details.name}</h4>
                        {details.description?.split('\n\n').map((paragraph) => (
                          <p key={paragraph} className="doc_down">
                            {paragraph
                              .split('\n')
                              .reduce((total, line) => [total, <br />, line])}
                          </p>
                        ))}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            <Pagination pageCount={pageCount} changePage={changePage} />
          </>
        ) : (
          <p className="no-record">{t('noRecordMatch')}</p>
        )}
      </div>
    </div>
  );
}

export default withRouter(ViewEachDocumentBank);
