import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { changeUserPassword } from '../../services/usersServives';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function PasswordChange({ children, ui, ...props }) {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const { t } = useTranslation();

  async function handleSubmit() {
    if (
      oldPassword === '' ||
      password === '' ||
      confirmPassword === '' ||
      password !== confirmPassword ||
      password.length < 8
    ) {
      if (oldPassword === '') {
        setOldPasswordError('Old password is required');
      } else {
        setOldPasswordError(null);
      }

      if (password === '') {
        setPasswordError('New password is required');
      } else if (password.length < 8) {
        setPasswordError('Minimum 8 characters');
      } else {
        setPasswordError(null);
      }
      if (confirmPassword === '') {
        setConfirmPasswordError('Confirm password is required');
      } else if (password !== confirmPassword) {
        setConfirmPasswordError('Password confirmation is incorrect');
      } else {
        setConfirmPasswordError(null);
      }
    } else {
      setOldPasswordError(null);
      setPasswordError(null);
      setConfirmPasswordError(null);
      getData();
    }
  }

  async function getData() {
    changeUserPassword(oldPassword, password)
      .then((response) => {
        props.history.push(ui);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
        if (error.response.data.error) {
          setOldPasswordError(error.response.data.error);
        }
        if (error.response.data.errorPassword) {
          setPasswordError(error.response.data.errorPassword);
        }
      });
  }
  return (
    <Page title={t('changePassword')} content={t('dDocumentContent')} href="/">
      <div className="row">
        {children}
        <div className="col-md-9 col-12">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="col-md-8 col-12 form-row user-detail-record">
                  <div className="col-12 form-group form-group">
                    <label>
                      {t('oldPassword')}
                      <i className="required-detail">*</i>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name={oldPassword}
                      placeholder={t('oldPassword')}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    {oldPasswordError ? (
                      <p className="errorMessage"> {oldPasswordError}</p>
                    ) : null}
                  </div>
                  <div className="col-12 form-group form-group">
                    <label>
                      {t('newPassword')}
                      <i className="required-detail">*</i>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name={password}
                      placeholder={t('newPassword')}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {passwordError ? (
                      <p className="errorMessage"> {passwordError}</p>
                    ) : null}
                  </div>
                  <div className="col-12 form-group">
                    <label>
                      {t('confirmPassword')}
                      <i className="required-detail">*</i>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name={confirmPassword}
                      placeholder={t('confirmPassword')}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    {confirmPasswordError ? (
                      <p className="errorMessage"> {confirmPasswordError}</p>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div className="card-title">
                    <Link
                      to="#"
                      onClick={() => handleSubmit()}
                      className="btn-style btn-info mr-2"
                    >
                      {t('changePassword')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(PasswordChange);
