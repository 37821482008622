import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import {
  viewUserProfile,
  updateUserProfile,
} from '../../services/usersServives';
import { SubscriptionNavBar } from './subscriptionNavBar';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function UserUpgradeBilling({
  viewBilling,
  upgradeBilling,
  cancelBilling,
  children,
  ...props
}) {
  const [userDetail, setUserDetail] = useState({
    subscriptionActivate: '',
    cancellationMessage: '',
  });
  const [messageError, setMessageError] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setUserDetail(response.data.user);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);
      const userId = Cookies.get('creadUseDetail');

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (userId === ms) {
          getData();
        }
      });
    }
    clientSocket();
    getData();
  }, [baseURL, props.history]);

  async function cancel() {
    if (!userDetail.cancellationMessage) {
      setMessageError(t('dReasonCancellingRequired'));
      return false;
    } else {
      setMessageError(null);
    }
    if (window.confirm(t('dBillingCancel'))) {
      userDetail.subscriptionActivate = 'cancel';
      updateUserProfile(userDetail)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('user', `${userId}`);
          socket.emit('updateUser', `${userId}`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  function showRecord() {
    if (
      userDetail.subscriptionActivate === 'activate' ||
      userDetail.subscriptionActivate === 'upgradeToBusiness'
    ) {
      return (
        <>
          <div className="col-12 form-row">
            <p>{t('dCancellingMessage')}</p>
          </div>
          <div className="col-12 user-detail-record">
            <label>
              {t('dReasonCancelling')}
              <i className="required-detail">*</i>
            </label>
            <textarea
              className="form-control"
              type="text"
              name={userDetail.cancellationMessage}
              placeholder={t('enterMessage')}
              rows="5"
              value={userDetail.cancellationMessage}
              onChange={(e) =>
                setUserDetail({
                  ...userDetail,
                  cancellationMessage: e.target.value,
                })
              }
            />
            {messageError ? (
              <p className="errorMessage"> {messageError}</p>
            ) : null}
          </div>
          <div className="col-12">
            <div className="card-title">
              <Link
                to="#"
                onClick={() => cancel()}
                className="btn-style btn-danger mr-2"
              >
                {t('dCancelBilling')}
              </Link>
            </div>
          </div>
        </>
      );
    } else if (
      userDetail.subscriptionActivate === 'diactivate' ||
      userDetail.subscriptionActivate === 'cancel' ||
      userDetail.subscriptionActivate === 'terminate'
    ) {
      return (
        <>
          <div>
            <p>
              {`${t('dSubIs')} ${userDetail.subscriptionActivate}. ${t(
                'dSumIsRe'
              )}`}
            </p>
            <Link
              className="btn-style btn-info mr-2"
              to={{
                pathname: `/pricing`,
              }}
            >
              {t('pStartNow')}
            </Link>
          </div>
        </>
      );
    }
  }

  return (
    <Page title={t('dCancelBilling')} content={t('dDocumentContent')} href="/">
      <div className="row">
        {children}
        <div className="col-md-9 col-12">
          <div className="col-12">
            <div className="card">
              <SubscriptionNavBar
                viewBilling={viewBilling}
                upgradeBilling={upgradeBilling}
                cancelBilling={cancelBilling}
              />
              <div className="card-body">{showRecord()}</div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(UserUpgradeBilling);
