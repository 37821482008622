import React, { useEffect, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import FrontEndNavbar from '../navBar/Navbar';
import FrontEndFooter from '../footer/Footer';
import ScrollToTop from '../scrollToTop/ScrollToTop';
import { logout } from '../../services/usersServives';
import client from '../../utils/client';
import ReactGA from 'react-ga';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
const REACT_APP_GA_TRACKING_CODE = process.env.REACT_APP_GA_TRACKING_CODE;
const lawyerExpertCookie = Cookies.get('lawyerExpertCookie');
ReactGA.initialize(REACT_APP_GA_TRACKING_CODE);

function FrontendLayout(props) {
  let token = client.token;
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    //Cookies.remove('lawyerExpertCookie');
  });

  const idleTimerRef = useRef(null);
  const onIdle = () => {
    logout()
      .then((response) => {
        Cookies.remove('token');
        Cookies.remove('creadUseDetail');
        props.history.push('/');
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  };

  async function setCookies() {
    Cookies.set('lawyerExpertCookie', uuidv4(), {});
  }
  return (
    <>
      {token && (
        <IdleTimer
          ref={idleTimerRef}
          timeout={1000 * 60 * 60 * 24}
          onIdle={onIdle}
        ></IdleTimer>
      )}
      <ScrollToTop />
      <FrontEndNavbar />
      {props.children}
      {!lawyerExpertCookie && (
        <CookieConsent
          enableDeclineButton
          debug={true}
          onAccept={() => {
            setCookies();
          }}
          location="bottom"
          declineButtonText="Cancel"
          buttonText="Accept all"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: 'black', fontSize: '13px', background: '#fff' }}
          declineButtonStyle={{
            color: 'black',
            fontSize: '13px',
            background: '#fff',
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{' '}
          {/* <span style={{ fontSize: '10px' }}>
            This bit of text is smaller :O
          </span> */}
        </CookieConsent>
      )}

      <FrontEndFooter />
    </>
  );
}

export default withRouter(FrontendLayout);
