import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AiOutlineCheck } from 'react-icons/ai';
import { BsBoxArrowInDown } from 'react-icons/bs';
import queryString from 'query-string';
import { isBrowser, isMobile } from 'react-device-detect';
import io from 'socket.io-client';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';
import StripeCheckout from 'react-stripe-checkout';
import TopSection from '../../components/TopSection';
import {
  viewEachRecordFrontend,
  viewSubRecord,
  postCheckout,
  addDocumentDownloaded,
} from '../../services/documentBankServives';
import { viewEachUserDocument } from '../../services/userDocumentServives';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function DocumentDownload(props) {
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const [documentBank, setDocumentBank] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState('');
  const [countDocuments, setCountDocument] = useState([]);
  let parsed = queryString.parse(props.location.search);
  const { t } = useTranslation();
  const [product, setProduct] = useState({
    productID: '',
    name: '',
    price: '',
    description: '',
    plan: '',
  });

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 3000);
    async function getEach() {
      viewEachRecordFrontend(props.match.params.id)
        .then((response) => {
          setDocumentBank(response.data);
          setProduct({
            productID: response.data._id,
            name: response.data.name,
            price: response.data.price,
            description: response.data.description,
            plan: 'buying',
          });
        })
        .catch((error) => {
          console.log(error);
        });

      // Check if user has already bought the doucment
      viewEachUserDocument(props.match.params.id)
        .then((res) => {
          setDocument(res.data.documentBank);
        })
        .catch((error) => {
          console.log(error);
        });

      // Get sub categories
      const filter = props.location.search;
      viewSubRecord(filter)
        .then((response) => {
          setCountDocument(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for delete document record
      socket.on('deleteDocument', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push('/document-bank');
        }
      });

      // Socket for delete sub category record
      socket.on('deleteSubCategory', (ms) => {
        if (parsed.status === ms) {
          props.history.push('/document-bank');
        }
      });

      // Socket for getting document record
      socket.on('document', (ms) => {
        getEach();
      });
    }
    clientSocket();
    getEach();
  }, [
    props.match.params,
    isLoading,
    props.history,
    parsed.status,
    props.location.search,
    baseURL,
  ]);

  async function addDocumentDownload() {
    const documentBank = props.match.params.id;
    let device = null;
    const user = '';
    if (isMobile) {
      device = 'Mobile';
    } else if (isBrowser) {
      device = 'Browser';
    }
    addDocumentDownloaded(device, documentBank, user)
      .then((response) => {
        console.log(response);
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('documentDownload', `${userId}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleToken(token) {
    setIsLoading(true);
    postCheckout(token, product)
      .then((response) => {
        props.history.push('/main-view-user-documents');
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Page
      title={t('dDocumentDownload')}
      content={t('dDocumentContent')}
      href="/"
    >
      <TopSection heading={props.match.params.subcategory} />

      <div className="main_container">
        {isLoading && (
          <div className="main_spinner">
            <Button variant="primary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">{t('dLoading')}</span>
            </Button>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              {documentBank && (
                <div className="doc_main">
                  <div className="col-sm-12">
                    <h4 className="doc_title">{documentBank.name}</h4>
                    <div>
                      {documentBank.description
                        ?.split('\n\n')
                        .map((paragraph) => (
                          <p key={paragraph} className="doc_down">
                            {paragraph
                              .split('\n')
                              .reduce((total, line) => [total, <br />, line])}
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="row">
                    {documentBank.price ? (
                      <>
                        <div className="col-md-2 col-3">
                          <h5>
                            <span>
                              <sup>{t('dCurrency')}</sup>
                              {documentBank.price}
                            </span>
                          </h5>
                        </div>
                        <div className="col-md-10 col-9 btn-doc-right">
                          {document ? (
                            <a
                              onClick={() => addDocumentDownload()}
                              className="doc-download"
                              rel="noopener noreferrer"
                              target="_blank"
                              href={`${baseURL}/images/${documentBank.document}`}
                            >
                              <button className="dow-buy">
                                {t('dDownload')}
                                <span className="icon-started">
                                  <BsBoxArrowInDown />
                                </span>
                              </button>
                            </a>
                          ) : (
                            !isLoading && (
                              <StripeCheckout
                                stripeKey={
                                  process.env
                                    .REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
                                }
                                token={handleToken}
                                billingAddress
                                amount={documentBank.price * 100}
                                name={process.name}
                                currency="GBP"
                              >
                                <button className="dow-buy">
                                  {t('dPayDownload')}
                                  <span className="icon-started">
                                    <BsBoxArrowInDown />
                                  </span>
                                </button>
                              </StripeCheckout>
                            )
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-2 col-3">
                          <h5>
                            <span className="doc_free">Free</span>
                          </h5>
                        </div>
                        <div className="col-md-10 col-9 btn-doc-right">
                          <a
                            onClick={() => addDocumentDownload()}
                            className="doc-download"
                            rel="noopener noreferrer"
                            target="_blank"
                            href={`${baseURL}/images/${documentBank.document}`}
                          >
                            <button className="dow-buy">
                              {t('dDownload')}
                              <span className="icon-started">
                                <BsBoxArrowInDown />
                              </span>
                            </button>
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="col-md-4 col-12 down-side">
              <div className="well">
                <div className="block-doc">
                  <h3>{t('dRelatedDocuments')}</h3>
                  {countDocuments.map((details) => (
                    <div key={details._id}>
                      <Link
                        to={{
                          pathname: `/document-download/${details._id}/${details.subCategory.name}`,
                          search: `?status=${details.subCategory._id}`,
                          SubCategory: details.subCategory.name,
                        }}
                        onClick={() => {
                          window.location.href = `/document-download/${details._id}/${details.subCategory.name}?status=${details.subCategory._id}`;
                        }}
                      >
                        <p>
                          {' '}
                          <span>
                            <AiOutlineCheck />
                          </span>{' '}
                          {details.name}
                        </p>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(DocumentDownload);
