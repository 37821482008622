import client from '../utils/client';

/**
 * Add service
 */
export async function addRecord(name) {
  return await client.post(`/categories/`, {
    name: name,
  });
}

/**
 * View  service
 */
export async function viewAllRecordCategory() {
  return await client.get(`/categories/`, {});
}

/**
 * View each service
 */
export async function viewEachRecordCategory(id) {
  return await client.get(`/categories/${id}`, {});
}

/**
 * Update service
 */
export async function updateRecord(name, id) {
  return await client.put(`/categories/${id}`, {
    name: name,
  });
}

/**
 * Delete service
 */
export async function deleteRecord(id) {
  return await client.remove(`/categories/${id}`, {});
}
