import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { viewUserProfile } from '../../services/usersServives';
import Cookies from 'js-cookie';

export function SubscriptionNavBar({
  viewBilling,
  upgradeBilling,
  cancelBilling,
  ...props
}) {
  const [user, setUser] = useState({});

  useEffect(() => {
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setUser(response.data.user);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }

    getData();
  }, [props.history]);
  return (
    <div className="card-header">
      <div className="card-title">
        <NavLink
          className="btn-style btn-info mr-2 disabled-links"
          activeClassName="selectedProfile"
          to={{ pathname: viewBilling }}
        >
          View
        </NavLink>
        {user.subscriptionActivate ? (
          <>
            <NavLink
              className="btn-style btn-info mr-2"
              to={{ pathname: upgradeBilling }}
            >
              Upgrade
            </NavLink>
            <NavLink
              className="btn-style btn-info mr-2"
              to={{ pathname: cancelBilling }}
            >
              Cancel
            </NavLink>
          </>
        ) : null}
      </div>
    </div>
  );
}
