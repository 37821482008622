import React from 'react';
import SidebarProfile from './SideBarProfile';
import ViewProfileDetails from '../../components/user-profile/view-details';
import TopSection from '../../components/TopSection';

function MainProfile() {
  return (
    <>
      <TopSection heading="Profile" />
      <div className="main_container about-us about-top">
        <div className="container">
          <ViewProfileDetails
            epr="/profile"
            edpr="/profile-edit-user"
            epic="/main-edit-user-profile-picture"
            elog="/main-edit-user-company-logo"
          >
            <SidebarProfile />
          </ViewProfileDetails>
        </div>
      </div>
    </>
  );
}

export default MainProfile;
