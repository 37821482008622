import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import TopSection from '../../components/TopSection';
import { login } from '../../services/usersServives';
import Cookies from 'js-cookie';
import client from '../../utils/client';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function SignIn(props) {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [errorMessage, setErrorMessager] = useState('');

  let parsed = queryString.parse(props.location.search);
  let redirectTo = parsed.redirectTo;

  if (client.token !== undefined) {
    props.history.push('/profile');
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (email === '' || password === '' || reg.test(email) === false) {
      if (!email) {
        setEmailError(t('emailRequired'));
        setErrorMessager(null);
      } else if (reg.test(email) === false) {
        setEmailError(t('wrongEmail'));
        setErrorMessager(null);
      } else {
        setEmailError(null);
        setErrorMessager(null);
      }

      if (!password) {
        setPasswordError(t('passwordRequired'));
        setErrorMessager(null);
      } else {
        setPasswordError(null);
        setErrorMessager(null);
      }
    } else {
      setEmailError(null);
      setPasswordError(null);
      setErrorMessager(null);
      submitMessage();
    }
  }
  async function submitMessage() {
    login(email, password)
      .then((user) => {
        Cookies.set('token', user.data.token, {
          //httpOnly: true,
          //domain: 'localhost:3000/',
        });
        Cookies.set('creadUseDetail', user.data.user._id, {
          //httpOnly: true,
          //domain: 'localhost:3000/',
        });
        if (redirectTo) {
          props.history.push(redirectTo);
          window.location.reload();
        } else {
          window.location.reload();
        }
      })
      .catch((error) => {
        setErrorMessager(t('wrongCredential'));
      });
  }

  return (
    <Page title={t('signInTitle')} content={t('signInContent')} href="/sign-in">
      <TopSection heading={t('signInTitle')} />
      <div className="main_container contact">
        <div className="container">
          <div className="row justify-content-center sign-in-box">
            <div className="col-md-7 col-12">
              <form className="commentForm" onSubmit={handleSubmit}>
                <div className="php-email-form">
                  <div className="form-row">
                    <div className="col-md-12 col-12 form-group">
                      <label>
                        {t('email')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        name={email}
                        placeholder={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      {emailError ? (
                        <p className="errorMessage"> {emailError}</p>
                      ) : null}
                    </div>

                    <div className="col-md-12 col-12 form-group">
                      <label>
                        {t('password')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        name={password}
                        placeholder={t('password')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      {passwordError ? (
                        <p className="errorMessage"> {passwordError}</p>
                      ) : null}
                      {errorMessage ? (
                        <p className="errorMessage"> {errorMessage}</p>
                      ) : null}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-6 forgot-password">
                      <Link to="/forgot-password-email">
                        {t('forgotPassword')}
                      </Link>
                    </div>
                    <div className="col-6 btn-text-right">
                      <button type="submit">{t('signInTitle')}</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-5 col-12">
              <div className="php-email-form user-create-account">
                <h4>{t('createAccount')}</h4>
                <p>{t('fSignForFree')}</p>
                <div className="btn-text-align">
                  <Link
                    to={{
                      pathname: '/sign-up',
                      search: redirectTo ? `?redirectTo=${redirectTo}` : null,
                    }}
                  >
                    {' '}
                    <button type="submit">{t('signUp')}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default SignIn;
