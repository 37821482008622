import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/translation.js';
import bg from './bg/translation.js';
console.log(en);

export function localisations() {
  const languageDisplay = process.env.REACT_APP_LANGUAGE_DISPLAY;
  // function getlanguageDisplay() {
  //   if (languageDisplay === 'english') {
  //     return 'en';
  //   } else if (languageDisplay === 'bulgaria') {
  //     return 'bg';
  //   } else {
  //     return 'en';
  //   }
  // }
  const options = {
    // order and from where user language should be detected
    order: ['navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,

    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' },
  };
  // console.log(options);

  i18n
    .use(initReactI18next)
    // .use(LanguageDetector)
    .init({
      fallbackLng: languageDisplay,
      // lng: 'en',
      detection: options,
      resources: {
        en: {
          translations: en,
        },
        bg: {
          translations: bg,
        },
      },
      ns: ['translations'],
      defaultNS: 'translations',
    });
}
