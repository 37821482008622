import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { updateUsers, viewEachUser } from '../../services/usersServives';
import { viewAllRecord } from '../../services/rolesServives';

export default function EditUser(props) {
  const [userDetail, setUserDetail] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    status: '',
    tel: '',
    mobilePhone: '',
    website: '',
    youtubeLink: '',
    linkedIn: '',
    description: '',
    country: '',
    city: '',
    county: '',
    address: '',
    postCode: '',
    action: '',
    role: {
      role: '',
    },
  });
  const [roles, setRoles] = useState([]);

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [youtubeError, setYoutubeError] = useState('');
  const [linkedInError, setLinkedInError] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      viewEachUser(id)
        .then((response) => {
          setUserDetail(response.data);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for delete user record
      socket.on('deleteUser', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push('/cms/users');
        }
      });

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (props.match.params.id === ms) {
          getEach();
        }
      });
    }
    clientSocket();
    getEach();
    getRoles();
  }, [props.match.params.id]);

  async function handleSubmit() {
    let reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let isNum = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    let linkUrl =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    let phoneParsed = parsePhoneNumberFromString(userDetail.mobilePhone, '');
    let telParsed = parsePhoneNumberFromString(userDetail.tel, '');

    if (
      userDetail.firstName === '' ||
      userDetail.lastName === '' ||
      userDetail.email === '' ||
      (userDetail.mobilePhone && (!phoneParsed || !phoneParsed.isValid())) ||
      (userDetail.tel && (!telParsed || !telParsed.isValid())) ||
      (userDetail.mobilePhone &&
        isNum.test(userDetail.mobilePhone) === false) ||
      (userDetail.tel && isNum.test(userDetail.tel) === false) ||
      (userDetail.youtubeLink &&
        linkUrl.test(userDetail.youtubeLink) === false) ||
      (userDetail.linkedIn && linkUrl.test(userDetail.linkedIn) === false) ||
      (userDetail.website && linkUrl.test(userDetail.website) === false) ||
      reg.test(userDetail.email) === false
    ) {
      if (userDetail.firstName === '') {
        setFirstNameError('First name is required');
      } else {
        setFirstNameError(null);
      }
      if (userDetail.lastName === '') {
        setLastNameError('Last name is required');
      } else {
        setLastNameError(null);
      }

      if (userDetail.email === '') {
        setEmailError('Email is required');
      } else if (reg.test(userDetail.email) === false) {
        setEmailError('Email is incorrect');
      } else {
        setEmailError(null);
      }

      if (userDetail.mobilePhone && (!phoneParsed || !phoneParsed.isValid())) {
        setMobileError(
          'Please enter a valid phone number including country code (e.g. +44)'
        );
      } else if (
        userDetail.mobilePhone &&
        isNum.test(userDetail.mobilePhone) === false
      ) {
        setMobileError('Please enter a valid phone number');
      } else {
        setMobileError(null);
      }
      if (userDetail.tel && (!telParsed || !telParsed.isValid())) {
        setPhoneError(
          'Please enter a valid phone number including country code (e.g. +44)'
        );
      } else if (userDetail.tel && isNum.test(userDetail.tel) === false) {
        setPhoneError('Please enter a valid phone number');
      } else {
        setPhoneError(null);
      }
      if (
        userDetail.youtubeLink &&
        linkUrl.test(userDetail.youtubeLink) === false
      ) {
        setYoutubeError('Please enter a valid link');
      } else {
        setYoutubeError(null);
      }
      if (userDetail.linkedIn && linkUrl.test(userDetail.linkedIn) === false) {
        setLinkedInError('Please enter a valid link');
      } else {
        setLinkedInError(null);
      }
      if (userDetail.website && linkUrl.test(userDetail.website) === false) {
        setWebsiteError('Please enter a valid link');
      } else {
        setWebsiteError(null);
      }
    } else {
      setFirstNameError(null);
      setLastNameError(null);
      setEmailError(null);
      setMobileError(null);
      setYoutubeError(null);
      setWebsiteError(null);
      setLinkedInError(null);
      editData();
    }
  }

  async function editData() {
    const id = props.match.params.id;
    updateUsers(userDetail, id)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('user', `${id}`);
        socket.emit('updateUser', `${id}`);
        props.history.push('/cms/users');
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
        if (error.response.data.error) {
          setEmailError(error.response.data.error);
        }
      });
  }

  async function getRoles() {
    viewAllRecord()
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Edit user</h1>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <Link to="/cms/users" className="btn btn-info mr-2">
                        View users
                      </Link>
                    </h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="col-12 user-detail-record">
                          <div className="row form-row">
                            <div className="detail-title col-12">
                              <h5>Pesonal detail</h5>
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <label>Company Name</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.companyName}
                                placeholder="Company name"
                                value={userDetail.companyName}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    companyName: e.target.value,
                                  })
                                }
                              />
                            </div>

                            <div className="col-6 form-group">
                              <label>
                                First Name<i className="required-detail">*</i>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.firstName}
                                placeholder="First name"
                                value={userDetail.firstName}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    firstName: e.target.value,
                                  })
                                }
                              />
                              {firstNameError ? (
                                <p className="errorMessage">
                                  {' '}
                                  {firstNameError}
                                </p>
                              ) : null}
                            </div>
                            <div className="col-6 form-group">
                              <label>
                                Last Name<i className="required-detail">*</i>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.lastName}
                                placeholder="Last name"
                                value={userDetail.lastName}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    lastName: e.target.value,
                                  })
                                }
                              />
                              {lastNameError ? (
                                <p className="errorMessage"> {lastNameError}</p>
                              ) : null}
                            </div>
                            <div className="col-6 form-group">
                              <label>
                                Email<i className="required-detail">*</i>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.email}
                                placeholder="Email"
                                value={userDetail.email}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    email: e.target.value,
                                  })
                                }
                              />
                              {emailError ? (
                                <p className="errorMessage"> {emailError}</p>
                              ) : null}
                            </div>
                            <div className="col-6 form-group">
                              <label>
                                Telephone<i> eg: +442189652501</i>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.tel}
                                placeholder="Telephone"
                                value={userDetail.tel}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    tel: e.target.value,
                                  })
                                }
                              />
                              {phoneError ? (
                                <p className="errorMessage"> {phoneError}</p>
                              ) : null}
                            </div>
                            <div className="col-6 form-group">
                              <label>
                                Mobile phone<i>eg: +447589652501</i>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.mobilePhone}
                                placeholder="Mobile phone"
                                value={userDetail.mobilePhone}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    mobilePhone: e.target.value,
                                  })
                                }
                              />
                              {mobileError ? (
                                <p className="errorMessage"> {mobileError}</p>
                              ) : null}
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <label>Status</label>
                              <select
                                className="form-control"
                                name={userDetail.status}
                                value={userDetail.status}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    status: e.target.value,
                                  })
                                }
                              >
                                <option value="active">active</option>
                                <option value="inactive">inactive</option>
                              </select>
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <label>
                                Gender<i className="required-detail">*</i>
                              </label>
                              <select
                                className="form-control"
                                name={userDetail.gender}
                                value={userDetail.gender}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    gender: e.target.value,
                                  })
                                }
                              >
                                <option value="">--select gender---</option>
                                <option value="Male">Male</option>
                                <option value="Femalle">Femalle</option>
                              </select>
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <label>
                                Account type<i className="required-detail">*</i>
                              </label>
                              <select
                                className="form-control"
                                name={userDetail.role}
                                value={userDetail.role}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    role: e.target.value,
                                  })
                                }
                              >
                                <option value={userDetail.role._id}>
                                  {userDetail.role.name}
                                </option>
                                {roles.map((detail) => (
                                  <option key={detail._id} value={detail._id}>
                                    {detail.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="row form-row">
                            <div className="detail-title col-12">
                              <h5>Address detail</h5>
                            </div>
                            <div className="col-6 form-group">
                              <label>Country</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.country}
                                placeholder="Country"
                                value={userDetail.country}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    country: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label>Address</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.address}
                                placeholder="Address"
                                value={userDetail.address}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    address: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label>City</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.city}
                                placeholder="City"
                                value={userDetail.city}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    city: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label>County</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.county}
                                placeholder="County"
                                value={userDetail.county}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    county: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label>Post code</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.postCode}
                                placeholder="Post code"
                                value={userDetail.postCode}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    postCode: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div className="row form-row">
                            <div className="detail-title col-12">
                              <h5>Additional information</h5>
                            </div>
                            <div className="col-6 form-group">
                              <label>Website</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.website}
                                placeholder="Website"
                                value={userDetail.website}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    website: e.target.value,
                                  })
                                }
                              />
                              {websiteError ? (
                                <p className="errorMessage"> {websiteError}</p>
                              ) : null}
                            </div>
                            <div className="col-6 form-group">
                              <label>Youtube</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.youtubeLink}
                                placeholder="Youtube"
                                value={userDetail.youtubeLink}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    youtubeLink: e.target.value,
                                  })
                                }
                              />
                              {youtubeError ? (
                                <p className="errorMessage"> {youtubeError}</p>
                              ) : null}
                            </div>
                            <div className="col-6 form-group">
                              <label>LinkedIn</label>
                              <input
                                className="form-control"
                                type="text"
                                name={userDetail.linkedIn}
                                placeholder="LinkedIn"
                                value={userDetail.linkedIn}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    linkedIn: e.target.value,
                                  })
                                }
                              />
                              {linkedInError ? (
                                <p className="errorMessage"> {linkedInError}</p>
                              ) : null}
                            </div>
                            <div className="col-12 form-group">
                              <label>Profile summary</label>
                              <textarea
                                className="form-control"
                                type="text"
                                rows="5"
                                name={userDetail.description}
                                placeholder="description"
                                value={userDetail.description}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    description: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card-header">
                          <h3 className="card-title">
                            <Link
                              to="#"
                              onClick={() => handleSubmit()}
                              className="btn btn-info mr-2"
                            >
                              Update Record
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
