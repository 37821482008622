import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import TopSection from '../../components/TopSection';
import {
  resetPassword,
  checkForgotPasswordConfirm,
} from '../../services/usersServives';
import client from '../../utils/client';

function ResetPassword(props) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [user, setUser] = useState('');

  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  let parsed = queryString.parse(props.location.search);
  let email = parsed.email;
  let validationCode = parsed.code;

  if (client.token !== undefined) {
    props.history.push('/');
  }
  useEffect(() => {
    checkForgotPasswordConfirm(email, validationCode)
      .then((user) => {
        console.log(user.data.validationCode);
        setUser(user.data.validationCode);
      })
      .catch((error) => {
        // props.history.push('/forgot-password-email');
        // window.location.reload();
      });
  }, []);

  async function handleSubmit() {
    if (
      password === '' ||
      confirmPassword === '' ||
      password !== confirmPassword ||
      password.length < 8
    ) {
      if (password === '') {
        setPasswordError('password is required');
      } else if (password.length < 8) {
        setPasswordError('minimum 8 characters');
      } else {
        setPasswordError(null);
      }
      if (confirmPassword === '') {
        setConfirmPasswordError('confirm password is required');
      } else if (password !== confirmPassword) {
        setConfirmPasswordError('Password confirmation is incorrect');
      } else {
        setConfirmPasswordError(null);
      }
    } else {
      setPasswordError(null);
      setConfirmPasswordError(null);
      passwordReset();
    }
  }
  async function passwordReset() {
    resetPassword(email, validationCode, password)
      .then((user) => {
        props.history.push('/sign-in');
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data.error) {
          setConfirmPasswordError(error.response.data.error);
        }
      });
  }

  function displayInfo() {
    if (user) {
      return (
        <div className="php-email-form">
          <div className="form-row">
            <div className="col-12 form-group">
              <label>
                Password<i className="required-detail">*</i>
              </label>
              <input
                className="form-control"
                type="password"
                name={password}
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {passwordError ? (
                <p className="errorMessage"> {passwordError}</p>
              ) : null}
            </div>
            <div className="col-12 form-group">
              <label>
                Password Verification<i className="required-detail">*</i>
              </label>
              <input
                className="form-control"
                type="password"
                name={confirmPassword}
                placeholder=" Password Verification"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              {confirmPasswordError ? (
                <p className="errorMessage"> {confirmPasswordError}</p>
              ) : null}
            </div>
          </div>
          <div className="btn-text-alig">
            <button onClick={() => handleSubmit()} type="submit">
              Submit
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="php-email-form reset-code">
          <div className="">
            <p>
              Your validation code has expired, please click on the reset button
              to request new code.
            </p>
          </div>
          <div className="btn-text-alig">
            <button
              onClick={() => {
                window.location = `/forgot-password-email`;
              }}
              type="submit"
            >
              Reset code
            </button>
          </div>
        </div>
      );
    }
  }
  return (
    <>
      <TopSection heading="Reset password" />
      <div className="contact">
        <div className="container">
          <div className="row justify-content-center sign-in-box">
            <div className="col-md-8 col-12">{displayInfo()}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
