import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import io from 'socket.io-client';
import TopSection from '../../components/TopSection';
import { viewEachLawyer } from '../../services/usersServives';
import { addRecord } from '../../services/contactServives';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function Connect(props) {
  const { t } = useTranslation();
  const [userEmail, setUserName] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  const [nameError, setNameError] = useState('');
  const [numberError, setNumberError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  async function handleSubmit(event) {
    event.preventDefault();
    let reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (
      email === '' ||
      name === '' ||
      number === '' ||
      subject === '' ||
      message === '' ||
      reg.test(email) === false
    ) {
      if (!email) {
        setEmailError(t('emailRequired'));
      } else if (reg.test(email) === false) {
        setEmailError(t('wrongEmail'));
      } else {
        setEmailError(null);
      }

      if (!name) {
        setNameError(t('fullNameRequired'));
      } else {
        setNameError(null);
      }
      if (!number) {
        setNumberError(t('telNumberRequired'));
      } else {
        setNumberError(null);
      }
      if (!subject) {
        setSubjectError(t('subjectRequired'));
      } else {
        setSubjectError(null);
      }
      if (!message) {
        setMessageError(t('messageRequired'));
      } else {
        setMessageError(null);
      }
    } else {
      setEmailError(null);
      setNameError(null);
      setNumberError(null);
      setSubjectError(null);
      setMessageError(null);
      submitMessage();
    }
  }

  async function submitMessage() {
    setShow(true);
    const type = 'connect';
    addRecord(name, email, number, subject, message, type, userEmail)
      .then((response) => {
        const socket = io(baseURL);
        socket.emit('contact', `Add connect`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      viewEachLawyer(id)
        .then((response) => {
          setUserName(response.data.email);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getEach();
  }, [props.match.params.id]);

  return (
    <Page title={t('connectTitle')} href="/" content={t('connectContent')}>
      <TopSection heading={t('connectHeading')} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('message')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('successMessage')}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('ok')}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="main_container contact">
        <div className="container">
          <div className="row justify-content-center">
            <div>
              <p>{t('connectMessage')}</p>
            </div>
            <form className="commentForm" onSubmit={handleSubmit}>
              <div className="row mt-5 justify-content-center">
                <div className="col-lg-10 php-email-form">
                  <div className="form-row">
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('fullName')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={name}
                        placeholder={t('fullName')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      {nameError ? (
                        <p className="errorMessage"> {nameError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('telNumber')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={number}
                        placeholder={t('telNumber')}
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />

                      {numberError ? (
                        <p className="errorMessage"> {numberError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('subject')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={subject}
                        placeholder={t('subject')}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />

                      {subjectError ? (
                        <p className="errorMessage"> {subjectError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('email')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        name={email}
                        placeholder={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      {emailError ? (
                        <p className="errorMessage"> {emailError}</p>
                      ) : null}
                    </div>
                    <div className="col-12 form-group">
                      <label>
                        {t('message')}
                        <i className="required-detail">*</i>
                      </label>
                      <textarea
                        className="form-control"
                        type="text"
                        rows="5"
                        name={message}
                        placeholder={t('enterMessage')}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />

                      {messageError ? (
                        <p className="errorMessage"> {messageError}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="btn-text-align">
                    <button type="submit"> {t('sendMessage')}</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Connect;
