import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { viewUserProfile } from '../../services/usersServives';
import Cookies from 'js-cookie';

export function ProfileNavBar({ epr, edpr, epic, elog, ...props }) {
  const [role, setRole] = useState({});

  useEffect(() => {
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setRole(response.data.user.role);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }

    getData();
  }, [props.history]);
  return (
    <div className="card-header">
      <div className="card-title">
        <NavLink
          className="btn-style btn-info mr-2 disabled-links"
          activeClassName="selectedProfile"
          to={{ pathname: epr }}
        >
          View
        </NavLink>
        <NavLink className="btn-style btn-info mr-2" to={{ pathname: edpr }}>
          Edit
        </NavLink>
        <NavLink className="btn-style btn-info mr-2" to={{ pathname: epic }}>
          Picture
        </NavLink>
        {(role._id === '6044119e0079f15a6cd050f7' ||
          role._id === '604411b80079f15a6cd050f8') && (
          <NavLink className="btn-style btn-info mr-2" to={{ pathname: elog }}>
            Logo
          </NavLink>
        )}
      </div>
    </div>
  );
}
