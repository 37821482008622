import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { viewUsers, deleteUser } from '../../services/usersServives';
import { viewAllRecord } from '../../services/rolesServives';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Form, Formik } from 'formik';
import { Pagination } from '../../components/pagination/index';

export default function ViewUers(props) {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('');
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * page.perPage;
  const pageCount = Math.ceil(page.total / page.perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    async function getRole() {
      viewAllRecord()
        .then((response) => {
          setRoles(response.data);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function getData() {
      const filter = props.location.search;
      viewUsers(filter)
        .then((response) => {
          setUsers(response.data.users);
          setPage(response.data);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting users record
      socket.on('user', (ms) => {
        getData();
      });

      // Socket for update role record
      socket.on('role', (ms) => {
        getData();
        getRole();
      });
    }
    clientSocket();
    getData();
    getRole();
  }, [baseURL, props.history, props.location.search]);

  function handleSubmit(values) {
    props.history.push(
      `/cms/users?filter=${values.filter}&status=${values.status}&role=${values.role}&ujeugeuhegghghrghrrhrurhruogjeheengeuge`
    );
    //getData();
  }

  async function deleteData(id) {
    if (
      window.confirm(
        'Are you sure you want to delete this user? This will also remove all document bank and document bank purchased by this user and cannot be undone.'
      )
    ) {
      deleteUser(id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('user', `${userId}`);
          socket.emit('deleteUser', `${id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-3">
                <h1>Users List</h1>
              </div>
              <div className="col-9 search-side">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    filter: filter || '',
                    status: status || '',
                    role: role || '',
                  }}
                  enableReinitialize={true}
                >
                  {({ submitForm }) => (
                    <Form className="searchForm">
                      <select
                        className="input"
                        name="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        onChangeCapture={submitForm}
                      >
                        <option value="">Select status</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                      <select
                        className="input"
                        name="role"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        onChangeCapture={submitForm}
                      >
                        <option value="">Select account type</option>
                        {roles.map((role) => (
                          <option key={role._id} value={role._id}>
                            {role.name}
                          </option>
                        ))}
                      </select>
                      <input
                        type="text"
                        name="filter"
                        id="filter"
                        placeholder="Search user..."
                        className="input"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                      <button className="submit submitEsp" type="submit">
                        Search
                      </button>

                      <Link
                        className="clear"
                        to="/cms/users"
                        onClick={() => {
                          window.location.href = `/cms/users`;
                        }}
                      >
                        Clear Filter
                      </Link>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* <div className="card-header">
                    <h3 className="card-title">
                      <Link to="/add-user" className="btn btn-info mr-2">
                        Add New User
                      </Link>
                    </h3>
                  </div> */}
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-hover"
                    >
                      <thead className="headerTable">
                        <tr>
                          <th>Full Name</th>
                          <th>Email</th>
                          <th>Status</th>
                          <th>Account type</th>
                          <th colSpan="3" className="col-span">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users
                          .slice(pageVisited, pageVisited + page.perPage)
                          .map((user) => (
                            <tr key={user._id}>
                              <td>{user.firstName + ' ' + user.lastName}</td>
                              <td>{user.email}</td>
                              <td>{user.status}</td>
                              <td>{user.role.name}</td>
                              <td className="col-span">
                                <Link
                                  className="btn btn-secondary mr-2"
                                  to={`/cms-each-user/${user._id}`}
                                >
                                  View
                                </Link>
                              </td>
                              <td className="col-span">
                                <Link
                                  className="btn btn-primary mr-2"
                                  to={`/cms-edit-user/${user._id}`}
                                >
                                  Edit
                                </Link>
                              </td>
                              <td className="col-span">
                                <Link
                                  to="#"
                                  className="btn btn-danger mr-2 disabled-lin"
                                  onClick={() => deleteData(user._id)}
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination pageCount={pageCount} changePage={changePage} />
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
