import React from 'react';
import TopSection from '../TopSection';

function LegalMaketing() {
  return (
    <>
      <TopSection heading="Legal Marketing" />
      <div className="main_containerssss">
        <div className="main_container team section-bg">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>Legal Maketing</h2>
              <p>
                Our team ensure your network is running at the best frequency
                and network layer, which ensure the business process of data
                storage.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalMaketing;
