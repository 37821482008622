import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
// import 'react-confirm-alert/src/react-confirm-alert.css';
import {
  viewAllRecord,
  deleteRecord,
} from '../../services/subCategoriesServives';

function SubCategories(props) {
  const [subCategories, setSubCategories] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting sub document record
      socket.on('subCategory', (ms) => {
        getAll();
      });

      socket.on('category', (ms) => {
        getAll();
      });
    }
    clientSocket();
    getAll();
  }, []);

  async function getAll() {
    viewAllRecord()
      .then((response) => {
        setSubCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function deleteData(id) {
    if (
      window.confirm(
        'Are you sure you want to delete this sub category? This will also remove all  document bank and document bank purchased by users that associated to this category and cannot be undone.'
      )
    ) {
      deleteRecord(id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('subCategory', `${userId}`);
          socket.emit('deleteSubCategory', `${id}`);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
  }

  return (
    <div className="col-md-6 col-12">
      <div className="card">
        {/* /.card-header */}
        <div className="card-body">
          <table id="example2" className="table table-bordered table-hover">
            <thead className="headerTable">
              <tr>
                <th>Categories</th>
                <th>Sub Categories</th>
                <th colSpan="2" className="col-span">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {subCategories.map((detail) => (
                <tr key={detail._id}>
                  <td>{detail.category.name}</td>
                  <td>{detail.name}</td>
                  <td className="col-span">
                    <Link
                      className="btn btn-primary mr-2"
                      to={{
                        pathname: `/cms-sub-categories-edit/${detail._id}`,
                      }}
                      onClick={() => {
                        window.location.href = `/cms-sub-categories-edit/${detail._id}`;
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                  <td className="col-span">
                    {' '}
                    <Link
                      to="#"
                      onClick={() => deleteData(detail._id)}
                      className="btn btn-danger mr-2 disabled-lin"
                    >
                      Delete
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* /.card-body */}
      </div>
      {/* /.card */}
      {/* /.card */}
    </div>
  );
}

export default withRouter(SubCategories);
