import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import {
  updateUsers,
  viewEachUser,
  viewUserProfile,
} from '../../services/usersServives';

export default function EditUser(props) {
  const [userDetail, setUserDetail] = useState({
    billing: '',
    subscription: '',
    subscriptionActivate: '',
    email: '',
    confirmation: '',
    messageToUser: '',
  });
  const [name, setName] = useState('');
  const [user, setUser] = useState('');
  const [confirmationError, setConfirmationError] = useState('');
  const [messageToUserError, setMessageToUserError] = useState('');
  const [statusError, setStatusNameError] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      viewEachUser(id)
        .then((response) => {
          setUserDetail(response.data);
          setName(response.data.firstName + ' ' + response.data.lastName);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });

      viewUserProfile()
        .then((response) => {
          setUser(
            response.data.user.firstName + ' ' + response.data.user.lastName
          );
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for delete user record
      socket.on('deleteUser', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push('/cms/users');
        }
      });

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (props.match.params.id === ms) {
          getEach();
        }
      });
    }
    clientSocket();
    getEach();
  }, [props.match.params.id]);

  async function editData() {
    if (
      userDetail.confirmation === '' ||
      userDetail.confirmation === undefined ||
      userDetail.messageToUser === '' ||
      userDetail.messageToUser === undefined
    ) {
      if (
        userDetail.confirmation === '' ||
        userDetail.confirmation === undefined
      ) {
        setConfirmationError(
          'Please select YES if you really you to update this record!'
        );
      } else {
        setConfirmationError(null);
      }
      if (
        userDetail.messageToUser === '' ||
        userDetail.messageToUser === undefined
      ) {
        setMessageToUserError('This field is required');
      } else {
        setMessageToUserError(null);
      }

      return true;
    } else {
      setConfirmationError(null);
      setMessageToUserError(null);
    }

    if (
      window.confirm(
        'Are you sure you want to update this user billing record?'
      )
    ) {
      const id = props.match.params.id;
      updateUsers(userDetail, id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('user', `${id}`);
          socket.emit('updateUser', `${id}`);
          props.history.push('/cms/users-billing');
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
          if (error.response.data.error) {
            setStatusNameError(error.response.data.error);
          }
        });
    }
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Edit user billing type</h1>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <Link
                        to="/cms/users-billing"
                        className="btn btn-info mr-2"
                      >
                        View users billing
                      </Link>
                    </h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="col-12 user-detail-record">
                          <div className="row form-row">
                            <div className="detail-title billing-up col-12">
                              <p>
                                Dear <b>{user}</b>, any record you update in
                                this page will be affected to <b>{name}</b>{' '}
                                billing details. So make sure you are updating
                                the right user billing information. Also we
                                recommend you updating <b>{name}</b> stripe
                                account first before any action on this page.
                              </p>
                            </div>

                            <div className="col-md-6 col-12 form-group">
                              <label>
                                Billing Type<i className="required-detail">*</i>
                              </label>
                              <select
                                className="form-control"
                                name={userDetail.billing}
                                value={userDetail.billing}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    billing: e.target.value,
                                  })
                                }
                              >
                                <option value="monthly">Monthly</option>
                                <option value="annual">Annual</option>
                              </select>
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <label>
                                Subscription<i className="required-detail">*</i>
                              </label>
                              <select
                                className="form-control"
                                name={userDetail.subscription}
                                value={userDetail.subscription}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    subscription: e.target.value,
                                  })
                                }
                              >
                                <option value="pro">Pro</option>
                                <option value="business">Business</option>
                              </select>
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <label>
                                Status<i className="required-detail">*</i>
                              </label>
                              <select
                                className="form-control"
                                name={userDetail.subscriptionActivate}
                                value={userDetail.subscriptionActivate}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    subscriptionActivate: e.target.value,
                                  })
                                }
                              >
                                <option value="activate">Activate</option>
                                <option value="diactivate">Diactivate</option>
                                <option value="cancel">Cancel</option>
                                <option value="terminate">Terminate</option>
                                <option value="upgradeToBusiness">
                                  Upgrade To Business
                                </option>
                              </select>

                              {statusError ? (
                                <p className="errorMessage"> {statusError}</p>
                              ) : null}
                            </div>
                            <div className="col-md-6 col-12 form-group">
                              <label>
                                Confirmation<i className="required-detail">*</i>
                              </label>
                              <select
                                className="form-control"
                                name={userDetail.confirmation}
                                value={userDetail.confirmation}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    confirmation: e.target.value,
                                  })
                                }
                              >
                                <option value="">Please confirm</option>
                                <option value="yes">YES</option>
                              </select>

                              {confirmationError ? (
                                <p className="errorMessage">
                                  {' '}
                                  {confirmationError}
                                </p>
                              ) : null}
                            </div>
                            <div className="col-12 form-group">
                              <label>
                                Description<i className="required-detail">*</i>
                              </label>
                              <textarea
                                className="form-control"
                                type="text"
                                rows="5"
                                name={userDetail.messageToUser}
                                placeholder="Description"
                                value={userDetail.messageToUser}
                                onChange={(e) =>
                                  setUserDetail({
                                    ...userDetail,
                                    messageToUser: e.target.value,
                                  })
                                }
                              />
                              {messageToUserError ? (
                                <p className="errorMessage">
                                  {' '}
                                  {messageToUserError}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="card-header">
                          <h3 className="card-title">
                            <Link
                              to="#"
                              onClick={() => editData()}
                              className="btn btn-info mr-2"
                            >
                              Update Record
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
