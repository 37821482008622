import React from 'react';
import { Form, Formik } from 'formik';
import { AiOutlineCheck } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function SideDocument({
  onSubmit,
  filter,
  initialValues,
  onChange,
  onClick,
  to,
  countDocuments,
  placeholder,
  className = null,
  classNameInvisible = null,
}) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <div className="well">
        <div className="cont-search">
          <p>{t('sRequestMessage')}</p>
          <Link to="/contact">
            <button className="btn-global-small btn-space-bt">
              {t('sRequestDocument')} <i className="fas fa-angle-right"></i>
            </button>
          </Link>
          <h3>{t('sSearch')}</h3>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({ submitForm }) => (
              <Form className="searchFor">
                <div className="search-document">
                  <input
                    type="text"
                    name="filter"
                    placeholder={placeholder}
                    className="input"
                    value={filter}
                    onChange={onChange}
                  />
                  <button className="submit" type="submit">
                    <i className="nav-icon fas fa-search" />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <p className={classNameInvisible}>
            <Link className="clear" to={to} onClick={onClick}>
              <span className="clear-input"> {t('sClearInput')}</span>
            </Link>
          </p>
        </div>

        <div className="block-doc">
          <h3>{t('sDocumentBank')}</h3>
          {countDocuments.map((details) => (
            <div key={details._id}>
              <Link
                to={{
                  pathname: `/sub-documents/${details.name}`,
                  search: `?status=${details._id}`,
                }}
              >
                <p>
                  <span>
                    <AiOutlineCheck />
                  </span>{' '}
                  {`${details.name} (${details.count})`}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SideDocument;
