import React, { useState, useEffect } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import {
  logout,
  viewUserProfile,
  viewImage,
} from '../../services/usersServives';
import client from '../../utils/client';
import { isMobile } from 'react-device-detect';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function Navbar(props) {
  const [click, setClick] = useState(false);
  const [state, setState] = useState({ user: '', role: '' });
  const [userLogoDisplay, setUserLogoDisplay] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  let token = client.token;

  useEffect(() => {
    async function tokenFunction() {
      token &&
        viewUserProfile()
          .then((response) => {
            setState({
              user: response.data.user,
              role: response.data.user.role._id,
            });
          })
          .catch((error) => {
            if (error.response.data.userError) {
              Cookies.remove('token');
              Cookies.remove('creadUseDetail');
              props.history.push('/');
              window.location.reload();
            }
          });

      token &&
        viewImage()
          .then((response) => {
            setUserLogoDisplay(response.data.userLogo);
          })
          .catch((error) => {
            if (error.response.data.userError) {
              Cookies.remove('token');
              Cookies.remove('creadUseDetail');
              props.history.push('/');
              window.location.reload();
            }
          });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting users record
      socket.on('user', (ms) => {
        tokenFunction();
      });
    }
    clientSocket();
    tokenFunction();
  }, [baseURL, props.history, token]);

  async function logoutUser() {
    if (window.confirm(t('logoutMessage'))) {
      logout()
        .then((response) => {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
  }

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
            {/* LawyerExpert
            <i className="fab fa-typo3" /> */}
            <img
              className="logo"
              src="/images/logo/logo-red.jpg"
              width="200"
              alt=""
            />
          </NavLink>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className="nav-item">
              <NavLink
                to="/who-we-are"
                activeClassName="navbar-active"
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  //tokenFunction();
                }}
              >
                {t('nWhoWeAre')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/pricing"
                activeClassName="navbar-active"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                {t('nPricing')}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/find-a-lawyer"
                activeClassName="navbar-active"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                {t('nFindALawyer')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/document-bank"
                activeClassName="navbar-active"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                {t('nDocumentBank')}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact"
                activeClassName="navbar-active"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                {t('nContact')}
              </NavLink>
            </li>
            {token === undefined ? (
              <li className="nav-item">
                <NavLink
                  to="/sign-in"
                  activeClassName="navbar-active"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  <i className="fas fa-sign-in-alt login-icon"></i>{' '}
                  {t('nLogin')}
                </NavLink>
              </li>
            ) : (
              <li className="nav-item">
                <Link
                  to="#"
                  className="nav-links"
                  //onClick={closeMobileMenu}
                >
                  {userLogoDisplay ? (
                    <img
                      className="imageRound"
                      src={`${baseURL}/images/${userLogoDisplay}`}
                      height="150"
                      width="150"
                      alt=""
                    />
                  ) : (
                    <i className="fas fa-user user-icon-web"></i>
                  )}
                  <span className="headerTitleNameWeb">
                    {state.user.firstName}
                  </span>
                  <i className="fas fa-angle-down login-icon"></i>
                </Link>
                <ul>
                  <li>
                    <NavLink
                      to="/profile"
                      activeClassName="navbar-active"
                      className="nav-links"
                      onClick={closeMobileMenu}
                    >
                      <i className="fas fa-user login-icon"> </i>{' '}
                      {t('nProfile')}
                    </NavLink>
                  </li>
                  {state.role === '6044119e0079f15a6cd050f7' && !isMobile && (
                    <li className="small-size">
                      <NavLink
                        to="/dashboard"
                        activeClassName="navbar-active"
                        className="nav-links"
                        onClick={closeMobileMenu}
                      >
                        <i className="fas fa-tachometer-alt login-icon"> </i>
                        {t('nDashboard')}
                      </NavLink>
                    </li>
                  )}
                  <li>
                    <Link
                      to="#"
                      className="nav-links"
                      onClick={() => logoutUser()}
                    >
                      <i className="fas fa-sign-out-alt login-icon"></i>{' '}
                      {t('nLogout')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default withRouter(Navbar);
