const sideBar = {
  sRequestMessage:
    'You can request the documents you are looking for using our contact form.',
  sSearch: 'Search',
  sDocumentBank: 'Document Bank',
  sDocumentName: 'Document name',
  sRequestDocument: 'Request Document',
  sClearInput: 'Clear input',
};

export default sideBar;
