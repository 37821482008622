import React from 'react';
import PasswordChangeProfile from '../../components/user-profile/password-change';
import SidebarProfile from './SideBarProfile';
import TopSection from '../../components/TopSection';

export default function ChangePassword() {
  return (
    <>
      <TopSection heading="Change password" />
      <div className="main_container about-us about-top">
        <div className="container">
          <PasswordChangeProfile ui="/profile">
            <SidebarProfile />
          </PasswordChangeProfile>
        </div>
      </div>
    </>
  );
}
