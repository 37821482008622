import client from '../utils/client';

/**
 * Add service
 */
export async function addRecord(lawName) {
  return await client.post(`/userDocuments/`, {
    userDocument: lawName,
  });
}

/**
 * View  service
 */
export async function viewAllRecord() {
  return await client.get(`/userDocuments/`, {});
}

export async function viewAllRecordUser() {
  return await client.get(`/userDocuments/me`, {});
}

export async function viewEachUserDocument(document) {
  return await client.get(`/userDocuments/me/${document}/`, {});
}

/**
 * View each service
 */
export async function viewEachRecord(id) {
  return await client.get(`/userDocuments/${id}`, {});
}

/**
 * Update service
 */
export async function updateRecord(name, id) {
  return await client.put(`/userDocuments/${id}`, {
    userDocument: name,
  });
}

/**
 * Delete service
 */
export async function deleteRecord(id) {
  return await client.remove(`/userDocuments/${id}`, {});
}
