import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { viewEachUser } from '../../services/usersServives';

export default function EditUser(props) {
  const [user, setUser] = useState({});
  const [role, setRole] = useState({});
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      viewEachUser(id)
        .then((response) => {
          setUser(response.data);
          setRole(response.data.role);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for delete user record
      socket.on('deleteUser', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push('/cms/users');
        }
      });

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (props.match.params.id === ms) {
          getEach();
        }
      });
    }
    clientSocket();
    getEach();
  }, [props.match.params.id]);

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <Link to="/cms/users" className="btn btn-info mr-2">
                        View users
                      </Link>
                    </h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 each-top">
                        <div className="row">
                          <div className="col-12 each-detail">
                            <h5>User details</h5>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Company name</span>:{' '}
                              {user.companyName ? user.companyName : '-'}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Full name</span>:{' '}
                              {user.firstName + ' ' + user.lastName}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Email</span>: {user.email}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Gender</span>: {user.gender}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Account type</span>: {role.name}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Status</span>: {user.status}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>action</span>:{' '}
                              {user.action ? user.action : '-'}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 each-top">
                        <div className="row">
                          <div className="col-12 each-detail">
                            <h5>Contact address</h5>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Country</span>:{' '}
                              {user.country ? user.country : '-'}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>City</span>: {user.city ? user.city : '-'}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>County</span>:{' '}
                              {user.county ? user.county : '-'}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Address</span>:{' '}
                              {user.address ? user.address : '-'}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Post code</span>:{' '}
                              {user.postCode ? user.postCode : '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 each-top">
                        <div className="row">
                          <div className="col-12 each-detail">
                            <h5>Contact details</h5>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Telephone</span>:{' '}
                              {user.tel ? user.tel : '-'}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Mobile</span>:{' '}
                              {user.mobilePhone ? user.mobilePhone : '-'}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Websie</span>:{' '}
                              {user.website ? (
                                <a
                                  target="_blank"
                                  href={user.website}
                                  className="sMediaColor"
                                >
                                  visit website
                                </a>
                              ) : (
                                '-'
                              )}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Youtube</span>:{' '}
                              {user.youtubeLink ? (
                                <a
                                  target="_blank"
                                  href={user.youtubeLink}
                                  className="sMediaColor"
                                >
                                  visit youtube
                                </a>
                              ) : (
                                '-'
                              )}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>LinkedIn</span>:{' '}
                              {user.linkedIn ? (
                                <a
                                  target="_blank"
                                  href={user.linkedIn}
                                  className="sMediaColor"
                                >
                                  visit linkedIn
                                </a>
                              ) : (
                                '-'
                              )}
                            </p>
                          </div>
                          <div className="col-12 each-p">
                            <p>
                              <span>Description</span>:<br />
                              {user.description
                                ? user.description
                                    ?.split('\n\n')
                                    .map((paragraph) => (
                                      <p key={paragraph}>
                                        {paragraph
                                          .split('\n')
                                          .reduce((total, line) => [
                                            total,
                                            <br />,
                                            line,
                                          ])}
                                      </p>
                                    ))
                                : '-'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
