import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import { viewUserProfile } from '../../services/usersServives';
import { ProfileNavBar } from './profileNavBar';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();
function ViewProfileDetails({ epr, edpr, epic, elog, children, ...props }) {
  const [user, setUsers] = useState({});
  const [role, setRole] = useState({});
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);
      const userId = Cookies.get('creadUseDetail');

      // Socket for delete user record
      socket.on('deleteUser', (ms) => {
        if (userId === ms) {
          props.history.push('/');
        }
      });

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (userId === ms) {
          getData();
        }
      });
    }
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setUsers(response.data.user);
          setRole(response.data.user.role);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    clientSocket();
    getData();
  }, [baseURL, props.history]);

  return (
    <Page title={t('dProfile')} content={t('dDocumentContent')} href="/">
      <div className="row">
        {children}
        <div className="col-md-9 col-12">
          <div className="col-12">
            <div className="card">
              <ProfileNavBar epr={epr} edpr={edpr} epic={epic} elog={elog} />
              <div className="card-body">
                <div className="row">
                  <div className="col-12 each-top">
                    <div className="row">
                      <div className="col-12 each-detail">
                        <h5>{t('userDetails')}</h5>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('companyName')}</span>:{' '}
                          {user.companyName ? user.companyName : '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('fullName')}</span>:{' '}
                          {user.firstName + ' ' + user.lastName}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('email')}</span>: {user.email}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('gender')}</span>: {user.gender}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('accountType')}</span>: {role.name}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('status')}</span>: {user.status}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('action')}</span>:{' '}
                          {user.action ? user.action : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 each-top">
                    <div className="row">
                      <div className="col-12 each-detail">
                        <h5>{t('contactAddress')}</h5>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('country')}</span>:{' '}
                          {user.country ? user.country : '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('city')}</span>:{' '}
                          {user.city ? user.city : '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('county')}</span>:{' '}
                          {user.county ? user.county : '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('address')}</span>:{' '}
                          {user.address ? user.address : '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('postCode')}</span>:{' '}
                          {user.postCode ? user.postCode : '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 each-top">
                    <div className="row">
                      <div className="col-12 each-detail">
                        <h5>{t('contactDetails')}</h5>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('telNumber')}</span>:{' '}
                          {user.tel ? user.tel : '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('mobileNumber')}</span>:{' '}
                          {user.mobilePhone ? user.mobilePhone : '-'}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('website')}</span>:{' '}
                          {user.website ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={user.website}
                              className="sMediaColor"
                            >
                              {t('visitWebsite')}
                            </a>
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('youtube')}</span>:{' '}
                          {user.youtubeLink ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={user.youtubeLink}
                              className="sMediaColor"
                            >
                              {t('visitYoutube')}
                            </a>
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                      <div className="col-md-6 col-12 each-p">
                        <p>
                          <span>{t('linkedin')}</span>:{' '}
                          {user.linkedIn ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={user.linkedIn}
                              className="sMediaColor"
                            >
                              {t('visitLinkedin')}
                            </a>
                          ) : (
                            '-'
                          )}
                        </p>
                      </div>
                      <div className="col-md-12 col-12 each-p each-desc">
                        <div>
                          <span>{t('description')}</span>:<br />
                          {user.description
                            ? user.description
                                ?.split('\n\n')
                                .map((paragraph) => (
                                  <p key={paragraph}>
                                    {paragraph
                                      .split('\n')
                                      .reduce((total, line) => [
                                        total,
                                        <br />,
                                        line,
                                      ])}
                                  </p>
                                ))
                            : '-'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(ViewProfileDetails);
