import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function AccessMoreDoc() {
  const { t } = useTranslation();
  return (
    <div className="main_containerssss">
      <div className="main_container team section-bg">
        <div className="container">
          <div className="row">
            <div className="access-more col-md-9 col-12">
              <h3>{t('accessLegals')}</h3>
              <p>{t('accessLegalsText')}</p>
            </div>
            <div className="access-more-btn col-md-3 col-12">
              <Link to="/document-bank">
                <button className="btn-global">
                  {' '}
                  {t('fDocumentBank')} <i className="fas fa-angle-right"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AccessMoreDoc);
