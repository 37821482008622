import React from 'react';

function HelSection() {
  return (
    <div className="help-container">
      <h1>Help when you need it</h1>
      <p>
        SenamiTech is a team of highly motivated IT experts, building and
        equipping businesses by breaking down all barriers and working closely
        with clients as a team member to create an online platform and build a
        bespoke customisable software, as well as other services and training to
        suit client’s business. Meeting your everyday needs through connectivity
        & digitalization of Business Services, B2B, Training, Mentoring,
        Codemaniac (Rock Code Academy).
      </p>
    </div>
  );
}

export default HelSection;
