import React, { useEffect, useState } from 'react';
import TopSection from '../../components/TopSection';
import images from './data';

function Project() {
  const [tag, setTag] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);

  useEffect(() => {
    tag === 'all'
      ? setFilteredImages(images)
      : setFilteredImages(images.filter((image) => image.tag === tag));
  }, [tag]);
  return (
    <>
      <TopSection heading="Our projects" />
      <div className="main_container portfolio">
        <div className="container">
          <div className="project">
            <div className="tags">
              <TagButton
                name="all"
                tagActive={tag === 'all' ? true : false}
                handleSetTag={setTag}
              />
              <TagButton
                name="new"
                tagActive={tag === 'new' ? true : false}
                handleSetTag={setTag}
              />
              <TagButton
                name="free"
                tagActive={tag === 'free' ? true : false}
                handleSetTag={setTag}
              />
              <TagButton
                name="pro"
                tagActive={tag === 'pro' ? true : false}
                handleSetTag={setTag}
              />
            </div>
          </div>
          <div className="row portfolio-container project" data-aos="fade-up">
            <div className="container">
              {filteredImages.map((image) => (
                <div
                  key={image.id}
                  className="col-sm-12 portfolio-item image-card"
                >
                  <p className="top-name">{image.name}</p>
                  <a href={`/images/images/${image.imageName}`}>
                    <img
                      className="image"
                      src={`/images/images/${image.imageName}`}
                      alt=""
                    />
                  </a>
                  <div className="portfolio-info">
                    <h4>{image.name}</h4>
                    <p>{image.tag}</p>
                    <a
                      href="/images/portfolio/portfolio-9.jpg"
                      data-gall="portfolioGallery"
                      className="venobox preview-link"
                      title="Web 3"
                    >
                      <i className="fas fa-envelope"></i>
                    </a>
                    <a
                      href="portfolio-details.html"
                      className="details-link"
                      title="More Details"
                    >
                      <i className="fas fa-envelope"></i>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const TagButton = ({ name, handleSetTag, tagActive }) => {
  return (
    <button
      className={`tag ${tagActive ? 'active' : null}`}
      onClick={() => handleSetTag(name)}
    >
      {name.toUpperCase()}
    </button>
  );
};

export default Project;
