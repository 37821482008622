import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import TopSection from '../../components/TopSection';
import { viewEachLawyer } from '../../services/usersServives';
import { viewAllRecordFrontend } from '../../services/subCategoriesServives';
import { viewAllRecordUserDisplay } from '../../services/userAreaOfLawServives';
import SideDocument from '../../components/document/SideDocument';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function ViewProfile(props) {
  const { t } = useTranslation();
  const [viewEach, setViewEachUser] = useState({});
  const [countDocuments, setCountDocument] = useState([]);
  const [userAreaOfLaw, setUserAreaOfLaw] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getEach() {
      viewEachLawyer(props.match.params.id)
        .then((response) => {
          setViewEachUser(response.data);
          getExpertise();
        })
        .catch((error) => {});
    }
    async function getExpertise() {
      viewAllRecordUserDisplay(props.match.params.id)
        .then((response) => {
          setUserAreaOfLaw(response.data);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            props.history.push('/');
            window.location.reload();
          }
        });
    }

    async function getDocument() {
      const filter = props.location.search;
      viewAllRecordFrontend(filter)
        .then((response) => {
          setCountDocument(response.data.countDocument);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting users record
      socket.on('user', (ms) => {
        getEach();
      });

      // Socket for update role record
      socket.on('userAreaOfLaw', (ms) => {
        getEach();
      });

      // Socket for getting document record
      socket.on('document', (ms) => {
        getDocument();
      });

      // Socket for getting area of law record
      socket.on('areaOfLaw', (ms) => {
        getEach();
      });
    }
    clientSocket();
    getEach();
    getDocument();
  }, [baseURL, props.history, props.location.search, props.match.params.id]);

  return (
    <Page
      title={t('findALawyer')}
      href="/find-a-lawyer"
      content={t('findALawyerContent')}
    >
      <TopSection heading={t('findALawyer')} />
      <div className="main_container lawyers">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              {/* <h1 className="page-header col-sm-12">List of Lawyers</h1> */}
              <div className="col-md-12 findExpertList each__view_back">
                <div className="col-12 find_title">
                  <div className="row">
                    <div className="col-lg-3 col-12 logo_image">
                      {viewEach.companyLogo ? (
                        <img
                          width="150"
                          height="150"
                          className="img-responsive"
                          src={`${baseURL}/images/${viewEach.companyLogo}`}
                          alt=""
                        />
                      ) : (
                        <img
                          width="150"
                          height="150"
                          className="img-responsive"
                          src="/images/banner/placeholder.png"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="col-lg-5 col-md-6 detail_style">
                      <h4>{t('lawFirm')}</h4>
                      <p>
                        <b>{t('lawFirm')}:</b> {viewEach.companyName} <br></br>
                        {/* <b>Email:</b> {viewEach.email} <br></br> */}
                        <b>{t('telephone')}:</b> {viewEach.telephone} <br></br>
                        {/* <b>Mobile phone:</b> {viewEach.mobile_phone} <br></br> */}
                        <b>{t('linkedin')}:</b>{' '}
                        <a
                          href={viewEach.linkedIn}
                          target="_blank"
                          rel="noopener noreferrer"
                          alt=""
                        >
                          {t('linkedinProfile')}
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 detail_style">
                      <h4>{t('address')}</h4>
                      <p>
                        <b>{t('country')}:</b> {viewEach.country} <br></br>
                        {/* <b>Address:</b> {viewEach.address} <br></br> */}
                        {/* <b>County:</b> {viewEach.county} <br></br> */}
                        <b>{t('city')}:</b> {viewEach.city} <br></br>
                        <b>{t('postCode')}:</b> {viewEach.post_code} <br></br>
                      </p>
                    </div>
                  </div>
                  <p className="firm-specialise">
                    <b>{t('legalExpertise')}:</b> <br />
                    {userAreaOfLaw.map((detail) => (
                      <span key={detail._id}>
                        <span>{detail.areaOfLaw.name} | </span>
                      </span>
                    ))}
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4 col-4 view_connect">
                    <Link to="/find-a-lawyer">
                      <p>{t('back')}</p>
                    </Link>
                  </div>
                  <div className="col-md-4 col-4 view_connect">
                    <Link to={{ pathname: `/connect/${viewEach._id}` }}>
                      <p>{t('connect')}</p>
                    </Link>
                  </div>
                  <div className="col-md-4 col-4 view_connect">
                    {viewEach.website && (
                      <a
                        href={viewEach.website}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p>{t('website')}</p>
                      </a>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12">
                <p className="">
                  {viewEach.description?.split('\n\n').map((paragraph) => (
                    <p key={paragraph} className="lawy-paragraph">
                      {paragraph
                        .split('\n')
                        .reduce((total, line) => [total, <br />, line])}
                    </p>
                  ))}
                </p>
              </div>
            </div>

            {/* Document side  */}
            <SideDocument
              className="col-md-4 col-12 lawy-side serach-display"
              countDocuments={countDocuments}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(ViewProfile);
