import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import client from '../../utils/client';
import { viewUserProfile } from '../../services/usersServives';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function UserProfile(props) {
  const [state, setState] = useState({ user: '', role: '' });
  let token = client.token;
  const { t } = useTranslation();

  if (token === undefined) {
    props.history.push('/');
  }

  useEffect(() => {
    async function getData() {
      if (token) {
        viewUserProfile()
          .then((response) => {
            setState({
              user: response.data.user,
              role: response.data.user.role._id,
            });
          })
          .catch((error) => {
            if (error.response.data.userError) {
              Cookies.remove('token');
              Cookies.remove('creadUseDetail');
              props.history.push('/');
              window.location.reload();
            }
          });
      } else {
        props.history.push('/');
        window.location.reload();
      }
    }

    getData();
  }, [props.history, token]);

  return token ? (
    <div className="col-md-3 col-12 profile-side-bar side-bar-mobile">
      <ul>
        <li>
          <NavLink activeClassName="selected" to={{ pathname: '/profile' }}>
            {t('dProfile')}
          </NavLink>
        </li>
        {(state.role === '6044119e0079f15a6cd050f7' ||
          state.role === '604411b80079f15a6cd050f8') && (
          <>
            <li>
              <NavLink
                activeClassName="selected"
                to={{ pathname: '/main-view-user-area-of-law' }}
              >
                {t('dAreaExpertise')}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName="selected"
                to={{ pathname: '/main-view-documents' }}
              >
                {t('dDocumentTitle')}
              </NavLink>
            </li>
          </>
        )}

        <li>
          <NavLink
            activeClassName="selected"
            to={{ pathname: '/main-view-user-documents' }}
          >
            {t('dMyDocument')}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="selected"
            to={{ pathname: '/user-billing' }}
          >
            {t('dBAndS')}
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="selected"
            to={{ pathname: '/main-view-user-change-password' }}
          >
            {t('dChangePassword')}
          </NavLink>
        </li>
        {/* <li>
          <NavLink activeClassName="selected" to={{ pathname: '/delete-user' }}>
            Delete
          </NavLink>
        </li> */}
      </ul>
    </div>
  ) : (
    <div>{window.location.reload()}</div>
  );
}

export default withRouter(UserProfile);
