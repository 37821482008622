import React, { useState } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import { Modal, Button } from 'react-bootstrap';
import { addRecord } from '../../services/contactServives';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function Footer() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [show, setShow] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  //const LANGUAGE_DISPLAY = process.env.REACT_APP_LANGUAGE_DISPLAY;
  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };
  const date = new Date();

  async function handleSubmit(event) {
    event.preventDefault();
    let reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (email === '' || reg.test(email) === false) {
      if (!email) {
        setEmailError(t('emailRequired'));
      } else if (reg.test(email) === false) {
        setEmailError(t('wrongEmail'));
      } else {
        setEmailError(null);
      }
    } else {
      setEmailError(null);
      submitMessage();
    }
  }

  async function submitMessage() {
    setShow(true);
    const type = 'subscribe';
    const name = 'Unknown';
    const number = '';
    const subject = '';
    const message = '';
    addRecord(name, email, number, subject, message, type)
      .then((response) => {
        const socket = io(baseURL);
        socket.emit('contact', `Add subscription`);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('message')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('fNewsLetterThanks')}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('ok')}
          </Button>
        </Modal.Footer>
      </Modal>
      <div id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-12 footer-contact footer-lawyer">
                <h4>{t('lawyerExpertLtd')}</h4>
                <p
                  className="footer-address"
                  dangerouslySetInnerHTML={{ __html: t('fAddress') }}
                />
                <p>
                  <i className="fas fa-phone"></i> <span> {t('tel')}</span>
                </p>
                <p>
                  <i className="fas fa-envelope"></i>{' '}
                  <span>{t('emailAddress')}</span>
                </p>
              </div>

              <div className="col-md-2 col-6 footer-links">
                <h4>{t('fUsefulLinks')}</h4>
                <ul>
                  <li>
                    <i className="fas fa-angle-right"></i>
                    <Link to="/">{t('fHome')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/who-we-are">{t('fAboutUs')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/contact">{t('fContactUs')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/terms-of-service">{t('fTermsOfService')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/privacy-policy">{t('fPrivacyPolicy')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/cookies-policy">{t('fCookiesPolicy')}</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-6 footer-links">
                <h4>{t('fOurServices')}</h4>
                <ul>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/find-a-lawyer">{t('fFindLawyer')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/document-bank">{t('fDocumentBank')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/pricing">{t('fPricing')}</Link>
                  </li>
                  <h4 className="for-lawyer">{t('fForLawyers')}</h4>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/sign-up">{t('fJoinForFree')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/legal-marketing">{t('fLegalMarketing')}</Link>
                  </li>
                  <li>
                    <i className="fas fa-angle-right"></i>{' '}
                    <Link to="/legal-technology">{t('fLegalTechnology')}</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 col-12 footer-links footer-newsletter">
                <h4>{t('fJoinOurNewsLetter')}</h4>
                <p>{t('fNewsLettersMessage')}</p>
                <form className="commentForm" onSubmit={handleSubmit}>
                  <div>
                    <input
                      placeholder={t('email')}
                      type="email"
                      name={email}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <input type="submit" value={t('subscribe')} />
                  </div>
                </form>
                {emailError ? (
                  <p className="errorMessage"> {emailError}</p>
                ) : null}

                <div className="footer-stripe">
                  <img
                    className="footer-image"
                    src={`/images/stripe-badge-white.png`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-base">
          <div className="container d-md-flex py-4">
            <div className="mr-md-auto text-center text-md-left">
              <div className="copyright">
                {t('nCopyright')} &copy; {date.getFullYear()}{' '}
                <strong>
                  <span>{t('lawyerExpertLtd')}</span>
                </strong>
              </div>
            </div>
            <div className="social-links text-center text-md-right pt-3 pt-md-0">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="/"
                className="twitter"
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/LawyerExpertcom-103104528878682"
                className="facebook"
              >
                <i className="fab fa-facebook"></i>
              </a>
              {/* <a target="_blank" href="#" className="instagram">
                <i className="fab fa-instagram"></i>
              </a> */}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/74526725/admin/"
                className="linkedin"
              >
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
