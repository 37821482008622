import React from 'react';
import SidebarProfile from './SideBarProfile';
import AreaOfLawProfile from '../../components/user-profile/area-of-law';
import TopSection from '../../components/TopSection';

export default function AreaOfLaw() {
  return (
    <>
      <TopSection heading="Areas of expertise" />
      <div className="main_container about-us about-top">
        <div className="container">
          <AreaOfLawProfile>
            <SidebarProfile />
          </AreaOfLawProfile>
        </div>
      </div>
    </>
  );
}
