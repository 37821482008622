const aboutUs = {
  abWhoWeAre: 'Who we are',
  aboutUs: 'About us',
  aContent: 'About us content',
  abWhoWeAreFP:
    'We are a team of entrepreneurs and professionals dedicated to creating a more inclusive and fair society, where legal services are accessible on demand to everyone from a sole trader to corporate executive.',
  abWhoWeAreSP:
    'We aim to be a leader in the digital transformation of legal services by empowering both lawyers and their clients to achieve more through tech-enabled collaboration, smart services and innovative ways to fulfil client’s needs.',
  abOurServices: 'Our Servives',
  abOurServicesFP:
    'We are disrupting the way we access and deliver legal services by partnering with expert firms and solicitors who want to fill a spare capacity, grow their business or simply give back to society. All legal experts on our platform are vetted and have a proven ability to deliver results in full and on time.',
  abOurVision: 'Our Vision',
  abOurVisionFP:
    'Our vision is to enable and empower everyone to be able to access legal services when needed and thus make society fairer, more inclusive and offering equal opportunity for everyone to protect their dreams and achieve their goals.',
};

export default aboutUs;
