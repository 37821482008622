import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { viewUserProfile } from '../../services/usersServives';
import SidebarProfile from './SideBarProfile';

export default function Documents(props) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    viewUserProfile()
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Documents</h1>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="row">
                      <SidebarProfile />
                      <div className="col-md-8 col-12">
                        <p>Coming soon</p>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
