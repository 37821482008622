import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { viewAllRecord } from '../../services/areaOfLawServives';
import {
  viewAllRecordUser,
  addRecord,
  deleteRecord,
} from '../../services/userAreaOfLawServives';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function AreaOfLawProfile({ children, ...props }) {
  const [userAreaOfLaw, setUserAreaOfLaw] = useState([]);
  const [lawName, setLawName] = useState('');
  const [lawNameError, setLawNameError] = useState('');
  const [areaOfLaw, setAreaOfLaw] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getData() {
      viewAllRecordUser()
        .then((response) => {
          setUserAreaOfLaw(response.data);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);
      const userId = Cookies.get('creadUseDetail');

      // Socket for getting user area f law record
      socket.on('userAreaOfLaw', (ms) => {
        if (userId === ms) {
          getData();
        }
      });

      // Socket for delete user area of law record
      socket.on('deleteUserAreaOfLaw', (ms) => {
        if (userId === ms) {
          getData();
        }
      });

      // Socket for getting area of law record
      socket.on('areaOfLaw', (ms) => {
        getData();
      });
    }
    clientSocket();
    getData();
    getAreaOfLaw();
  }, [baseURL, props.history]);

  async function handleSubmit() {
    if (lawName === '') {
      if (lawName === '') {
        setLawNameError('Name is required');
      } else {
        setLawNameError(null);
      }
    } else {
      setLawNameError(null);
      addData();
    }
  }

  async function addData() {
    addRecord(lawName)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('userAreaOfLaw', `${userId}`);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
        if (error.response.data.error) {
          setLawNameError(error.response.data.error);
        }
      });
  }

  async function getAreaOfLaw() {
    viewAllRecord()
      .then((response) => {
        setAreaOfLaw(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function deleteData(id) {
    if (window.confirm(t('checkDeleteRecord'))) {
      deleteRecord(id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('userAreaOfLaw', `${userId}`);
          socket.emit('deleteUserAreaOfLaw', `${userId}`);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
  }
  return (
    <Page title={t('dAreaExpertise')} content={t('dDocumentContent')} href="/">
      <div className="row">
        {children}
        <div className="col-md-9 col-12">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="card-title">
                  <div className="col-12 form-row">
                    <div className="row">
                      <div className="col-10 form-group form-group">
                        <select
                          className="form-control"
                          name={lawName}
                          onChange={(e) => setLawName(e.target.value)}
                        >
                          <option value=""> {t('selectAreaOfLaw')}</option>
                          {areaOfLaw.map((detail) => (
                            <option key={detail._id} value={detail._id}>
                              {detail.name}
                            </option>
                          ))}
                        </select>
                        {lawNameError ? (
                          <p className="errorMessage"> {lawNameError}</p>
                        ) : null}
                      </div>

                      <div className="col-2 lawyers-user-add">
                        <div className="card-title">
                          <Link
                            to="#"
                            onClick={() => handleSubmit()}
                            className="btn-style btn-info mr-2"
                          >
                            {t('add')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="">
                  <table
                    id="example2"
                    className="table table-bordered table-hover"
                  >
                    <thead className="headerTable">
                      <tr>
                        <th> {t('name')}</th>
                        <th colSpan="1" className="col-span">
                          {t('action')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userAreaOfLaw.map((detail) => (
                        <tr key={detail._id}>
                          <td>{detail.areaOfLaw.name}</td>
                          <td className="col-span">
                            <Link
                              to="#"
                              onClick={() => deleteData(detail._id)}
                              className="btn-style btn-danger mr-2"
                            >
                              {t('delete')}
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
            {/* /.card */}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(AreaOfLawProfile);
