import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import ViewCategories from './Categories';
import {
  viewEachRecordCategory,
  updateRecord,
} from '../../services/categoriesServives';

function Categories(props) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      viewEachRecordCategory(id)
        .then((response) => {
          setName(response.data.name);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for update category record
      socket.on('updateCategory', (ms) => {
        if (props.match.params.id === ms) {
          getEach();
        }
      });

      // Socket for delete category record
      socket.on('deleteCategory', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push('/cms/categories');
        }
      });
    }
    clientSocket();
    getEach();
  }, [props.match.params.id]);

  async function handleSubmit() {
    if (name === '') {
      if (name === '') {
        setNameError('Category name is required');
      } else {
        setNameError(null);
      }
    } else {
      setNameError(null);
      editData();
    }
  }

  async function editData() {
    const id = props.match.params.id;
    updateRecord(name, id)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('category', `${userId}`);
        socket.emit('updateCategory', `${props.match.params.id}`);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1></h1>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="form-row">
                      <div className="col-12 form-group">
                        <label>
                          Edit Category<i className="required-detail">*</i>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name={name}
                          placeholder="Category name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        {nameError ? (
                          <p className="errorMessage"> {nameError}</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">
                      <Link
                        to="#"
                        onClick={() => handleSubmit()}
                        className="btn btn-info mr-2"
                      >
                        Update Record
                      </Link>
                    </h3>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              <ViewCategories />
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default Categories;
