import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { viewUserProfile } from '../../services/usersServives';
import { SubscriptionNavBar } from './subscriptionNavBar';
// import {
//   AnnualFree,
//   AnnualPro,
//   AnnualBusiness,
//   MonthlyFree,
//   MonthlyPro,
//   MonthlyBusiness,
// } from '../../pages/packages/PackageList';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function UserUpgradeBilling({
  viewBilling,
  upgradeBilling,
  cancelBilling,
  children,
  ...props
}) {
  // const [role, setRole] = useState({});
  const [user, setUser] = useState({});
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setUser(response.data.user);
          // setRole(response.data.user.role);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);
      const userId = Cookies.get('creadUseDetail');

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (userId === ms) {
          getData();
        }
      });
    }
    clientSocket();
    getData();
  }, [baseURL, props.history]);

  function showRecord() {
    if (
      user.billing === 'annual' &&
      user.subscription === 'business' &&
      (user.subscriptionActivate === 'activate' ||
        user.subscriptionActivate === 'upgradeToBusiness')
    ) {
      return (
        <>
          <p className="col-12">Your subscription is business.</p>
        </>
      );
    } else if (
      user.billing === 'annual' &&
      user.subscription === 'pro' &&
      user.subscriptionActivate === 'activate'
    ) {
      return (
        <>
          <p className="col-12">
            You can upgrade your subscription to business.
          </p>
          <div className="col-md-6 col-12 mt-4 mt-md-0" data-aos="fade-down">
            <p className="pricing-save">Save £198 per year</p>
            <div className="box">
              <h3>Business</h3>
              <h4>
                <sup>£</sup>990<span> / year</span>
              </h4>
              <ul>
                <li>
                  <i className="fas fa-check icon-tick"></i>2 Weeks Free Trial
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Secure Personal Account
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Unlimited Access to Business legal documents
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Download and edit on your device
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Access on demand 24/7
                </li>
              </ul>
              <div className="btn-wrap">
                <Link
                  className="btn-buy"
                  to={{
                    pathname: `/billing`,
                    search: `?billing=annual billing business`,
                  }}
                >
                  Start Now
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    } else if (
      user.billing === 'monthly' &&
      user.subscription === 'business' &&
      (user.subscriptionActivate === 'activate' ||
        user.subscriptionActivate === 'upgradeToBusiness')
    ) {
      return (
        <>
          <>
            <p className="col-12">Your subscription is business</p>
          </>
          {/* <p>
            You can upgrade your subscription to both annual pro or annual
            business ...
          </p>
          <div className="col-md-6 col-12 mt-4 mt-md-0" data-aos="fade-up">
            <p className="pricing-save">Save £98 per year</p>
            <div className="box featured">
              <h3>Pro</h3>
              <h4>
                <sup>£</sup>490<span> / year</span>
              </h4>
              <ul>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>2 Weeks Free
                  Trial
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Secure Personal Account
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Unlimited Access to Personal legal documents
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Download and edit on your device
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Access on demand 24/7
                </li>
              </ul>
              <div className="btn-wrap">
                <Link
                  className="btn-buy"
                  to={{
                    pathname: `/billing`,
                    search: `?billing=annual billing pro`,
                  }}
                >
                  Start Now
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-12 mt-4 mt-md-0" data-aos="fade-down">
            <p className="pricing-save">Save £198 per year</p>
            <div className="box">
              <h3>Business</h3>
              <h4>
                <sup>£</sup>990<span> / year</span>
              </h4>
              <ul>
                <li>
                  <i className="fas fa-check icon-tick"></i>2 Weeks Free Trial
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Secure Personal Account
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Unlimited Access to Business legal documents
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Download and edit on your device
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Access on demand 24/7
                </li>
              </ul>
              <div className="btn-wrap">
                <Link
                  className="btn-buy"
                  to={{
                    pathname: `/billing`,
                    search: `?billing=annual billing business`,
                  }}
                >
                  Start Now
                </Link>
              </div>
            </div>
          </div> */}
        </>
      );
    } else if (
      user.billing === 'monthly' &&
      user.subscription === 'pro' &&
      user.subscriptionActivate === 'activate'
    ) {
      return (
        <>
          <p className="col-12">
            You can upgrade your subscription to business.
          </p>
          {/* <div className="col-md-6 col-12 mt-4 mt-md-0" data-aos="fade-up">
            <p className="pricing-save">Save £98 per year</p>
            <div className="box featured">
              <h3>Pro</h3>
              <h4>
                <sup>£</sup>490<span> / year</span>
              </h4>
              <ul>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>2 Weeks Free
                  Trial
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Secure Personal Account
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Unlimited Access to Personal legal documents
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Download and edit on your device
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Access on demand 24/7
                </li>
              </ul>
              <div className="btn-wrap">
                <Link
                  className="btn-buy"
                  to={{
                    pathname: `/billing`,
                    search: `?billing=annual billing pro`,
                  }}
                >
                  Start Now
                </Link>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-6 col-12 mt-4 mt-md-0" data-aos="fade-down">
            <p className="pricing-save">Save £198 per year</p>
            <div className="box">
              <h3>Business</h3>
              <h4>
                <sup>£</sup>990<span> / year</span>
              </h4>
              <ul>
                <li>
                  <i className="fas fa-check icon-tick"></i>2 Weeks Free Trial
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Secure Personal Account
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Unlimited Access to Business legal documents
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Download and edit on your device
                </li>
                <li>
                  <i className="fas fa-check icon-tick"></i>
                  Access on demand 24/7
                </li>
              </ul>
              <div className="btn-wrap">
                <Link
                  className="btn-buy"
                  to={{
                    pathname: `/billing`,
                    search: `?billing=annual billing business`,
                  }}
                >
                  Start Now
                </Link>
              </div>
            </div>
          </div> */}

          <div
            className="col-md-6 col-12 mt-4 mt-md-0 pac-to-space"
            data-aos="fade-up"
          >
            <div className="box featured">
              <h3>Business</h3>
              <h4>
                <sup>£</sup>99<span> / month</span>
              </h4>
              <ul>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>2 Weeks Free
                  Trial
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Secure Personal Account
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Unlimited Access to Business legal documents
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Download and edit on your device
                </li>
                <li>
                  <i className="fas fa-check icon-tick-white"></i>
                  Access on demand 24/7
                </li>
              </ul>
              <div className="btn-wrap">
                <Link
                  className="btn-buy"
                  to={{
                    pathname: `/billing`,
                    search: `?billing=monthly billing business`,
                  }}
                >
                  Start Now
                </Link>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      if (user.subscriptionActivate === 'diactivate') {
        return (
          <div>
            <p>
              Your subscription is ..... {user.subscriptionActivate}. You can
              click on the button below to start a new plan
            </p>
            <Link
              className="btn-style btn-info mr-2"
              to={{
                pathname: `/pricing`,
              }}
            >
              Start Now
            </Link>
          </div>
        );
      } else if (user.subscriptionActivate === 'cancel') {
        return (
          <div>
            <p>
              Your subscription is ..... {user.subscriptionActivate}. You can
              click on the button below to start a new plan
            </p>
            <Link
              className="btn-style btn-info mr-2"
              to={{
                pathname: `/pricing`,
              }}
            >
              Start Now
            </Link>
          </div>
        );
      } else if (user.subscriptionActivate === 'terminate') {
        return (
          <div>
            <p>
              Your subscription is ..... {user.subscriptionActivate}. You can
              click on the button below to start a new plan
            </p>
            <Link
              className="btn-style btn-info mr-2"
              to={{
                pathname: `/pricing`,
              }}
            >
              Start Now
            </Link>
          </div>
        );
      }
    }
  }

  return (
    <Page title={t('dUpgradeBilling')} content={t('dDocumentContent')} href="/">
      <div className="row">
        {children}
        <div className="col-md-9 col-12">
          <div className="col-12">
            <div className="card">
              <SubscriptionNavBar
                viewBilling={viewBilling}
                upgradeBilling={upgradeBilling}
                cancelBilling={cancelBilling}
              />
              <div className="card-body">
                <di className="pricing">
                  <div className="row">{showRecord()}</div>
                </di>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(UserUpgradeBilling);
