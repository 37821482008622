const aboutUs = {
  hTitle: 'Home',
  hContent: 'Home content',
  findALawyerDescription:
    ' We are experts in connecting you with the right lawyer for your needs. We work with qualified and experienced legal professionals who can deliver the solutions you need at affordable price.',
  findALawyer: 'Find a Lawyer',
  membershipPlan: 'Membership Plans',
  membershipPlanDescription:
    'Our membership plans offer flexible access to expert guidance and legal documents, whenever and wherever you need them.',
  downloadLegalDocuments: 'Download Legal Documents',
  downloadLegalDocumentsDescription:
    'Our Document Bank includes over 200 standard and specialised legal documents covering both business and personal matters.',
  hOurMission: 'Our Mission',
  hOurMissionDescription:
    'LawyerExpert is a legal data platform designed to revolutionise the way we access legal services. We were established in 2020 with the mission to make legal services accessible to individuals and SMEs who are often overwhelmed by opaque legal process and structure.',
  flDescription:
    'This is a smart engine that enables you to connect with and request quotes from specialist law firms and solicitors.',
  mspDecriptions:
    'Our fixed price monthly membership plans provide access to hundreds of legal documents on demand, whenever and wherever you need them. They offer great flexibility and advance protection, especially for individuals, sole traders and small businesses.',
  docBankDecription:
    'Our Document Bank provides easy access to hundreds of standard and specialised legal documents covering business and personal matters. Simply sign up and download the documents you need. If you can’t find what you are looking for please get in touch to discuss your requirements.',
};

export default aboutUs;
