const footer = {
  fUsefulLinks: 'Useful links',
  fOurServices: 'Our services',
  fJoinOurNewsLetter: 'Join our news letter',
  fForLawyers: 'For lawyers',
  fAddress: `86-90 Paul Street, London, <br/> United Kingdom, EC2A 4NE`,
  fHome: 'Home',
  fAboutUs: 'About us',
  fContactUs: 'Contact us',
  fTermsOfService: 'Terms of Service',
  fPrivacyPolicy: 'Privacy policy',
  fCookiesPolicy: 'Cookies Policy',
  fFindLawyer: 'Find a Lawyer',
  fDocumentBank: 'Document Bank',
  fPricing: 'Pricing',
  fJoinForFree: 'Join for free',
  fLegalMarketing: 'Legal Marketing',
  fLegalTechnology: 'Legal Technology',
  fNewsLettersMessage:
    'We will keep you up to date with the latest insights and trends in legal technology innovation.',
  fNewsLetterThanks: 'Thank you for joining our newsletter list.',
  nCopyright: 'Copyright',
};

export default footer;
