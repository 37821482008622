import React, { useRef, useState } from 'react';
import axios from 'axios';

//import './App.css'

async function postImage({ image, description }) {
  const formData = new FormData();
  formData.append('image', image);
  formData.append('description', description);

  const result = await axios.post('http://localhost:5000/images', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return result.data;
}

function App() {
  const [file, setFile] = useState();
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);

  const submit = async (event) => {
    event.preventDefault();
    const result = await postImage({ image: file, description });
    setImages([result.image, ...images]);
  };

  const fileSelected = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  return (
    <div className="App">
      <form onSubmit={submit}>
        <input onChange={fileSelected} type="file"></input>
        <input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          type="text"
        ></input>
        <button type="submit">Submit</button>
      </form>

      {images.map((image) => (
        <div key={image}>
          <img src={image}></img>
        </div>
      ))}
      <a
        className="doc-download"
        target="_blank"
        href={`http://localhost:5000/images/documents/c9488b48cddf299fc0d79011dad3e93b`}
      >
        Download
      </a>
      <img src="http://localhost:5000/images/51f6446997611042b25fe4961e143772"></img>
    </div>
  );
}

export default App;
