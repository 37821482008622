import React from 'react';
import Cookies from 'js-cookie';
import TopSection from '../../components/TopSection';
import { Link } from 'react-router-dom';
import SidebarProfile from '../users/SideBarProfile';
import { deleteUserProfile } from '../../services/usersServives';

function DeleteUser(props) {
  props.history.push('/profile');
  async function deleteData() {
    if (window.confirm('Are you sure you want to delete your account?')) {
      deleteUserProfile()
        .then((response) => {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      <TopSection heading="Delete user" />
      <div className="main_container about-us about-top lawyers">
        <div className="container">
          <div className="row">
            <SidebarProfile />
            <div className="col-md-9 col-12">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12 form-row user-detail-record">
                      <p>Message here .....</p>
                    </div>
                    <div className="col-12">
                      <div className="card-title">
                        <Link
                          to="#"
                          onClick={() => deleteData()}
                          className="btn-style btn-danger mr-2"
                        >
                          Delete
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteUser;
