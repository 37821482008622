import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import TopSection from '../../components/TopSection';
import HelpSection from '../../components/utils/HelpSection';
import GetStarted from '../../components/utils/GetStarted';

function Service() {
  return (
    <>
      <TopSection heading="Our services" />
      <div className="main_container services">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up">
                <div className="icon">
                  <i className="fas fa-chalkboard-teacher"></i>
                </div>
                <h4 className="title">
                  <a href="/">E-Learning Platform</a>
                </h4>
                <p className="description">
                  Our e-learning platform built with the best technology of the
                  LMS is designed to effectively and efficiently deliver
                  educational ...{' '}
                  <span className="read-more">
                    <Link to="/projects"> Read more</Link>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div className="icon">
                  <i className="fas fa-chart-line"></i>
                </div>
                <h4 className="title">
                  <a href="/">Data Analysis</a>
                </h4>
                <p className="description">
                  Our Expertise in IT consulting cuts across IT Advisory, ERP
                  Services, Data Analytics, Software Management, Systems
                  Integration ...{' '}
                  <span className="read-more">
                    <Link to="/projects"> Read more</Link>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div className="icon">
                  <i className="fas fa-laptop-code"></i>
                </div>
                <h4 className="title">
                  <a href="/">Web development</a>
                </h4>
                <p className="description">
                  Our software team is on the ground to design and develop your
                  software to meet the size and needs of your business. ...{' '}
                  <span className="read-more">
                    <Link to="/projects"> Read more</Link>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div className="icon">
                  <i className="fas fa-business-time"></i>
                </div>
                <h4 className="title">
                  <a href="/">Business consultancy</a>
                </h4>
                <p className="description">
                  Our Business is perfecting and promoting your business. We
                  deliver by helping clients achieve their business goals by ...{' '}
                  <span className="read-more">
                    <Link to="/projects"> Read more</Link>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div className="icon">
                  <i className="fas fa-comment-dollar"></i>
                </div>
                <h4 className="title">
                  <a href="/">Digital Marketing</a>
                </h4>
                <p className="description">
                  We promote your business on the internet and bring you maximum
                  return on your investment through digitally marketing your
                  Brand ...{' '}
                  <span className="read-more">
                    <Link to="/projects"> Read more</Link>
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                <div className="icon">
                  <i className="fas fa-desktop"></i>
                </div>
                <h4 className="title">
                  <a href="/">Maintenance & repairs</a>
                </h4>
                <p className="description">
                  Our team of hardware and software specialist are always ready
                  to maintain your hardware and software to ensure your business
                  ...{' '}
                  <span className="read-more">
                    <Link to="/projects"> Read more</Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HelpSection />
      <GetStarted />
    </>
  );
}

export default withRouter(Service);
