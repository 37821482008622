import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import SidebarProfile from '../users/SideBarProfile';
import TopSection from '../../components/TopSection';
import { Pagination } from '../../components/pagination/index';
import {
  viewAllRecordUser,
  deleteRecordUser,
} from '../../services/documentBankServives';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function DocumentBank(props) {
  const [documentBank, setDocumentBank] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * page.perPage;
  const pageCount = Math.ceil(page.total / page.perPage);
  const { t } = useTranslation();

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    async function getAll() {
      const filter = props.location.search;
      viewAllRecordUser(filter)
        .then((response) => {
          setDocumentBank(response.data.documentBank);
          setPage(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting document record
      socket.on('document', (ms) => {
        const userId = Cookies.get('creadUseDetail');
        if (userId === ms) {
          getAll();
        }
      });
    }
    clientSocket();
    getAll();
  }, [baseURL, props.location.search]);

  async function deleteData(id) {
    if (window.confirm('Are you sure you want to delete this document bank?')) {
      deleteRecordUser(id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('document', `${userId}`);
          socket.emit('deleteDocument', `${id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <Page
      title={t('dDocumentTitle')}
      content={t('dDocumentContent')}
      href="/document-bank"
    >
      <TopSection heading="Document bank" />
      <div className="main_container about-us about-top lawyers">
        <div className="container">
          <div className="row">
            <SidebarProfile />
            <div className="col-md-9 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-title">
                        <Link
                          to="/main-view-document-add"
                          className="btn-style btn-info mr-2"
                        >
                          {t('dUploadDocument')}
                        </Link>
                      </div>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <div className="large-screen">
                        <table
                          id="example2"
                          className="table table-bordered table-hover"
                        >
                          <thead className="headerTable">
                            <tr>
                              <th> {t('name')}</th>
                              <th> {t('dPrice')}</th>
                              <th>{t('dSubCategories')}</th>
                              <th>{t('dDocument')}</th>
                              <th colSpan="3" className="col-span">
                                {t('action')}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {documentBank
                              .slice(pageVisited, pageVisited + page.perPage)
                              .map((detail) => (
                                <tr key={detail._id}>
                                  <td>{detail.name}</td>
                                  <td>
                                    {' '}
                                    {detail.price ? `£${detail.price}` : 'Free'}
                                  </td>
                                  <td>{detail.subCategory.name}</td>
                                  <td>
                                    <a
                                      className="doc-download"
                                      rel="noopener noreferrer"
                                      target="_blank"
                                      href={`${baseURL}/images/${detail.document}`}
                                    >
                                      {t('dDownload')}
                                    </a>
                                  </td>

                                  <td className="col-span">
                                    <Link
                                      className="btn-style btn-secondary mr-2"
                                      to={{
                                        pathname: `/main-view-document-view/${detail._id}`,
                                      }}
                                    >
                                      {t('view')}
                                    </Link>
                                  </td>
                                  <td className="col-span">
                                    <Link
                                      className="btn-style btn-primary mr-2"
                                      to={{
                                        pathname: `/main-view-document-edit/${detail._id}`,
                                      }}
                                    >
                                      {t('edit')}
                                    </Link>
                                  </td>
                                  <td className="col-span">
                                    <Link
                                      to="#"
                                      onClick={() => deleteData(detail._id)}
                                      className="btn-style btn-danger mr-2 disabled-lin"
                                    >
                                      {t('delete')}
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        <Pagination
                          pageCount={pageCount}
                          changePage={changePage}
                        />
                      </div>

                      <div className="small-screen">
                        <div className="doc-small-screen">
                          {documentBank
                            .slice(pageVisited, pageVisited + page.perPage)
                            .map((details) => (
                              <ul
                                key={details._id}
                                className="findExpertList col-md-12"
                              >
                                <li>
                                  <div className="col-12">
                                    <div className="row">
                                      <div className="col-12 detail_style">
                                        <p>
                                          <b>{t('name')}</b>: {details.name}
                                        </p>
                                        <p>
                                          <b>{t('dSubCategories')}</b>:{' '}
                                          {details.subCategory.name}
                                        </p>
                                        <p>
                                          <b>{t('status')}</b>: {details.status}
                                        </p>
                                        <p>
                                          <b>{t('country')}</b>:{' '}
                                          {details.country}
                                        </p>
                                        <p>
                                          <b>{t('dDownload')}</b>:{' '}
                                          <a
                                            className="doc-download"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            href={`${baseURL}/images/${details.document}`}
                                          >
                                            {t('dDownload')}
                                          </a>
                                        </p>
                                      </div>
                                      <div className="col-lg-3 col-12">
                                        <div className="row">
                                          <div className="col-lg-12 col-4 view_connect">
                                            <Link
                                              to={{
                                                pathname: `/main-view-document-view/${details._id}`,
                                              }}
                                            >
                                              <p>{t('view')}</p>
                                            </Link>
                                          </div>
                                          <div className="col-lg-12 col-4 view_connect">
                                            <Link
                                              to={{
                                                pathname: `/main-view-document-edit/${details._id}`,
                                              }}
                                            >
                                              <p>{t('edit')}</p>
                                            </Link>
                                          </div>
                                          <div className="col-lg-12 col-4 view_connect">
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                deleteData(details._id)
                                              }
                                            >
                                              <p>{t('delete')}</p>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            ))}
                        </div>
                        <Pagination
                          pageCount={pageCount}
                          changePage={changePage}
                        />
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default DocumentBank;
