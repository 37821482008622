import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import {
  viewImage,
  updateUserCompanyProfileImage,
  deleteImageLogo,
} from '../../services/usersServives';
import { ProfileNavBar } from './profileNavBar';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function EditCompanyLogo({ children, ui, epr, edpr, epic, elog, ...props }) {
  const [companyLogo, setUserLogo] = useState('');
  const [userLogoError, setUserLogoError] = useState(null);
  const [userLogoDisplay, setUserLogoDisplay] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getData() {
      viewImage()
        .then((response) => {
          setUserLogo(response.data.companyLogo);
          setUserLogoDisplay(response.data.companyLogo);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);
      const userId = Cookies.get('creadUseDetail');

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (userId === ms) {
          getData();
        }
      });
    }
    clientSocket();
    getData();
  }, [baseURL, props.history]);

  const handleChange = (e) => {
    const companyLogo = e.target.files[0];
    setUserLogo(companyLogo);
  };
  async function handleSubmit() {
    if (companyLogo == undefined) {
      props.history.push(ui);
    } else {
      editData();
    }
  }
  async function editData() {
    const data = new FormData();
    data.append('companyLogo', companyLogo);
    updateUserCompanyProfileImage(data)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('user', `${userId}`);
        socket.emit('updateUser', `${userId}`);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }
  async function deleteLogo() {
    if (window.confirm('Are you sure you want to delete this image?')) {
      deleteImageLogo()
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('user', `${userId}`);
          socket.emit('updateUser', `${userId}`);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
  }

  return (
    <Page title={t('dProfileLogo')} content={t('dDocumentContent')} href="/">
      <div className="row">
        {children}
        <div className="col-md-9 col-12">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <ProfileNavBar epr={epr} edpr={edpr} epic={epic} elog={elog} />
              </div>
              <div className="card-body">
                <div className="col-12 user-detail-record">
                  <div className="row form-row">
                    <div className="detail-title col-12">
                      <h5>{t('companyLogo')}</h5>
                    </div>
                    <div className="col-12 ImageSt">
                      {userLogoDisplay ? (
                        <img
                          src={`${baseURL}/images/${userLogoDisplay}`}
                          height="150"
                          width="150"
                          alt=""
                        />
                      ) : (
                        <img
                          width="150"
                          height="150"
                          src="/images/banner/placeholder.png"
                          alt=""
                        />
                      )}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('logo')}</label>
                      <input
                        className="form-control"
                        type="file"
                        name={companyLogo}
                        placeholder="user profile"
                        accept=".jpg, .png, .jpeg"
                        onChange={handleChange}
                      />
                      {userLogoError ? (
                        <p className="errorMessage"> {userLogoError}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="container row">
                  <div className="col-6">
                    <Link
                      to="#"
                      onClick={() => handleSubmit()}
                      className="btn-style btn-info"
                    >
                      {t('update')}
                    </Link>
                  </div>
                  <div className="col-6">
                    <Link
                      to="#"
                      onClick={() => deleteLogo()}
                      className="btn-style btn-danger"
                    >
                      {t('delete')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(EditCompanyLogo);
