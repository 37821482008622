import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import {
  viewUserProfile,
  updateUserProfile,
} from '../../services/usersServives';
import { ProfileNavBar } from './profileNavBar';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function EditProfile({ epr, edpr, epic, elog, children, ui, ...props }) {
  const [userDetail, setUserDetail] = useState({
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    tel: '',
    mobilePhone: '',
    website: '',
    youtubeLink: '',
    linkedIn: '',
    description: '',
    country: '',
    city: '',
    county: '',
    address: '',
    postCode: '',
    action: '',
  });

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [websiteError, setWebsiteError] = useState('');
  const [youtubeError, setYoutubeError] = useState('');
  const [linkedInError, setLinkedInError] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setUserDetail(response.data.user);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);
      const userId = Cookies.get('creadUseDetail');

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (userId === ms) {
          getData();
        }
      });

      // Socket for delete user record
      socket.on('deleteUser', (ms) => {
        if (userId === ms) {
          props.history.push(ui);
        }
      });
    }
    clientSocket();
    getData();
  }, [baseURL, props.history, ui]);

  async function editData() {
    updateUserProfile(userDetail)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('user', `${userId}`);
        socket.emit('updateUser', `${userId}`);
        props.history.push(ui);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
        if (error.response.data.error) {
          setEmailError(error.response.data.error);
        }
      });
  }

  async function handleSubmit() {
    let reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    let isNum = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    let linkUrl =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    let phoneParsed = parsePhoneNumberFromString(userDetail.mobilePhone, '');
    let telParsed = parsePhoneNumberFromString(userDetail.tel, '');

    if (
      userDetail.firstName === '' ||
      userDetail.lastName === '' ||
      userDetail.email === '' ||
      (userDetail.mobilePhone && (!phoneParsed || !phoneParsed.isValid())) ||
      (userDetail.tel && (!telParsed || !telParsed.isValid())) ||
      (userDetail.mobilePhone &&
        isNum.test(userDetail.mobilePhone) === false) ||
      (userDetail.tel && isNum.test(userDetail.tel) === false) ||
      (userDetail.youtubeLink &&
        linkUrl.test(userDetail.youtubeLink) === false) ||
      (userDetail.linkedIn && linkUrl.test(userDetail.linkedIn) === false) ||
      (userDetail.website && linkUrl.test(userDetail.website) === false) ||
      reg.test(userDetail.email) === false
    ) {
      if (userDetail.firstName === '') {
        setFirstNameError('First name is required');
      } else {
        setFirstNameError(null);
      }
      if (userDetail.lastName === '') {
        setLastNameError('Last name is required');
      } else {
        setLastNameError(null);
      }

      if (userDetail.email === '') {
        setEmailError('Email is required');
      } else if (reg.test(userDetail.email) === false) {
        setEmailError('Email is incorrect');
      } else {
        setEmailError(null);
      }

      if (userDetail.mobilePhone && (!phoneParsed || !phoneParsed.isValid())) {
        setMobileError(
          'Please enter a valid phone number including country code (e.g. +44)'
        );
      } else if (
        userDetail.mobilePhone &&
        isNum.test(userDetail.mobilePhone) === false
      ) {
        setMobileError('Please enter a valid phone number');
      } else {
        setMobileError(null);
      }
      if (userDetail.tel && (!telParsed || !telParsed.isValid())) {
        setPhoneError(
          'Please enter a valid phone number including country code (e.g. +44)'
        );
      } else if (userDetail.tel && isNum.test(userDetail.tel) === false) {
        setPhoneError('Please enter a valid phone number');
      } else {
        setPhoneError(null);
      }
      if (
        userDetail.youtubeLink &&
        linkUrl.test(userDetail.youtubeLink) === false
      ) {
        setYoutubeError('Please enter a valid link');
      } else {
        setYoutubeError(null);
      }
      if (userDetail.linkedIn && linkUrl.test(userDetail.linkedIn) === false) {
        setLinkedInError('Please enter a valid link');
      } else {
        setLinkedInError(null);
      }
      if (userDetail.website && linkUrl.test(userDetail.website) === false) {
        setWebsiteError('Please enter a valid link');
      } else {
        setWebsiteError(null);
      }
    } else {
      setFirstNameError(null);
      setLastNameError(null);
      setEmailError(null);
      setMobileError(null);
      setYoutubeError(null);
      setWebsiteError(null);
      setLinkedInError(null);
      editData();
    }
  }

  return (
    <Page title={t('dEditPrifile')} content={t('dDocumentContent')} href="/">
      <div className="row">
        {children}
        <div className="col-md-9 col-12">
          <div className="col-12">
            <div className="card">
              <ProfileNavBar epr={epr} edpr={edpr} epic={epic} elog={elog} />
              <div className="card-body">
                <div className="col-12 user-detail-record">
                  <div className="row form-row">
                    <div className="detail-title col-12">
                      <h5>{t('userDetails')}</h5>
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('companyName')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.companyName}
                        placeholder="Company name"
                        value={userDetail.companyName || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            companyName: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('firstName')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.firstName}
                        placeholder="First name"
                        value={userDetail.firstName || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            firstName: e.target.value,
                          })
                        }
                      />
                      {firstNameError ? (
                        <p className="errorMessage"> {firstNameError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('lastName')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.lastName}
                        placeholder="Last name"
                        value={userDetail.lastName || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            lastName: e.target.value,
                          })
                        }
                      />
                      {lastNameError ? (
                        <p className="errorMessage"> {lastNameError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('email')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.email}
                        placeholder="email"
                        value={userDetail.email || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            email: e.target.value,
                          })
                        }
                      />
                      {emailError ? (
                        <p className="errorMessage"> {emailError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('telNumber')}
                        <i> eg: +442189652501</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.tel}
                        placeholder="telephone"
                        value={userDetail.tel || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            tel: e.target.value,
                          })
                        }
                      />
                      {phoneError ? (
                        <p className="errorMessage"> {phoneError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('mobileNumber')}
                        <i> eg: +447589652501</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.mobilePhone}
                        placeholder="mobile phone"
                        value={userDetail.mobilePhone || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            mobilePhone: e.target.value,
                          })
                        }
                      />
                      {mobileError ? (
                        <p className="errorMessage"> {mobileError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('gender')}
                        <i className="required-detail">*</i>
                      </label>
                      <select
                        className="form-control"
                        name={userDetail.gender}
                        value={userDetail.gender || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            gender: e.target.value,
                          })
                        }
                      >
                        <option value="">{t('selectGender')}</option>
                        <option value="Male">{t('male')}</option>
                        <option value="Femalle">{t('female')}</option>
                      </select>
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="detail-title col-12">
                      <h5>{t('dProfile')}</h5>
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('country')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.country}
                        placeholder="country"
                        value={userDetail.country || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            country: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('address')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.address}
                        placeholder="address"
                        value={userDetail.address || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            address: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('city')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.city}
                        placeholder="city"
                        value={userDetail.city || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            city: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('county')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.county}
                        placeholder="county"
                        value={userDetail.county || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            county: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('postCode')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.postCode}
                        placeholder="post code"
                        value={userDetail.postCode || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            postCode: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="row form-row">
                    <div className="detail-title col-12">
                      <h5>{t('additionalInformation')}</h5>
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('website')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.website}
                        placeholder="Website"
                        value={userDetail.website || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            website: e.target.value,
                          })
                        }
                      />
                      {websiteError ? (
                        <p className="errorMessage"> {websiteError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('youtube')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.youtubeLink}
                        placeholder="youtube"
                        value={userDetail.youtubeLink || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            youtubeLink: e.target.value,
                          })
                        }
                      />
                      {youtubeError ? (
                        <p className="errorMessage"> {youtubeError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>{t('linkedin')}</label>
                      <input
                        className="form-control"
                        type="text"
                        name={userDetail.linkedIn}
                        placeholder="linkedIn"
                        value={userDetail.linkedIn || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            linkedIn: e.target.value,
                          })
                        }
                      />
                      {linkedInError ? (
                        <p className="errorMessage"> {linkedInError}</p>
                      ) : null}
                    </div>
                    <div className="col-12 form-group">
                      <label>{t('profileSummary')}</label>
                      <textarea
                        className="form-control"
                        type="text"
                        rows="5"
                        name={userDetail.description}
                        placeholder="description"
                        value={userDetail.description || ''}
                        onChange={(e) =>
                          setUserDetail({
                            ...userDetail,
                            description: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="container row">
                  <div className="col-12">
                    <Link
                      to="#"
                      onClick={() => handleSubmit()}
                      className="btn-style btn-info mr-2"
                    >
                      {t('save')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(EditProfile);
