const navBar = {
  nWhoWeAre: 'Who we are',
  nPricing: 'Pricing',
  nFindALawyer: 'Find a lawyer',
  nDocumentBank: 'Document bank',
  nContact: 'Contact',
  nLogin: 'Login',
  nProfile: 'Profile',
  nDashboard: 'Dashboard',
  nLogout: 'Logout',
  logoutMessage: 'Are you sure you want to log out?',
};

export default navBar;
