import React, { useState } from 'react';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import io from 'socket.io-client';
import TopSection from '../../components/TopSection';
import { addUser } from '../../services/usersServives';
import client from '../../utils/client';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function SignUp(props) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [roleError, setRoleError] = useState('');

  let parsed = queryString.parse(props.location.search);
  let redirectTo = parsed.redirectTo;

  if (client.token !== undefined) {
    props.history.push('/profile');
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let reg = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (
      email === '' ||
      firstName === '' ||
      lastName === '' ||
      role === '' ||
      password === '' ||
      confirmPassword === '' ||
      password !== confirmPassword ||
      password.length < 8 ||
      reg.test(email) === false
    ) {
      if (!email) {
        setEmailError(t('emailRequired'));
      } else if (reg.test(email) === false) {
        setEmailError(t('wrongEmail'));
      } else {
        setEmailError(null);
      }

      if (firstName === '') {
        setFirstNameError(t('firstNameRequired'));
      } else {
        setFirstNameError(null);
      }
      if (lastName === '') {
        setLastNameError(t('lastNameRequired'));
      } else {
        setLastNameError(null);
      }
      if (role === '') {
        setRoleError(t('accountTypeRequired'));
      } else {
        setRoleError(null);
      }
      if (password === '') {
        setPasswordError(t('passwordRequired'));
      } else if (password.length < 8) {
        setPasswordError(t('passwordLenght'));
      } else {
        setPasswordError(null);
      }
      if (confirmPassword === '') {
        setConfirmPasswordError(t('passwordConfirmRequired'));
      } else if (password !== confirmPassword) {
        setConfirmPasswordError(t('passwordConfirmIncorrect'));
      } else {
        setConfirmPasswordError(null);
      }
    } else {
      setFirstNameError(null);
      setLastNameError(null);
      setEmailError(null);
      setPasswordError(null);
      setConfirmPasswordError(null);
      setRoleError(null);
      addData();
    }
  }
  async function addData() {
    addUser(firstName, lastName, email, password, role)
      .then((user) => {
        console.log(user.data.token);
        Cookies.set('token', user.data.token, {
          //httpOnly: true,
          //domain: 'localhost:3000/',
        });
        Cookies.set('creadUseDetail', user.data.user._id, {
          //httpOnly: true,
          //domain: 'localhost:3000/',
        });
        if (user.data.token && redirectTo) {
          props.history.push(redirectTo);
          window.location.reload();
        } else if (user.data.token) {
          window.location.reload();
        }
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('user', `${userId}`);
      })
      .catch((error) => {
        if (error.response.data.error) {
          setEmailError(error.response.data.error);
        }
        if (error.response.data.errorPassword) {
          setPasswordError(error.response.data.errorPassword);
        }
      });
  }
  return (
    <Page title={t('signUpTitle')} content={t('signUpContent')} href="/sign-up">
      <TopSection heading={t('signUpTitle')} />
      <div className="contact">
        <div className="main_container container">
          <div className="row justify-content-center">
            <div className="row mt-5 justify-content-center" data-aos="fade-up">
              <form className="commentForm" onSubmit={handleSubmit}>
                <div className="col-lg-10 php-email-form">
                  <div className="form-row">
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('firstName')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={firstName}
                        placeholder={t('firstName')}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {firstNameError ? (
                        <p className="errorMessage"> {firstNameError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('lastName')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name={lastName}
                        placeholder={t('lastName')}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {lastNameError ? (
                        <p className="errorMessage"> {lastNameError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('email')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        name={email}
                        placeholder={t('email')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      {emailError ? (
                        <p className="errorMessage"> {emailError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('accountType')}
                        <i className="required-detail">*</i>
                      </label>
                      <select
                        className="form-control"
                        name={role}
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                      >
                        <option value="">{t('selectAccountType')}</option>
                        <option value="604411c30079f15a6cd050f9">
                          {t('customer')}
                        </option>
                        <option value="604411b80079f15a6cd050f8">
                          {t('lawyer')}
                        </option>
                      </select>
                      {roleError ? (
                        <p className="errorMessage"> {roleError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('password')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        name={password}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />

                      {passwordError ? (
                        <p className="errorMessage"> {passwordError}</p>
                      ) : null}
                    </div>
                    <div className="col-md-6 col-12 form-group">
                      <label>
                        {t('passwordConfirm')}
                        <i className="required-detail">*</i>
                      </label>
                      <input
                        className="form-control"
                        type="password"
                        name={confirmPassword}
                        placeholder={t('passwordConfirm')}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />

                      {confirmPasswordError ? (
                        <p className="errorMessage"> {confirmPasswordError}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="btn-text-align">
                    <button type="submit"> {t('submit')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default SignUp;
