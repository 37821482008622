const documentBank = {
  dDocumentTitle: 'Document Bank',
  dDocumentContent: 'Document content',
  dProfile: 'Profile',
  dEditPrifile: 'Edit profile',
  dProfilePicture: 'Profile picture',
  dProfileLogo: 'Profile logo',
  dAreaExpertise: 'Areas of expertise',
  dMyDocument: 'My documents',
  dBAndS: 'Subscription & Billing',
  dChangePassword: 'Change password',
  dDocument: 'Document',
  dCategory: 'Category',
  dCategories: 'Categories',
  dSubCategories: 'Sub Categories',
  dSubCategory: 'Sub Category',
  dPrice: 'Price',
  dUploadDocument: 'Upload Document',
  dDownload: 'Download',
  dDownloadPro: 'Download all pro documents',
  dDownloadBusiness: 'Download all business documents',
  dViewBilling: 'View billing',
  dUpgradeBilling: 'Upgrade billing',
  dCancelBilling: 'Cancel billing',
  dSubscription: 'Subscription',
  dBilling: 'Billing',
  dBusiness: 'Business',
  dAnnual: 'Annual',
  dPro: 'Pro',
  dMonthly: 'Monthly',
  dNoSubscription: 'No Subscription',
  dReasonCancelling: 'Reason for cancelling',
  dReasonCancellingRequired: 'Reason for cancellation required',
  dCancellingMessage:
    'You will not be able to access in of our pro or business plan document if you cancel your billing. However, you can still access our free documents.',
  dBillingCancel: 'Are you sure you want to cancel your billing?',
  dSubIs: 'Your subscription is',
  dSumIsRe: 'You can click on the button below to start a new plan',
  dAcccessBusiness: 'Access our business documents',
  dAcccessPro: 'Access our pro documents',
  dAcccessFree: 'Access our free documents',
  dCannotAccess: 'You cannot access any record of this page.',
  dDocumentDownload: 'Document download',
  dLoading: 'Loading...',
  dPayDownload: 'Pay and Download',
  dRelatedDocuments: 'Related Documents',
  dSearchDocument: 'Search Documents',
  dSelectSubCategory: 'Select sub category',
  dClearFilter: 'Clear Filter',
  dCurrency: '£',
  dDocumentName: 'Document name',
  dViewDocumentBank: 'View document bank',
  dSubCategoryList: 'Sub category list',
  dEditDocumentBank: 'Edit Document Bank',
  dUpdateFile: 'Update File',
  dPlan: 'Plan',
  dUpdateRecord: ' Update Record',
  dFile: 'File',
  dDocumentBankName: 'Document Bank name',
  dAddNewRecord: ' Add New Record',
  dSelectPlan: 'Select Plan',
  dApproved: 'Approved',
  dUnapproved: 'Unapproved',
};

export default documentBank;
