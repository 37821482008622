import React from 'react';
import SidebarProfile from '../users/SideBarProfile';
import TopSection from '../../components/TopSection';
import AddDocumentBank from '../../components/document/add-document';

function DocumentBank() {
  return (
    <>
      <TopSection heading="Add document bank" />
      <div className="main_container about-us about-top">
        <div className="container">
          <div className="row">
            <SidebarProfile />

            <div className="col-md-8 col-12">
              <AddDocumentBank
                ui="/main-view-documents"
                ui2="/main-view-documents"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentBank;
