import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { viewAllRecordFilter } from '../../services/subCategoriesServives';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function SideDocumentFilter({
  onSubmit,
  filter,
  setFilter,
  status,
  setStatus,
  to,
  className,
}) {
  const [subCategories, setSubCategories] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function getSubCatgory() {
      viewAllRecordFilter()
        .then((response) => {
          setSubCategories(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    getSubCatgory();
  }, []);

  return (
    <div className={className}>
      <div className="well">
        <div className="block-document-side">
          <h3>{t('dSearchDocument')}</h3>

          <div className="row">
            <div className="col-12">
              <Formik
                onSubmit={onSubmit}
                initialValues={{
                  filter: filter || '',
                  status: status || '',
                }}
                enableReinitialize={true}
              >
                {({ submitForm }) => (
                  <Form className="searchFormDocument">
                    <select
                      className="input-side"
                      name="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      onChangeCapture={submitForm}
                    >
                      <option value="">{t('dSelectSubCategory')}</option>

                      {subCategories.map((subCategory) => (
                        <option key={subCategory._id} value={subCategory._id}>
                          {subCategory.name}
                        </option>
                      ))}
                    </select>
                    <span className="search-group">
                      <input
                        type="text"
                        name="filter"
                        id="filter"
                        placeholder="Search by document name..."
                        className="input-side-two"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                      <button className="submit input-side-three" type="submit">
                        <i className="nav-icon fas fa-search" />
                      </button>
                    </span>

                    <Link
                      className="clear"
                      to={to}
                      onClick={() => {
                        window.location.href = `${to}`;
                      }}
                    >
                      {t('dClearFilter')}
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideDocumentFilter;
