import React from 'react';
import EditPicture from '../../components/user-profile/edit-company-logo';
import SidebarProfile from './SideBarProfile';
import TopSection from '../../components/TopSection';

export default function UserProfile() {
  return (
    <>
      <TopSection heading="Company logo" />
      <div className="main_container about-us about-top">
        <div className="container">
          <EditPicture
            ui="/profile"
            epr="/profile"
            edpr="profile-edit-user"
            epic="/main-edit-user-profile-picture"
            elog="/main-edit-user-company-logo"
          >
            <SidebarProfile />
          </EditPicture>
        </div>
      </div>
    </>
  );
}
