import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { viewEachRecord } from '../../services/contactServives';

export default function EditUser(props) {
  const [eachRecord, setEachRecord] = useState({});
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      viewEachRecord(id)
        .then((response) => {
          setEachRecord(response.data);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for update contact record
      socket.on('updateContact', (ms) => {
        if (props.match.params.id === ms) {
          getEach();
        }
      });

      // Socket for delete document record
      socket.on('deleteContact', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push('/cms/contact-list');
        }
      });
    }
    clientSocket();
    getEach();
  }, []);

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <Link
                        to="/cms/contact-list"
                        className="btn btn-info mr-2"
                      >
                        View contact
                      </Link>
                    </h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 each-detail">
                            <h5>Deteail</h5>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Name</span>: {eachRecord.name}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Subject</span>: {eachRecord.subject}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Email</span>: {eachRecord.email}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Phone number</span>: {eachRecord.number}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Type</span>: {eachRecord.type}
                            </p>
                          </div>
                          <div className="col-md-6 col-12 each-p">
                            <p>
                              <span>Status</span>: {eachRecord.status}
                            </p>
                          </div>

                          <div className="col-12 each-p">
                            <p>
                              <span>Message:</span>
                            </p>
                            {eachRecord.message &&
                              eachRecord.message
                                ?.split('\n\n')
                                .map((paragraph) => (
                                  <p key={paragraph}>
                                    {paragraph
                                      .split('\n')
                                      .reduce((total, line) => [
                                        total,
                                        <br />,
                                        line,
                                      ])}
                                  </p>
                                ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
