import React from 'react';
import { Link } from 'react-router-dom';

import SidebarProfile from './SideBarProfile';
import EditUserProfile from '../../components/user-profile/edit-profile';

export default function UserProfile() {
  return (
    <div className="content-wrapper">
      <div>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div className="card-body">
                    <EditUserProfile
                      ui="/cms/user/profile"
                      epr="/cms/user/profile"
                      edpr="/cms-edit-user-profile"
                      epic="/cms-edit-user-profile-picture"
                      elog="/cms-edit-user-company-logo"
                    >
                      <SidebarProfile />
                    </EditUserProfile>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
