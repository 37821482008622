import React from 'react';
import TopSection from '../../../src/components/TopSection';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function Confirmation() {
  const { t } = useTranslation();
  return (
    <Page title={t('pConfirmation')} content={t('pContent')} href="/">
      <TopSection heading={t('pConfirmation')} />
      <div className="main_containerssss">
        <div className="main_container team section-bg">
          <div className="container">
            <div className="section-title" data-aos="fade-up">
              <h2>{t('pConfirmation')}</h2>
              <p>{t('pConfirmationText')}</p>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Confirmation;
