import React from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function TopSearch({
  setFilter,
  onSubmit,
  filter,
  to,
  classNameInvisible = null,
}) {
  const { t } = useTranslation();
  return (
    <div className="col-12 top-search">
      <div className="">
        <div className="cont-search">
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              filter: filter || '',
            }}
            enableReinitialize={true}
          >
            {({ submitForm }) => (
              <Form className="searchFor">
                <div className="search-document">
                  <input
                    type="text"
                    name="filter"
                    id="filter"
                    placeholder="Search by document name..."
                    className="input"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <button className="submit" type="submit">
                    <i className="nav-icon fas fa-search" />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <p className={classNameInvisible}>
            <Link
              className="clear"
              to={to}
              onClick={() => {
                window.location.href = `${to}`;
              }}
            >
              <span className="clear-input">{t('sClearInput')}</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TopSearch;
