import client from '../utils/client';

/**
 * Add service
 */

export async function postCheckout(token, product) {
  return await client.post(`/documents/checkout`, {
    token: token,
    product: product,
  });
}

export async function addRecord(data) {
  return await client.post('documents/', data);
}

/**
 * View  service
 */
export async function viewAllRecord(filter) {
  return await client.get(`/documents/${filter}`, {});
}
export async function viewAllFreeRecord(filter) {
  return await client.get(`/documents/free/${filter}`, {});
}
export async function viewAllBusinessRecord(filter) {
  return await client.get(`/documents/business/${filter}`, {});
}
export async function viewAllProRecord(filter) {
  return await client.get(`/documents/pro/${filter}`, {});
}
export async function getCountries() {
  return await client.get(`/documents/countries`, {});
}
export async function getUnapproved() {
  return await client.get(`/documents/unapproved`, {});
}

export async function viewAllRecordUser(filter) {
  return await client.get(`/documents/me/${filter}`, {});
}
export async function viewAllRecordHome() {
  return await client.get(`/documents/home`, {});
}
export async function viewSubRecord(filter) {
  return await client.get(`/documents/subDocumentBank/${filter}`, {});
}
export async function viewSearchRecord(filter) {
  return await client.get(`/documents/searchDocumentBank/${filter}`, {});
}
/**
 * View each service
 */
export async function viewEachRecord(id) {
  return await client.get(`/documents/${id}`, {});
}

export async function viewEachRecordUser(id) {
  return await client.get(`/documents/me/${id}`, {});
}

export async function viewEachRecordFrontend(id) {
  return await client.get(`/documents/frontend/${id}`, {});
}

/**
 * Update service
 */
export async function updateRecord(data, id) {
  return await client.put(`/documents/${id}`, data);
}

export async function updateRecordUser(data, id) {
  return await client.put(`/documents/me/${id}`, data);
}
/**
 * Delete service
 */
export async function deleteRecord(id) {
  return await client.remove(`/documents/${id}`, {});
}
export async function deleteRecordUser(id) {
  return await client.remove(`/documents/me/${id}`, {});
}

/**
 * Document bank download
 */

export async function addDocumentDownloaded(device, documentBank, user) {
  return await client.post(`/documentsDownload/`, {
    device: device,
    documentBank: documentBank,
    user: user,
  });
}

export async function viewAllDocumentDownloaded(filter) {
  return await client.get(`/documentsDownload/${filter}`, {});
}
export async function viewAllDocumentDownloadedCount() {
  return await client.get(`/documentsDownload/count`, {});
}
export async function viewAllDocumentDownloadedCountMonthly() {
  return await client.get(`/documentsDownload/countMonthly`, {});
}
export async function viewAllDocumentDownloadedCountWeekly() {
  return await client.get(`/documentsDownload/countWeekly`, {});
}

export async function deleteRecordDocumentDownloaded(id) {
  return await client.remove(`/documentsDownload/${id}`, {});
}
