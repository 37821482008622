import React from 'react';

function GetStarted() {
  return (
    <div className="main_container about-us">
      <div className="container">
        <div className="row no-gutters get-started-align">
          <div
            className="get-started-image col-md-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
            data-aos="fade-right"
          >
            <img src="/images/laptop.jpg" className="img-fluid" alt="" />
          </div>
          <div className="col-md-7 d-flex d-flex align-items-stretch">
            <div className="get-started-content d-flex flex-column justify-content-center">
              <h3 data-aos="fade-up">Getting started is simple</h3>
              <p data-aos="fade-up">
                With our tailored step by step guidance and support; build your
                business and give it a unique online presence. We also provide
                24-7 professional support with our highly qualified team at your
                service.
              </p>
              <div className="row">
                <div className="col-md-12 get-started" data-aos="fade-up">
                  <button
                    type="button"
                    className="btn-global"
                    //onClick={this.handleSubmit.bind(this)}
                  >
                    Get started
                    <span className="icon-started">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetStarted;
