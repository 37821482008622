import React, { useState, useEffect } from 'react';
import {
  Bar,
  Doughnut,
  Line,
  Pie,
  Radar,
  Scatter,
  PolarArea,
  Bubble,
} from 'react-chartjs-2';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import {
  countUser,
  viewUsersBillingCount,
  viewUsersAnnualChart,
} from '../../services/usersServives';
import { countSubscriber } from '../../services/contactServives';
import {
  viewAllDocumentDownloadedCount,
  viewAllDocumentDownloadedCountMonthly,
  viewAllDocumentDownloadedCountWeekly,
} from '../../services/documentBankServives';

export default function Content(props) {
  const [total, setTotal] = useState('');
  const [customer, setCustomer] = useState('');
  const [lawyer, setLawyer] = useState('');
  const [subscriber, setSubscriber] = useState('');
  const [documentDownload, setDocumentDownload] = useState([]);
  const [documentDownloadMonthly, setDocumentDownloadMonthly] = useState([]);
  const [documentDownloadWeekly, setDocumentDownloadWeekly] = useState([]);
  const [billingPlanCount, setBillingPlanCount] = useState([]);
  const [userChartData, setUserChartData] = useState({});
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting users record
      socket.on('user', (ms) => {
        getData();
        getAlluserBillingCount();
        getAlluserAnnualChart();
      });
      // Socket for getting contact record
      socket.on('contact', (ms) => {
        getSubscriber();
      });

      // Socket for getting contact record
      socket.on('documentDownload', (ms) => {
        getDocumentDownload();
      });
    }
    clientSocket();
    getData();
    getSubscriber();
    getDocumentDownload();
    getAlluserBillingCount();
    getAlluserAnnualChart();
  }, []);

  async function getData() {
    countUser()
      .then((response) => {
        setTotal(response.data.total);
        setLawyer(response.data.lawyers);
        setCustomer(response.data.customers);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  async function getSubscriber() {
    countSubscriber()
      .then((response) => {
        setSubscriber(response.data.total);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  async function getDocumentDownload() {
    // Get all document downloaded
    viewAllDocumentDownloadedCount()
      .then((response) => {
        setDocumentDownload(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });

    // Get monthly document downloaded
    viewAllDocumentDownloadedCountMonthly()
      .then((response) => {
        setDocumentDownloadMonthly(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });

    // Get weekly document downloaded
    viewAllDocumentDownloadedCountWeekly()
      .then((response) => {
        setDocumentDownloadWeekly(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  async function getAlluserBillingCount() {
    viewUsersBillingCount()
      .then((response) => {
        setBillingPlanCount(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }
  async function getAlluserAnnualChart() {
    viewUsersAnnualChart()
      .then((response) => {
        setUserChartData(response.data.chartData);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  return (
    <div className="content-wrapper">
      <div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 dash-top">
                <div className="row">
                  {/* First column */}
                  <div className="col-md-3 col-12">
                    <div className="col-12 dash-first-column">
                      <div className="col-12">
                        <div className="row">
                          <i className="dash-icon fas fa-user" />
                          <div className="dash-title">
                            <p>Total Users</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 dash-column-list">
                        <p>{total}</p>
                      </div>
                    </div>
                  </div>
                  {/* Second column */}
                  <div className="col-md-3 col-12">
                    <div className="col-12 dash-second-column">
                      <div className="col-12">
                        <div className="row">
                          <i className="dash-icon fas fa-user" />
                          <div className="dash-title">
                            <p>Total Subscribers</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 dash-column-list">
                        <p>{subscriber}</p>
                      </div>
                    </div>
                  </div>
                  {/* Third column */}
                  <div className="col-md-3 col-12">
                    <div className="col-12 dash-third-column">
                      <div className="col-12">
                        <div className="row">
                          <i className="dash-icon fas fa-user" />
                          <div className="dash-title">
                            <p>Total Lawyers</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 dash-column-list">
                        <p>{lawyer}</p>
                      </div>
                    </div>
                  </div>
                  {/* Fourth column */}
                  <div className="col-md-3 col-12">
                    <div className="col-12 dash-fourth-column">
                      <div className="col-12">
                        <div className="row">
                          <i className="dash-icon fas fa-user" />
                          <div className="dash-title">
                            <p>Total Customers</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 dash-column-list">
                        <p>{customer}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <table
                              id="example2"
                              className="table table-bordered table-hover"
                            >
                              <thead className="headerTable">
                                <tr>
                                  <th
                                    className="dash-first-theader"
                                    colspan="2"
                                  >
                                    Billing Plan
                                  </th>
                                </tr>
                              </thead>
                              <thead className="headerTable">
                                <tr>
                                  <th>Type</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {billingPlanCount.map((detail) => (
                                  <tr key={detail._id}>
                                    <td>{detail.plan}</td>
                                    <td>{detail.count}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6 col-12">
                            <table
                              id="example2"
                              className="table table-bordered table-hover"
                            >
                              <thead className="headerTable">
                                <tr>
                                  <th
                                    className="dash-first-theader"
                                    colspan="3"
                                  >
                                    All Documents Downloaded
                                  </th>
                                </tr>
                              </thead>
                              <thead className="headerTable">
                                <tr>
                                  <th>Document Added By</th>
                                  <th>Document</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {documentDownload.map((detail) => (
                                  <tr key={detail._id}>
                                    <td>
                                      {detail.user.firstName}{' '}
                                      {detail.user.lastName}
                                    </td>
                                    <td>{detail.name}</td>
                                    <td>{detail.count}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6 col-12">
                            <table
                              id="example2"
                              className="table table-bordered table-hover"
                            >
                              <thead className="headerTable">
                                <tr>
                                  <th
                                    className="dash-first-theader"
                                    colspan="3"
                                  >
                                    Monthly Documents Downloaded
                                  </th>
                                </tr>
                              </thead>
                              <thead className="headerTable">
                                <tr>
                                  <th>Document Added By</th>
                                  <th>Document</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {documentDownloadMonthly.map((detail) => (
                                  <tr key={detail._id}>
                                    <td>
                                      {detail.user.firstName}{' '}
                                      {detail.user.lastName}
                                    </td>
                                    <td>{detail.name}</td>
                                    <td>{detail.count}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          <div className="col-md-6 col-12">
                            <table
                              id="example2"
                              className="table table-bordered table-hover"
                            >
                              <thead className="headerTable">
                                <tr>
                                  <th
                                    className="dash-first-theader"
                                    colspan="3"
                                  >
                                    Weekly Documents Downloaded
                                  </th>
                                </tr>
                              </thead>
                              <thead className="headerTable">
                                <tr>
                                  <th>Document Added By</th>
                                  <th>Document</th>
                                  <th>Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {documentDownloadWeekly.map((detail) => (
                                  <tr key={detail._id}>
                                    <td>
                                      {detail.user.firstName}{' '}
                                      {detail.user.lastName}
                                    </td>
                                    <td>{detail.name}</td>
                                    <td>{detail.count}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <Bar
                          data={userChartData}
                          // options={{
                          //   title: {
                          //     display: true,
                          //     text: 'Largest City In ..',
                          //   },
                          //   legend: {
                          //     display: true,
                          //     position: 'right',
                          //   },
                          // }}
                          // width={10}
                          height={350}
                          options={{ maintainAspectRatio: false }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
