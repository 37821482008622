const general = {
  lawyerExpertLtd: 'LawyerExpert Ltd',
  lawyerExpert: 'LawyerExpert',
  tel: '+44 208 123 8313',
  emailAddress: 'info@lawyerexpert.com',
  email: 'Email',
  subscribe: 'Subscribe',
  message: 'Message',
  ok: 'OK',
  ourTeam: 'Our Team',
  ourTeamMessage:
    'Our founders come from a background in law and information technology with extensive experience in client relationship management across start-ups and global organisations in the UK and abroad. This creates a natural symbiosis between law and tech in our DNA, closely knit with focus on the commercial needs of our clients and partners.',
  ourPartners: 'Our Partners',
  ourPartnersMessage:
    'We partner with the best technology and law firms in the UK and abroad to ensure you have access to high quality, expert legal services. The main criteria we look for in the legal service providers we work with is integrity, competence, and shared values. We constantly expanding our global network of trusted partners, which creates the network effect necessary to maximise the value we bring to our clients. If you want to become a partner please contact us via the form on our Contact us page.',
  getOnThePath: 'Get on the path to results now',
  heroText:
    'We provide easy access to the right documents and legal services for your business or personal goals.',
  getStarted: ' GET STARTED',
  accessLegals: 'Access hundreds of legal documents.',
  accessLegalsText:
    ' Our Document Bank includes over 200 standard and specialised legal documents covering business and personal matters. Simply sign up and download the documents you need. If you can’t find what you are looking for please get in touch and we will provide a tailored service.',
  singUpForFree: 'Sign up for free to access our document bank™',
  accessOurDocumentBank: 'Access our document bank™',
  male: 'Male',
  female: 'Female',
  additionalInformation: 'Additional information',
  save: 'Save',
  cancel: 'Cancel',
  delete: 'Delete',
  profileImage: 'Profile Image',
  userProfile: 'User Profile',
  view: 'View',
  edit: 'Edit',
  picture: 'Picture',
  logo: 'Logo',
  update: 'Update',
  add: 'Add',
  checkDeleteRecord: 'Are you sure you want to delete this record?',
  subscription: 'Subscription',
  billing: 'Billing',
  upgrade: 'Upgrade',
  previous: 'Previous',
  next: 'Next',
  noRecordMatch: 'No records matching criteria',
  detail: 'Detail',
  details: 'Details',
};

export default general;
