import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

function SideBar() {
  return (
    <div className="small-size">
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/dashboard" className="brand-link">
          {/* <i className="fas fa-user user-icon"></i> */}
          <img
            className="flavicon-image"
            src="/images/logo/pic1.png"
            width="25"
            height="25"
            alt=""
          />
          <span className="brand-text font-weight-light">LawyerExpert</span>
        </Link>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li className="nav-item active">
                <NavLink to="/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt" />
                  <p>Dashboard</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms/user/profile" className="nav-link">
                  <i className="nav-icon fas fa-user" />
                  <p>Profile</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms/users" className="nav-link">
                  <i className="nav-icon fas fa-users" />
                  <p>Users</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms/users-billing" className="nav-link">
                  <i className="nav-icon fas fa-money-bill-wave" />
                  <p>Billing Plan</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms/roles" className="nav-link">
                  <i className="nav-icon fab fa-r-project" />
                  <p>Account Types</p>
                </NavLink>
              </li>

              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms/categories" className="nav-link">
                  <i className="nav-icon fas fa fw fa-chart-area" />
                  <p>Categories</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms-sub-categories-add" className="nav-link">
                  <i className="nav-icon fas fa-border-all" />
                  <p>Sub Categories</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms-document-bank-view" className="nav-link">
                  <i className="nav-icon fas fa-file-word" />
                  <p>Document Bank</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink
                  to="/cms-document-bank-view-download"
                  className="nav-link"
                >
                  <i className="nav-icon fas fa-download" />
                  <p>Downloaded</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms/contact-list" className="nav-link">
                  <i className="nav-icon fas fa-address-card" />
                  <p>Contact</p>
                </NavLink>
              </li>
              <li className="nav-item bashvoard-nav">
                <NavLink to="/cms/area-of-law" className="nav-link">
                  <i className="nav-icon fas fa-gavel" />
                  <p>Area of Law</p>
                </NavLink>
              </li>

              <li className="nav-item bashvoard-nav">
                <Link to="/" className="nav-link">
                  <i className="nav-icon fas fa-atlas" />
                  <p>Website</p>
                </Link>
              </li>
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
      {/* Content Wrapper. Contains page content */}
    </div>
  );
}
export default withRouter(SideBar);
