import React from 'react';
import TopSection from '../TopSection';

function CookiesPolicy() {
  return (
    <>
      <TopSection heading="Cookies Policy" />
      <div className="main_container about-us view-terms">
        <div className="container">
          <div className="row">
            <div className="col-12 main-headers">
              <div className="list-headers">
                <ol className="first-list">
                  <span>
                    <li>Introduction</li>
                    <p className="service-top-space">
                      This cookie policy explains how LAWYEREXPERT, a registered
                      trademark of LawyerExpert LTD, (collectively, the
                      “LAWYEREXPERT” or “we” or “us” or “our”) collect, use,
                      disclose, and safeguard your information when you visit
                      our websites including any other media form, media
                      channel, mobile website, or mobile application that may be
                      at any time related or connected thereto. Please read this
                      Cookies Policy carefully.
                    </p>
                    <p>
                      This cookie policy explains what these technologies are
                      and why we use them, as well as your rights to control our
                      use of them. In some cases we may use cookies to collect
                      personal information, or that becomes personal information
                      if we combine it with other information. In such cases our
                      privacy policy will apply in addition to this cookie
                      policy.
                    </p>
                    <p>
                      Having accurate information about you permits us to
                      provide you with a smooth, efficient, and customized
                      experience. We may share your information with our
                      affiliates, in which case we will require those affiliates
                      to honor this cookie policy. Affiliates may include our
                      parent company and any subsidiaries, joint venture
                      partners or other companies that we control or that are
                      under common control with us.
                    </p>
                  </span>
                  <span>
                    <li>What are cookies</li>
                    <p className="service-top-space">
                      Cookies are small data files that are placed on your
                      computer or mobile device when you visit a website.
                      Cookies are widely used by website owners to make their
                      websites work, or to work more efficiently, as well as to
                      provide reporting information.
                    </p>
                    <span>
                      <ul className="second-list">
                        <ul className="third-list">
                          <li>
                            Session Cookies: These are created temporarily when
                            you visit a website. As soon as the user leaves the
                            site/closes the browser, the session cookie is
                            deleted.
                          </li>
                          <li>
                            Persistent Cookies: These remain on your device and
                            is re-activated when the user visits the website
                            which created the respective persistent cookie.
                            These cookies expire after a certain period (set in
                            the file), or the persistent cookie can be removed
                            manually.
                          </li>
                        </ul>
                      </ul>
                    </span>
                  </span>
                  <span>
                    <li>Why do we use cookies</li>
                    <p className="service-top-space">
                      We use first and third party cookies for several reasons.
                      Some cookies are required for technical reasons for our
                      websites to operate, and we refer to these as “essential”
                      or “strictly necessary” cookies. Other cookies also enable
                      us to track and target the interests of our users to
                      enhance the experience on our products and services. In
                      addition to the What are cookies section above, you can
                      find further classification of the cookies we use on this
                      website below:
                    </p>
                    <p>
                      <b>Strictly Necessary cookies:</b>
                      <br />
                      These cookies are essential to enable you to move around
                      the website and use its features, such as accessing secure
                      areas of the website.
                    </p>
                    <p>
                      <b>Performance cookies:</b>
                      <br />
                      These cookies collect information about how visitors use a
                      website, for instance which pages visitors go to most
                      often, and if they get error messages from web pages.
                      These are cookies which don’t collect information that
                      identify a visitor. These cookies collect information that
                      aggregated and anonymous.
                    </p>
                    <p>
                      <b>Functionality cookies:</b>
                      <br />
                      These cookies allow the website to remember choices you
                      make (for example, the language or the region you are in
                      or for registration sites, your username) to provide an
                      enhanced and sometimes more personal browsing experience.
                      For instance, a website (not LAWYEREXPERT) may be able to
                      provide you with local traffic news by storing in a cookie
                      the region in which you are currently located. These
                      cookies can also be used to remember accessibility changes
                      you have made, and other parts of web pages you can
                      customize. They may also be used to provide services you
                      have asked for such as watching videos or commenting on a
                      blog. The information these cookies collect will largely
                      be anonymized and they do not track your browsing activity
                      on other websites.
                    </p>
                    <p>
                      <b>
                        Targeting/advertising/analytics/personalisation cookies:
                      </b>
                      <br />
                      These cookies are used to deliver adverts more relevant to
                      you and your interests. They are also used to limit the
                      number of time you see an advertisement as well as help
                      measure the effectiveness of the advertising campaign.
                    </p>
                  </span>
                  <span>
                    <li>How can I control cookies</li>
                    <p className="service-top-space">
                      You have the right to decide whether to accept or reject
                      cookies. You can set or amend your web browser controls to
                      accept or refuse cookies. If you choose to reject cookies,
                      you may still use our websites though your access to some
                      functionality and areas of our website may be restricted.
                      As to how you can refuse cookies through your web browser
                      controls vary from browser-to-browser, you should visit
                      your browser’s help menu for more information.
                    </p>
                  </span>
                  <span>
                    <li>How often will we update out cookie policy</li>
                    <p className="service-top-space">
                      We may update this cookie policy from time to time to
                      reflect, for example, changes to the cookies we use or for
                      other operational, legal or regulatory reasons. Please
                      therefore re-visit this cookie policy regularly to stay
                      informed about our use of cookies and related
                      technologies. The date at the Introduction section above
                      indicates when it was last updated.
                    </p>
                  </span>
                  <span>
                    <li>Contact us</li>
                    <p className="service-top-space">
                      If you have questions or comments about this cookie
                      policy, please contact us at <b>info@lawyerexpert.com</b>.
                    </p>
                  </span>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CookiesPolicy;
