import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';
import './Cards.css';
import CardItem from './CardItem';
import { Link, withRouter } from 'react-router-dom';
import { viewAllRecordHome } from '../../services/documentBankServives';
import client from '../../utils/client';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function Cards() {
  const { t } = useTranslation();
  const [documentBank, setDocumentBank] = useState([]);
  let token = client.token;
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getAll() {
      viewAllRecordHome()
        .then((response) => {
          setDocumentBank(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting document record
      socket.on('document', (ms) => {
        getAll();
      });
    }
    clientSocket();
    getAll();
  }, [baseURL]);

  return (
    <div className="cards">
      {token === undefined ? (
        <h4 className="doc-sing-up">
          <Link to="/sign-up">{t('singUpForFree')}</Link>
        </h4>
      ) : (
        <h4 className="doc-sing-up">
          <Link to="/document-bank">{t('accessOurDocumentBank')}</Link>
        </h4>
      )}
      <div className="cards__container" data-aos="fade-up">
        <div className="cards__wrapper">
          {documentBank.map((details) => (
            <ul key={details._id} className="cards__items">
              {token ? (
                <CardItem
                  src="images/banner/home-document.jpg"
                  text={details.name}
                  label={details.subCategory.name}
                  path={{
                    pathname: `/document-download/${details._id}/${details.subCategory.name}`,
                    search: `?status=${details.subCategory._id}`,
                  }}
                />
              ) : (
                <CardItem
                  src="images/banner/home-document.jpg"
                  text={details.name}
                  label={details.subCategory.name}
                  path={{
                    pathname: '/sign-in',
                    search: `?redirectTo=document-download/${details._id}/${details.subCategory.name}?status=${details.subCategory._id}`,
                  }}
                />
              )}
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Cards);
