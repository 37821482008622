import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { viewEachRecord, updateRecord } from '../../services/contactServives';

function DocumentBank(props) {
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      viewEachRecord(id)
        .then((response) => {
          setType(response.data.type);
          setStatus(response.data.status);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for update contact record
      socket.on('updateContact', (ms) => {
        if (props.match.params.id === ms) {
          getEach();
        }
      });

      // Socket for delete document record
      socket.on('deleteContact', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push('/cms/contact-list');
        }
      });
    }
    clientSocket();
    getEach();
  }, [props.match.params.id]);

  async function editData() {
    const id = props.match.params.id;
    updateRecord(status, id)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('contact', `${userId}`);
        socket.emit('updateContact', `${props.match.params.id}`);
        props.history.push('/cms/contact-list');
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <Link
                  className="btn btn-primary mr-2"
                  to={{ pathname: `/cms/contact-list` }}
                >
                  View contact list
                </Link>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="form-row">
                      <div className="col-md-6 col-12 form-group">
                        <label>
                          Type
                          <i className="required-detail">*</i>
                        </label>

                        <select
                          className="form-control"
                          name={type}
                          value={type}
                          disabled
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="contact">Contact</option>
                          <option value="connect">Connect</option>
                          <option value="subscribe">Subscribe</option>
                        </select>
                      </div>
                      <div className="col-md-6 col-12 form-group">
                        <label>
                          Status
                          <i className="required-detail">*</i>
                        </label>

                        <select
                          className="form-control"
                          name={status}
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="checked">checked</option>
                          <option value="unchecked">unchecked</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="card-header">
                    <h3 className="card-title">
                      <Link
                        to="#"
                        onClick={() => editData()}
                        className="btn btn-info mr-2"
                      >
                        Update Record
                      </Link>
                    </h3>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default DocumentBank;
