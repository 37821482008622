import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { viewUserProfile } from '../../services/usersServives';
import SidebarProfile from '../../cms/users/SideBarProfile';
import ViewProfileDetails from '../../components/user-profile/view-details';

export default function UserProfile(props) {
  const [user, setUsers] = useState({});
  const [role, setRole] = useState({});

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    viewUserProfile()
      .then((response) => {
        setUsers(response.data.user);
        setRole(response.data.user.role);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}

        {/* Main content */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div className="card-body">
                    <ViewProfileDetails
                      user={user}
                      role={role}
                      epr="/cms/user/profile"
                      edpr="/cms-edit-user-profile"
                      epic="/cms-edit-user-profile-picture"
                      elog="/cms-edit-user-company-logo"
                    >
                      <SidebarProfile />
                    </ViewProfileDetails>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>

        {/* /.content */}
      </div>
    </div>
  );
}
