import React from 'react';
import { Link } from 'react-router-dom';
import EditDocumentBank from '../../components/document/edit-document';

function DocumentBank(props) {
  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">{/* /.container-fluid */}</section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <EditDocumentBank
              ui="/cms-document-bank-view"
              ui2="/cms-document-bank-view"
            />
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default DocumentBank;
