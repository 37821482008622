import React from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function TopSearch({
  onSubmit,
  filter,
  initialValues,
  placeholder,
  onChange,
  onClick,
  to,
  classNameInvisible = null,
}) {
  const { t } = useTranslation();
  return (
    <div className="col-12 top-search">
      <div className="">
        <div className="cont-search">
          <p>
            {t('sRequestMessage')}
            <Link to="/contact">
              <button className="btn-global-small btn-space-bt">
                {t('sRequestDocument')}
                <i className="fas fa-angle-right"></i>
              </button>
            </Link>
          </p>

          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({ submitForm }) => (
              <Form className="searchFor">
                <div className="search-document">
                  <input
                    type="text"
                    name="filter"
                    placeholder={placeholder}
                    className="input"
                    value={filter}
                    onChange={onChange}
                  />
                  <button className="submit" type="submit">
                    <i className="nav-icon fas fa-search" />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <p className={classNameInvisible}>
            <Link className="clear" to={to} onClick={onClick}>
              <span className="clear-input">{t('sClearInput')}</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default TopSearch;
