import React, { useEffect, useState } from 'react';
import client from '../../src/utils/client';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import {
  logout,
  viewUserProfile,
  viewImage,
  viewUsersBillingCancelledCount,
} from '../services/usersServives';
import { getUnapproved } from '../services/documentBankServives';
const baseURLDocument = process.env.REACT_APP_BASE_URL_DOCUMENT;
const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

function Header(props) {
  const [user, setUsers] = useState({});
  const [count, setCount] = useState(0);
  const [countUnapproved, setCountUnapproved] = useState(0);
  const [userLogoDisplay, setUserLogoDisplay] = useState('');

  let token = client.token;

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting users record
      socket.on('user', (ms) => {
        tokenFunction();
      });

      // Socket for getting document bank record
      socket.on('document', (ms) => {
        tokenFunction();
      });
    }
    clientSocket();
    tokenFunction();
  }, [props.history]);

  async function tokenFunction() {
    if (token === undefined) {
      props.history.push('/');
    } else {
      viewUserProfile()
        .then((response) => {
          setUsers(response.data.user);
          if (
            !response.data.token ||
            response.data.user.role._id != '6044119e0079f15a6cd050f7'
          ) {
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });

      viewImage()
        .then((response) => {
          setUserLogoDisplay(response.data.userLogo);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });

      viewUsersBillingCancelledCount()
        .then((response) => {
          setCount(response.data.length);
        })
        .catch((error) => {
          console.log(error);
        });

      getUnapproved()
        .then((response) => {
          setCountUnapproved(response.data.length);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  async function logoutUser() {
    if (window.confirm('Are you sure you want to log out?')) {
      logout()
        .then((response) => {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          window.location.reload();
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            window.location.reload();
          }
        });
    }
  }
  async function viewNotification() {
    props.history.push('/cms/users-billing');
  }

  async function viewNotificationUnapproved() {
    props.history.push('/cms-document-bank-view');
  }

  return (
    <>
      <div className="small-size">
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="fake_url"
                role="button"
              >
                <i className="fas fa-bars" />
              </a>
            </li>
          </ul>

          <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown logoutDropdown">
              <button
                type="button"
                className="icon-button"
                onClick={() => viewNotificationUnapproved()}
              >
                <span className="fas fa-edit login-icon"></span>
                {countUnapproved > 0 ? (
                  <span className="badge-icon">{countUnapproved}</span>
                ) : null}
              </button>
            </li>
            <li className="nav-item dropdown logoutDropdown">
              <button
                type="button"
                className="icon-button"
                onClick={() => viewNotification()}
              >
                <span className="fas fa-bell login-icon"></span>
                {count > 0 ? <span className="badge-icon">{count}</span> : null}
              </button>
            </li>
            <li className="nav-item dropdown">
              <span className="btn">
                {userLogoDisplay ? (
                  <img
                    className="imageRound"
                    src={`${baseURLDocument}/images/${userLogoDisplay}`}
                    height="150"
                    width="150"
                  />
                ) : (
                  <i className="fas fa-user user-icon"></i>
                )}

                <span className="headerTitleName">
                  {user.firstName + ' ' + user.lastName}
                </span>
              </span>
            </li>
            <li className="nav-item dropdown logoutDropdown">
              <button className="btn" onClick={() => logoutUser()}>
                <i className="fas fa-sign-in-alt login-icon"></i> Logout
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default withRouter(Header);
