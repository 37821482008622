import React from 'react';
import TopSection from '../../components/TopSection';
import Clients from '../../components/utils/Clients';
import Teams from '../../components/utils/Teams';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function About() {
  const { t } = useTranslation();
  return (
    <Page title={t('aboutUs')} content={t('aContent')} href="/who-we-are">
      <TopSection heading={t('abWhoWeAre')} />
      <div className="main_container about-us about-top">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-md-8 d-flex align-items-stretch">
              <div className="content d-flex flex-column justify-content-center">
                <div className="row">
                  <div className="col-md-12 icon-box">
                    <i className="fas fa-gavel"></i>
                    <h4>{t('abWhoWeAre')}</h4>
                    <p>{t('abWhoWeAreFP')}</p>
                    <p className="about-space">{t('abWhoWeAreSP')}</p>
                  </div>
                  <div className="col-md-12 icon-box">
                    <i className="fas fa-bars"></i>
                    <h4>{t('abOurServices')}</h4>
                    <p>{t('abOurServicesFP')}</p>
                  </div>
                  <div className="col-md-12 icon-box">
                    <i className="fas fa-cube"></i>
                    <h4>{t('abOurVision')}</h4>
                    <p>{t('abOurVisionFP')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Teams />
      <Clients />
    </Page>
  );
}

export default About;
