import React from 'react';
import SidebarProfile from './SideBarProfile';
import EditUserProfile from '../../components/user-profile/billing-info';
import TopSection from '../../components/TopSection';

export default function UserBillingProfile() {
  return (
    <>
      <TopSection heading="Billing" />
      <div className="main_container about-us about-top">
        <div className="container">
          <EditUserProfile
            viewBilling="/user-billing"
            upgradeBilling="/user-upgrade-billing"
            cancelBilling="/user-cancel-billing"
          >
            <SidebarProfile />
          </EditUserProfile>
        </div>
      </div>
    </>
  );
}
