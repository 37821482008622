import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import TopSection from '../../components/TopSection';
import { viewLawyers } from '../../services/usersServives';
import { viewAllRecordFrontendSearch } from '../../services/subCategoriesServives';
import { viewAllRecordDisplay } from '../../services/userAreaOfLawServives';
import SideDocument from '../../components/document/SideDocument';
import TopSearch from '../../components/document/TopSearch';
import { Pagination } from '../../components/pagination/index';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function Lawyers(props) {
  const { t } = useTranslation();
  const [filter, setFilter] = useState('');
  const [users, setUsers] = useState([]);
  const [countDocuments, setCountDocument] = useState([]);
  const [userAreaOfLaw, setUserAreaOfLaw] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * page.perPage;
  const pageCount = Math.ceil(page.total / page.perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting users record
      socket.on('user', (ms) => {
        getData();
      });

      // Socket for update role record
      socket.on('userAreaOfLaw', (ms) => {
        getData();
      });

      // Socket for getting document record
      socket.on('document', (ms) => {
        getDocument();
      });

      // Socket for getting area of law record
      socket.on('areaOfLaw', (ms) => {
        getData();
      });
    }
    async function getData() {
      const filter = props.location.search;
      viewLawyers(filter)
        .then((response) => {
          setUsers(response.data.users);
          setPage(response.data);
          getExpertise();
        })
        .catch((error) => {
          console.log(error);
        });
    }
    clientSocket();
    getData();
    getDocument();
  }, [baseURL, props.location.search]);

  function handleSubmitFilter(values) {
    if (values.filter) {
      props.history.push(`/find-a-lawyer?filter=${values.filter}`);
    }
  }

  async function getExpertise() {
    viewAllRecordDisplay()
      .then((response) => {
        setUserAreaOfLaw(response.data);
      })
      .catch((error) => {});
  }

  async function getDocument() {
    viewAllRecordFrontendSearch()
      .then((response) => {
        setCountDocument(response.data.countDocument);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Page
      title={t('findALawyer')}
      href="/find-a-lawyer"
      content={t('findALawyerContent')}
    >
      <TopSection heading={t('findALawyer')} />
      <div className="main_container lawyers">
        <div className="container">
          <div className="row">
            <TopSearch
              onSubmit={handleSubmitFilter}
              placeholder={t('searchLawyers')}
              initialValues={{
                filter: filter || '',
              }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onClick={() => {
                window.location.href = `/find-a-lawyer`;
              }}
              to="/find-a-lawyer"
            />
          </div>
          <div className="row">
            {users.length ? (
              <div className="col-md-8 col-12">
                <div className="pgBtSp">
                  {users
                    .slice(pageVisited, pageVisited + page.perPage)
                    .map((details) => (
                      <ul
                        key={details._id}
                        className="findExpertList col-md-12"
                      >
                        <li>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-lg-3 col-md-5 col-12 logo_image">
                                {details.companyLogo ? (
                                  <img
                                    width="150"
                                    height="150"
                                    className="img-responsive"
                                    src={`${baseURL}/images/${details.companyLogo}`}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    width="150"
                                    height="150"
                                    className="img-responsive"
                                    src="/images/banner/placeholder.png"
                                    alt=""
                                  />
                                )}
                              </div>
                              <div className="col-lg-6 col-md-7 col-12 detail_style">
                                <p>
                                  <b>{t('lawFirm')}:</b> {details.companyName}{' '}
                                  <br />
                                  <b>{t('country')}:</b> {details.country}{' '}
                                  <br />
                                  <b>{t('city')}:</b> {details.city} <br />
                                  <b>{t('postCode')}:</b> {details.postCode}{' '}
                                  <br />
                                  {/* <b>Telephone:</b> {details.tel} <br />
                              <b>Mobile Phone:</b> {details.mobilePhone} <br />
                              <b>Email:</b> {details.email} */}
                                </p>
                                <p className="firm-specialise">
                                  {t('legalExpertise')}:<br />
                                  {userAreaOfLaw.map((detail) => (
                                    <span key={detail._id}>
                                      {details._id === detail.user._id && (
                                        <span>{detail.areaOfLaw.name} | </span>
                                      )}
                                    </span>
                                  ))}
                                </p>
                              </div>
                              <div className="col-lg-3 col-12">
                                <div className="row">
                                  <div className="col-lg-12 col-4 view_connect">
                                    <Link
                                      to={{
                                        pathname: `/view-profile/${details._id}`,
                                      }}
                                    >
                                      <p>{t('viewProfile')}</p>
                                    </Link>
                                  </div>
                                  <div className="col-lg-12 col-4 view_connect">
                                    <Link
                                      to={{
                                        pathname: `/connect/${details._id}`,
                                      }}
                                    >
                                      <p>{t('connect')}</p>
                                    </Link>
                                  </div>
                                  <div className="col-lg-12 col-4 view_connect">
                                    {details.website && (
                                      <a
                                        href={details.website}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <p>{t('website')}</p>
                                      </a>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    ))}
                </div>
                <Pagination pageCount={pageCount} changePage={changePage} />
              </div>
            ) : (
              <p className="no-record">{'noRecordMatch'}</p>
            )}

            {/* Document side  */}
            <SideDocument
              className="col-md-4 col-12 lawy-side"
              onSubmit={handleSubmitFilter}
              placeholder="Search lawyers..."
              initialValues={{
                filter: filter || '',
              }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onClick={() => {
                window.location.href = `/find-a-lawyer`;
              }}
              to="/find-a-lawyer"
              countDocuments={countDocuments}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(Lawyers);
