import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import classnames from 'classnames';

import styles from './page.module.scss';

export function Page({
  title = '',
  className = '',
  content = '',
  href = '',
  children,
}) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title key="title">{title ? `${title}` : 'LawyerExpert'}</title>
          <meta
            name="description"
            content={content ? content : 'LawyerExpert'}
          />
          <link rel="canonical" href={href ? href : '/'} />
        </Helmet>
      </HelmetProvider>
      <main className={classnames(styles.page, className)}>{children}</main>
    </>
  );
}
