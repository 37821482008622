import React, { useEffect } from 'react';
import './App.css';
import './pages/main.css';
import './cms/cms.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './components/heroSection/HeroSection.css';

import TermsAndService from './components/pageList/TermsAndService';
import PrivacyPolicy from './components/pageList/PrivacyPolicy';
import CookiesPolicy from './components/pageList/CookiesPolicy';
import LegalTechnology from './components/pageList/LegalTechnology';
import LegalMaketing from './components/pageList/LegalMaketing';
import Text from './components/utils/Text';

import TextPage from './pages/texts/TextPage';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Connect from './pages/lawyers/Connect';
import ViewProfile from './pages/lawyers/ViewProfile';
import Projects from './pages/projects/Projects';
import Services from './pages/services/Services';
import Packages from './pages/packages/Packages';
import Billing from './pages/packages/Billing';
import Lawyers from './pages/lawyers/Lawyers';
import Documents from './pages/documents/Documents';
import SubDocuments from './pages/documents/SubDocuments';
import FreeDocuments from './pages/documents/FreeDocuments';
import ProDocuments from './pages/documents/ProDocuments';
import BusinessDocuments from './pages/documents/BusinessDocuments';
import SearchDocuments from './pages/documents/SearchDocuments';
import DocumentDownload from './pages/documents/DocumentDownload';
import SignIn from './pages/users/SignIn';
import SignUp from './pages/users/SignUp';
import PasswordEmail from './pages/users/PasswordEmail';
import ResetPassword from './pages/users/ResetPassword';
import Profile from './pages/users/MainProfile';
import EditUserMainProfile from './pages/users/EditUserProfile';
import EditUserMainProfilePicture from './pages/users/EditUserProfilePicture';
import EditMainCompanyLogo from './pages/users/EditCompanyLogo';
import UserMainAreaOfLaw from './pages/users/AreaOfLaw';
import UserMainDocuments from './pages/users/Documents';
import UserBillingProfile from './pages/users/UserBillingProfile';
import BillingConfirmation from './pages/packages/Confirmation';
import UserUpgradeBillingProfile from './pages/users/UserUpgradeBillingProfile';
import CancelBilling from './pages/users/CancelBilling';
import ChangeMainPassword from './pages/users/ChangePassword';
import DeleteUser from './pages/users/DeleteUser';
import DocumentBankUser from './pages/documents/DocumentBank';
import DocumentBankUserAdd from './pages/documents/AddDocumentBank';
import DocumentBankUserEdit from './pages/documents/EditDocumentBank';
import DocumentBankUserView from './pages/documents/ViewEachDocument';

import Dashboard from './cms/dashboard/Dashboard';
import UserProfile from './cms/users/UserProfile';
import ChangePassword from './cms/users/ChangePassword';
import UserDocuments from './cms/users/Documents';
import UserAreaOfLaw from './cms/users/AreaOfLaw';
import ViewUsers from './cms/users/ViewUsers';
import ViewUsersBilling from './cms/users/ViewUsersBilling';
import EditUser from './cms/users/EditUser';
import EditUserBilling from './cms/users/EditUserBilling';
import ViewEachUser from './cms/users/ViewEachUser';
import EditUserProfile from './cms/users/EditUserProfile';
import EditUserProfilePicture from './cms/users/EditUserProfilePicture';
import EditCompanyLogo from './cms/users/EditCompanyLogo';
import AddCategories from './cms/categories/AddCategories';
import EditCategories from './cms/categories/EditCategories';
import AddSubCategories from './cms/subCategories/AddSubCategories';
import EditSubCategories from './cms/subCategories/EditSubCategories';
import EditDocumentBank from './cms/documentBank/EditDocumentBank';
import ViewDocumentBank from './cms/documentBank/DocumentBank';
import ViewDocumentBankDownload from './cms/documentBank/DocumentBankDownload';
import AddDocumentBank from './cms/documentBank/AddDocumentBank';
import ViewEachDocument from './cms/documentBank/ViewEachDocument';
import EditAreaOfLaw from './cms/areaOfLaw/EditAreaOfLaw';
import AddAreaOfLaw from './cms/areaOfLaw/AddAreaOfLaw';
import EditRole from './cms/roles/EditRole';
import AddRole from './cms/roles/AddRole';
import ContactList from './cms/contact/ContactList';
import AddNewsletter from './cms/contact/AddNewsletter';
import EditContact from './cms/contact/EditContact';
import ViewEachContact from './cms/contact/ViewEachContact';
import PageNotFound from './components/pageNotFound/PageNotFound';

import AppRoute from './components/layout/AppRoute';
import FrontendLayout from './components/layout/FrontendLayout';
import DashboardLayout from './components/layout/DashboardLayout';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  useEffect(() => {
    Aos.init({});
  }, []);

  return (
    <>
      <Router>
        <Switch>
          <AppRoute exact path="/" layout={FrontendLayout} component={Home} />
          <AppRoute
            path="/who-we-are"
            layout={FrontendLayout}
            component={About}
          />
          <Route path="/tex-page" component={TextPage} />
          <AppRoute
            path="/pricing"
            layout={FrontendLayout}
            component={Packages}
          />
          <AppRoute
            path="/billing"
            layout={FrontendLayout}
            component={Billing}
          />
          <AppRoute
            path="/contact"
            layout={FrontendLayout}
            component={Contact}
          />
          <AppRoute
            path="/projects"
            layout={FrontendLayout}
            component={Projects}
          />
          <AppRoute
            path="/services"
            layout={FrontendLayout}
            component={Services}
          />
          <AppRoute
            path="/find-a-lawyer"
            layout={FrontendLayout}
            component={Lawyers}
          />
          <AppRoute
            path="/connect/:id"
            layout={FrontendLayout}
            component={Connect}
          />
          <AppRoute
            path="/view-profile/:id"
            layout={FrontendLayout}
            component={ViewProfile}
          />
          <AppRoute
            path="/document-bank"
            layout={FrontendLayout}
            component={Documents}
          />
          <AppRoute
            path="/sub-documents/:subcategory"
            layout={FrontendLayout}
            component={SubDocuments}
          />
          <AppRoute
            path="/free-documents"
            layout={FrontendLayout}
            component={FreeDocuments}
          />
          <AppRoute
            path="/pro-documents"
            layout={FrontendLayout}
            component={ProDocuments}
          />
          <AppRoute
            path="/business-documents"
            layout={FrontendLayout}
            component={BusinessDocuments}
          />
          <AppRoute
            path="/search-documents/:name"
            layout={FrontendLayout}
            component={SearchDocuments}
          />

          <AppRoute
            path="/sign-in"
            layout={FrontendLayout}
            component={SignIn}
          />
          <AppRoute
            path="/sign-up"
            layout={FrontendLayout}
            component={SignUp}
          />

          <AppRoute
            path="/terms-of-service"
            layout={FrontendLayout}
            component={TermsAndService}
          />
          <AppRoute
            path="/privacy-policy"
            layout={FrontendLayout}
            component={PrivacyPolicy}
          />
          <AppRoute
            path="/cookies-policy"
            layout={FrontendLayout}
            component={CookiesPolicy}
          />
          <AppRoute
            path="/legal-technology"
            layout={FrontendLayout}
            component={LegalTechnology}
          />
          <AppRoute
            path="/legal-marketing"
            layout={FrontendLayout}
            component={LegalMaketing}
          />
          <AppRoute path="/text" layout={FrontendLayout} component={Text} />
          <AppRoute
            path="/forgot-password-email"
            layout={FrontendLayout}
            component={PasswordEmail}
          />
          <AppRoute
            path="/reset-password"
            layout={FrontendLayout}
            component={ResetPassword}
          />

          <AppRoute
            path="/profile"
            layout={FrontendLayout}
            component={Profile}
          />
          <AppRoute
            path="/profile-edit-user"
            layout={FrontendLayout}
            component={EditUserMainProfile}
          />

          <AppRoute
            path="/main-edit-user-profile-picture"
            layout={FrontendLayout}
            component={EditUserMainProfilePicture}
          />
          <AppRoute
            path="/main-view-user-documents"
            layout={FrontendLayout}
            component={UserMainDocuments}
          />
          <AppRoute
            path="/document-download/:id/:subcategory"
            layout={FrontendLayout}
            component={DocumentDownload}
          />
          <AppRoute
            path="/user-billing"
            layout={FrontendLayout}
            component={UserBillingProfile}
          />
          <AppRoute
            path="/confirmation"
            layout={FrontendLayout}
            component={BillingConfirmation}
          />
          <AppRoute
            path="/user-upgrade-billing"
            layout={FrontendLayout}
            component={UserUpgradeBillingProfile}
          />
          <AppRoute
            path="/user-cancel-billing"
            layout={FrontendLayout}
            component={CancelBilling}
          />
          <AppRoute
            path="/main-view-user-change-password"
            layout={FrontendLayout}
            component={ChangeMainPassword}
          />
          <AppRoute
            path="/delete-user"
            layout={FrontendLayout}
            component={DeleteUser}
          />

          <AppRoute
            path="/main-edit-user-company-logo"
            layout={FrontendLayout}
            component={EditMainCompanyLogo}
          />
          <AppRoute
            path="/main-view-user-area-of-law"
            layout={FrontendLayout}
            component={UserMainAreaOfLaw}
          />
          <AppRoute
            path="/main-view-document-add"
            layout={FrontendLayout}
            component={DocumentBankUserAdd}
          />
          <AppRoute
            path="/main-view-document-edit/:id"
            layout={FrontendLayout}
            component={DocumentBankUserEdit}
          />
          <AppRoute
            path="/main-view-document-view/:id"
            layout={FrontendLayout}
            component={DocumentBankUserView}
          />
          <AppRoute
            path="/main-view-documents"
            layout={FrontendLayout}
            component={DocumentBankUser}
          />

          <AppRoute
            path="/dashboard"
            layout={DashboardLayout}
            component={Dashboard}
          />
          <AppRoute
            path="/cms/users"
            layout={DashboardLayout}
            component={ViewUsers}
          />
          <AppRoute
            path="/cms/users-billing"
            layout={DashboardLayout}
            component={ViewUsersBilling}
          />
          <AppRoute
            path="/cms/user/profile"
            layout={DashboardLayout}
            component={UserProfile}
          />
          <AppRoute
            path="/cms-view-user-documents"
            layout={DashboardLayout}
            component={UserDocuments}
          />
          <AppRoute
            path="/cms-view-user-change-password"
            layout={DashboardLayout}
            component={ChangePassword}
          />
          <AppRoute
            path="/cms-view-user-area-of-law"
            layout={DashboardLayout}
            component={UserAreaOfLaw}
          />
          <AppRoute
            path="/cms-edit-user/:id"
            layout={DashboardLayout}
            component={EditUser}
          />
          <AppRoute
            path="/cms-edit-user-billing/:id"
            layout={DashboardLayout}
            component={EditUserBilling}
          />
          <AppRoute
            path="/cms-each-user/:id"
            layout={DashboardLayout}
            component={ViewEachUser}
          />
          <AppRoute
            path="/cms-edit-user-profile"
            layout={DashboardLayout}
            component={EditUserProfile}
          />
          <AppRoute
            path="/cms-edit-user-profile-picture"
            layout={DashboardLayout}
            component={EditUserProfilePicture}
          />
          <AppRoute
            path="/cms-edit-user-company-logo"
            layout={DashboardLayout}
            component={EditCompanyLogo}
          />
          <AppRoute
            path="/cms/categories"
            layout={DashboardLayout}
            component={AddCategories}
          />
          <AppRoute
            path="/cms-categories-edit/:id"
            layout={DashboardLayout}
            component={EditCategories}
          />
          <AppRoute
            path="/cms-sub-categories-add"
            layout={DashboardLayout}
            component={AddSubCategories}
          />
          <AppRoute
            path="/cms-sub-categories-edit/:id"
            layout={DashboardLayout}
            component={EditSubCategories}
          />
          <AppRoute
            path="/cms-document-bank-view"
            layout={DashboardLayout}
            component={ViewDocumentBank}
          />
          <AppRoute
            path="/cms-document-bank-view-download"
            layout={DashboardLayout}
            component={ViewDocumentBankDownload}
          />
          <AppRoute
            path="/cms-document-bank-add"
            layout={DashboardLayout}
            component={AddDocumentBank}
          />
          <AppRoute
            path="/cms-document-bank-edit/:id"
            layout={DashboardLayout}
            component={EditDocumentBank}
          />
          <AppRoute
            path="/cms-each-document/:id"
            layout={DashboardLayout}
            component={ViewEachDocument}
          />
          <AppRoute
            path="/cms/area-of-law"
            layout={DashboardLayout}
            component={AddAreaOfLaw}
          />
          <AppRoute
            path="/cms-area-of-law-edit/:id"
            layout={DashboardLayout}
            component={EditAreaOfLaw}
          />
          <AppRoute
            path="/cms/roles"
            layout={DashboardLayout}
            component={AddRole}
          />
          <AppRoute
            path="/cms/role/:id/edit"
            layout={DashboardLayout}
            component={EditRole}
          />
          <AppRoute
            path="/cms/contact-list"
            layout={DashboardLayout}
            component={ContactList}
          />
          <AppRoute
            path="/cms-newsletter-add"
            layout={DashboardLayout}
            component={AddNewsletter}
          />
          <AppRoute
            path="/cms/contact/:id/edit"
            layout={DashboardLayout}
            component={EditContact}
          />
          <AppRoute
            path="/cms/contact/:id"
            layout={DashboardLayout}
            component={ViewEachContact}
          />
          <AppRoute layout={FrontendLayout} component={PageNotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
