import React from 'react';
import { Link } from 'react-router-dom';
import Cards from '../../components/cards/Cards';
import HeroSection from '../../components/heroSection/HeroSection';
import AccessMoreDoc from '../../components/utils/AccessMoreDocuments';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function Home() {
  const { t } = useTranslation();
  return (
    <Page title={t('hTitle')} content={t('hContent')} href="/">
      <HeroSection />
      <AccessMoreDoc />
      <div className="main_container services">
        <div className="container">
          <div className="row first-top">
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up">
                <div className="icon">
                  <i className="fas fa-gavel"></i>
                </div>
                <h4 className="title">
                  <Link to="/find-a-lawyer">{t('findALawyer')}</Link>
                </h4>
                <p className="description">{t('findALawyerDescription')}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div className="icon">
                  <i className="fas fa-users"></i>
                </div>
                <h4 className="title">
                  <Link to="/pricing">{t('membershipPlan')}</Link>
                </h4>
                <p className="description">{t('membershipPlanDescription')}</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div className="icon">
                  <i className="fas fa-download"></i>
                </div>
                <h4 className="title">
                  <Link to="/document-bank">{t('downloadLegalDocuments')}</Link>
                </h4>
                <p className="description">
                  {t('downloadLegalDocumentsDescription')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="our-mission" className="about-us">
        <div className="container">
          <div className="row no-gutters">
            <div
              className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start"
              data-aos="fade-right"
            ></div>
            <div className="col-xl-7 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch">
              <div className="content d-flex flex-column justify-content-center home-control">
                <h4 className="home-mission-title" data-aos="fade-up">
                  {t('hOurMission')}
                </h4>
                <p className="home-mission" data-aos="fade-up">
                  {t('hOurMissionDescription')}
                </p>
                <div className="row">
                  <div className="col-md-12 icon-box" data-aos="fade-up">
                    <i className="fas fa-gavel"></i>
                    <h4>{t('findALawyer')}</h4>
                    <p>{t('flDescription')}</p>
                  </div>
                  <div
                    className="col-md-12 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="fas fa-chart-line"></i>
                    <h4>{t('membershipPlan')}</h4>
                    <p>{t('mspDecriptions')}</p>
                  </div>
                  <div
                    className="col-md-12 icon-box"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <i className="fas fa-bars"></i>
                    <h4>{t('nDocumentBank')}</h4>
                    <p>{t('docBankDecription')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Cards />
    </Page>
  );
}
export default Home;
