import client from '../utils/client';

/**
 * Add service
 */
export async function addRecord(name, catName) {
  return await client.post(`/subCategories/`, {
    name: name,
    category: catName,
  });
}

/**
 * View  service
 */
export async function viewAllRecord() {
  return await client.get(`/subCategories/`, {});
}
export async function viewAllRecordFrontend(filter) {
  return await client.get(`/subCategories/frontent/${filter}`, {});
}
export async function viewAllRecordFrontendSearch() {
  return await client.get(`/subCategories/frontent`, {});
}
export async function viewAllRecordFilter() {
  return await client.get(`/subCategories/`, {});
}

/**
 * View each service
 */
export async function viewEachRecord(id) {
  return await client.get(`/subCategories/${id}`, {});
}

/**
 * Update service
 */
export async function updateRecord(name, catName, id) {
  return await client.put(`/subCategories/${id}`, {
    name: name,
    category: catName,
  });
}

/**
 * Delete service
 */
export async function deleteRecord(id) {
  return await client.remove(`/subCategories/${id}`, {});
}
