import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function HeroSection() {
  const { t } = useTranslation();
  return (
    <div className="hero-container">
      {/* <video src="/videos/video-1.mp4" autoPlay loop muted /> */}
      <img src="/images/banner/slideone.jpg" alt="" />
      <div className="hero-text-wrap " data-aos="fade-up">
        <h1 className="hero-title">{t('getOnThePath')}</h1>
        <p className="hero-text">{t('heroText')}</p>
        <div className="hero-btns">
          <Link to="/document-bank">
            <Button buttonStyle="btn--outline" buttonSize="btn--large">
              {t('getStarted')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
