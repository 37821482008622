const pricing = {
  pricing: 'Pricing',
  pContent: 'Pricing content',
  pFiveFree: 'Access to 5 legal documents',
  pFree: 'Free',
  pPro: 'Pro',
  pPrice: 'Price',
  pPackage: 'Package',
  pBussiness: 'Business',
  pFreeFoever: 'Free Forever',
  pStartNow: 'Start Now',
  p2WeekFreeTrial: '2 Weeks Free Trial',
  pSecurePAccount: 'Secure Personal Account',
  pdownloadEdit: 'Download and edit on your device',
  pAccess247: 'Access on demand 24/7',
  pUnlimetedAccessBussiness: 'Unlimited Access to Business legal documents',
  pUnlimetedAccessPersonal: 'Unlimited Access to Personal legal documents',
  pFreePriceYear: '<sup>£</sup>0<span> / year</span>',
  pFreePriceMonth: '<sup>£</sup>0<span> / month</span>',
  pProPriceYear: '<sup>£</sup>490<span> / year</span>',
  pProPriceMonth: '<sup>£</sup>49<span> / month</span>',
  pBuPriceYear: '<sup>£</sup>990<span> / year</span>',
  pBuPriceMonth: '<sup>£</sup>99<span> / month</span>',
  pQuestions: 'Have a question?',
  pQuestionsText:
    'If you have a question or want to discuss your requirements, please follow the button below and send us your enquiry. We will get back to you as soon as possible.',
  pAskNow: 'Ask Now',
  pSavePro: 'Save £98 per year',
  pSaveBusiness: 'Save £198 per year',
  pConfirmation: 'Confirmation',
  pConfirmationText:
    'Our team ensure your network is running at the best frequency and network layer, which ensure the business process of data storage.',
};

export default pricing;
