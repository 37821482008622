import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import {
  viewEachRecord,
  viewEachRecordUser,
} from '../../services/documentBankServives';
import { viewEachRecordCategory } from '../../services/categoriesServives';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function ViewEachDocumentBank({ children, ui, ui2, ...props }) {
  const [eachRecord, setEachRecord] = useState({});
  const [subCategory, setSubcategory] = useState('');
  const [user, setUser] = useState('');
  const [category, setCategory] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      if (ui === '/main-view-documents') {
        viewEachRecordUser(id)
          .then((response) => {
            setEachRecord(response.data);
            setSubcategory(response.data.subCategory);
            setUser(response.data.user);

            //category
            viewEachRecordCategory(response.data.subCategory.category)
              .then((response) => {
                setCategory(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (ui === '/cms-document-bank-view') {
        viewEachRecord(id)
          .then((response) => {
            setEachRecord(response.data);
            setSubcategory(response.data.subCategory);
            setUser(response.data.user);

            //category
            viewEachRecordCategory(response.data.subCategory.category)
              .then((response) => {
                setCategory(response.data);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for update document record
      socket.on('updateDocument', (ms) => {
        if (props.match.params.id === ms) {
          getEach();
        }
      });

      // Socket for delete document record
      socket.on('deleteDocument', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push(ui);
        }
      });

      // Socket for update sub category record
      socket.on('updateSubCategory', (ms) => {
        getEach();
      });

      // Socket for update category record
      socket.on('category', (ms) => {
        getEach();
      });
    }
    clientSocket();
    getEach();
  }, [baseURL, props.history, props.match.params.id, ui]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <Link to={ui} className="btn-style btn-info mr-2">
                {t('view')}
              </Link>

              <Link to={ui2} className="btn-style btn-primary mr-2">
                {t('add')}
              </Link>
            </div>
          </div>
          {/* /.card-header */}
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12 each-detail">
                    <h5> {t('details')}</h5>
                  </div>
                  <div className="col-md-6 col-12 each-p">
                    <p>
                      <span>{t('dDocumentName')}</span>: {eachRecord.name}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 each-p">
                    <p>
                      <span>{t('dCategory')}</span>: {category.name}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 each-p">
                    <p>
                      <span>{t('dSubCategory')}</span>: {subCategory.name}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 each-p">
                    <p>
                      <span>{t('user')}</span>:{' '}
                      {user.firstName + ' ' + user.lastName}
                    </p>
                  </div>
                  {eachRecord.price && (
                    <div className="col-md-6 col-12 each-p">
                      <p>
                        <span>{t('pPrice')}</span>: £{eachRecord.price}
                      </p>
                    </div>
                  )}
                  <div className="col-md-6 col-12 each-p">
                    <p>
                      <span>{t('pPackage')}</span>: {eachRecord.packagePlan}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 each-p">
                    <p>
                      <span>{t('country')}</span>: {eachRecord.country}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 each-p">
                    <p>
                      <span>{t('status')}</span>: {eachRecord.status}
                    </p>
                  </div>
                  <div className="col-12 each-p">
                    <p>
                      <span>{t('description')}:</span>
                    </p>
                    {eachRecord.description?.split('\n\n').map((paragraph) => (
                      <p key={paragraph}>
                        {paragraph
                          .split('\n')
                          .reduce((total, line) => [total, <br />, line])}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
        {/* /.card */}
      </div>
      {/* /.col */}
    </div>
  );
}

export default withRouter(ViewEachDocumentBank);
