import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { viewUserProfile } from '../../services/usersServives';
import { SubscriptionNavBar } from './subscriptionNavBar';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function UserBilling({
  viewBilling,
  upgradeBilling,
  cancelBilling,
  children,
  ...props
}) {
  const [user, setUser] = useState({});
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setUser(response.data.user);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);
      const userId = Cookies.get('creadUseDetail');

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (userId === ms) {
          getData();
        }
      });
    }
    clientSocket();

    getData();
  }, [baseURL, props.history]);

  function displayInfo() {
    if (
      user.billing === 'annual' &&
      user.subscription === 'business' &&
      (user.subscriptionActivate === 'activate' ||
        user.subscriptionActivate === 'upgradeToBusiness')
    ) {
      return (
        <>
          <b>{t('dSubscription')}:</b> {t('dBusiness')} <br />{' '}
          <b>{t('dBilling')}:</b> {t('dAnnual')}
        </>
      );
    } else if (
      user.billing === 'annual' &&
      user.subscription === 'pro' &&
      user.subscriptionActivate === 'activate'
    ) {
      return (
        <>
          <b>{t('dSubscription')}:</b> {t('dPro')} <br />{' '}
          <b>{t('dBilling')}:</b> {t('dAnnual')}
        </>
      );
    } else if (
      user.billing === 'monthly' &&
      user.subscription === 'business' &&
      (user.subscriptionActivate === 'activate' ||
        user.subscriptionActivate === 'upgradeToBusiness')
    ) {
      return (
        <>
          <b>{t('dSubscription')}:</b> {t('dBusiness')} <br />{' '}
          <b>{t('dBilling')}:</b> {t('dMonthly')}
        </>
      );
    } else if (
      user.billing === 'monthly' &&
      user.subscription === 'pro' &&
      user.subscriptionActivate === 'activate'
    ) {
      return (
        <>
          <p>
            <b>{t('dSubscription')}:</b> {t('dPro')} <br />{' '}
            <b>{t('dBilling')}:</b> {t('dMonthly')}
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>{t('dNoSubscription')}</p>
        </>
      );
    }
  }

  return (
    <Page title={t('dViewBilling')} content={t('dDocumentContent')} href="/">
      <div className="row">
        {children}
        <div className="col-md-9 col-12">
          <div className="col-12">
            <div className="card">
              <SubscriptionNavBar
                viewBilling={viewBilling}
                upgradeBilling={upgradeBilling}
                cancelBilling={cancelBilling}
              />
              <div className="card-body">{displayInfo()}</div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(UserBilling);
