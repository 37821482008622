import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Form, Formik } from 'formik';

import {
  viewAllRecord,
  deleteRecord,
  getCountries,
} from '../../services/documentBankServives';
import { viewAllRecordFilter } from '../../services/subCategoriesServives';
import { Pagination } from '../../components/pagination/index';
const baseURLDocument = process.env.REACT_APP_BASE_URL_DOCUMENT;
const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

function DocumentBank(props) {
  const [documentBank, setDocumentBank] = useState([]);
  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('');
  const [approved, setApproved] = useState('');
  const [country, setCountry] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);

  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * page.perPage;
  const pageCount = Math.ceil(page.total / page.perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting document record
      socket.on('document', (ms) => {
        getAll();
      });

      // Socket for update sub category record
      socket.on('subCategory', (ms) => {
        getSubCatgory();
        getAll();
      });
    }
    clientSocket();
    getAll();
    getSubCatgory();
    getCountry();
  }, [props.location.search]);

  async function getAll() {
    const filter = props.location.search;
    viewAllRecord(filter)
      .then((response) => {
        //setDocumentBank(response.data.documentBank.slice(0, 2));
        setDocumentBank(response.data.documentBank);
        setPage(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }
  async function getSubCatgory() {
    viewAllRecordFilter()
      .then((response) => {
        setSubCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getCountry() {
    getCountries()
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  function handleSubmit(values) {
    props.history.push(
      `/cms-document-bank-view?filter=${values.filter}&status=${values.status}&country=${values.country}&approved=${values.approved}`
    );
    getAll();
  }

  async function deleteData(id) {
    if (
      window.confirm(
        'Are you sure you want to delete this ducument bank? It will also be removed from users record and cannot be undone.'
      )
    ) {
      deleteRecord(id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('document', `${userId}`);
          socket.emit('deleteDocument', `${id}`);
        })
        .catch((error) => {
          alert('error');
        });
    }
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 search-side">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    filter: filter || '',
                    status: status || '',
                    country: country || '',
                    approved: approved || '',
                  }}
                  enableReinitialize={true}
                >
                  {({ submitForm }) => (
                    <Form className="searchForm">
                      <select
                        className="input"
                        name="approved"
                        value={approved}
                        onChange={(e) => setApproved(e.target.value)}
                        onChangeCapture={submitForm}
                      >
                        <option value="">Select status</option>
                        <option value="approved">Approved</option>
                        <option value="unapproved">Unapproved</option>
                      </select>
                      <select
                        className="input"
                        name="country"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        onChangeCapture={submitForm}
                      >
                        <option value="">Select country</option>

                        {countries.map((country) => (
                          <option key={country} value={country}>
                            {country}
                          </option>
                        ))}
                      </select>
                      <select
                        className="input"
                        name="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        onChangeCapture={submitForm}
                      >
                        <option value="">Select sub category</option>

                        {subCategories.map((subCategory) => (
                          <option key={subCategory._id} value={subCategory._id}>
                            {subCategory.name}
                          </option>
                        ))}
                      </select>
                      <input
                        type="text"
                        name="filter"
                        id="filter"
                        placeholder="Search by document name..."
                        className="input"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                      <button className="submit submitEsp" type="submit">
                        Search
                      </button>

                      <Link
                        className="clear"
                        to="/cms-document-bank-view"
                        onClick={() => {
                          window.location.href = `/cms-document-bank-view`;
                        }}
                      >
                        Clear Filter
                      </Link>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="card-title">
                      <Link
                        to="/cms-document-bank-add"
                        className="btn btn-primary mr-2"
                      >
                        Upload Document
                      </Link>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-hover"
                    >
                      <thead className="headerTable">
                        <tr>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Plan</th>
                          <th>Sub Categories</th>
                          <th>Document</th>
                          <th>Country</th>
                          <th>Status</th>
                          <th colSpan="3" className="col-span">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentBank
                          .slice(pageVisited, pageVisited + page.perPage)
                          .map((detail) => {
                            return (
                              <tr key={detail._id}>
                                <td>{detail.name}</td>
                                <td>
                                  {' '}
                                  {detail.price ? `£${detail.price}` : 'Free'}
                                </td>
                                <td>{detail.packagePlan}</td>
                                <td>{detail.subCategory.name}</td>
                                <td>
                                  <a
                                    className="doc-download"
                                    target="_blank"
                                    href={`${baseURLDocument}/images/${detail.document}`}
                                  >
                                    Download
                                  </a>
                                </td>
                                <td>{detail.country}</td>
                                <td>{detail.status}</td>
                                <td className="col-span">
                                  <Link
                                    className="btn btn-secondary mr-2"
                                    to={{
                                      pathname: `/cms-each-document/${detail._id}`,
                                    }}
                                  >
                                    View
                                  </Link>
                                </td>
                                <td className="col-span">
                                  <Link
                                    className="btn btn-primary mr-2"
                                    to={{
                                      pathname: `/cms-document-bank-edit/${detail._id}`,
                                    }}
                                  >
                                    Edit
                                  </Link>
                                </td>
                                <td className="col-span">
                                  <Link
                                    to="#"
                                    onClick={() => deleteData(detail._id)}
                                    className="btn btn-danger mr-2 disabled-lin"
                                  >
                                    Delete
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination pageCount={pageCount} changePage={changePage} />
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default DocumentBank;
