import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import { BsBoxArrowInDown } from 'react-icons/bs';
import { isBrowser, isMobile } from 'react-device-detect';
import SidebarProfile from '../users/SideBarProfile';
import TopSection from '../../components/TopSection';
import { Pagination } from '../../components/pagination/index';

import { viewAllRecordUser } from '../../services/userDocumentServives';
import { viewUserProfile } from '../../services/usersServives';
import { addDocumentDownloaded } from '../../services/documentBankServives';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function DocumentBank(props) {
  const [documentBank, setDocumentBank] = useState([]);
  const [user, setUser] = useState({});
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * page.perPage;
  const pageCount = Math.ceil(page.total / page.perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setUser(response.data.user);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    async function getAll() {
      viewAllRecordUser()
        .then((response) => {
          setDocumentBank(response.data.userDocument);
          setPage(response.data);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }

    getAll();
    getData();
  }, [props.history, props.location.search]);

  async function addDocumentDownload(id) {
    const documentBank = id;
    let device = null;
    const user = '';
    if (isMobile) {
      device = 'Mobile';
    } else if (isBrowser) {
      device = 'Browser';
    }
    addDocumentDownloaded(device, documentBank, user)
      .then((response) => {
        console.log(response);
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('documentDownload', `${userId}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Page
      title={t('dDocumentTitle')}
      content={t('dDocumentContent')}
      href="/document-bank"
    >
      <TopSection heading={t('dDocument')} />
      <div className="main_container about-us about-top lawyers">
        <div className="container">
          <div className="row">
            <SidebarProfile />

            <div className="col-md-9 col-12">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      {user.subscriptionActivate === 'activate' &&
                        user.subscription === 'pro' && (
                          <div className="card-title">
                            <Link
                              to="/pro-documents"
                              className="btn-style btn-info mr-2"
                            >
                              {t('dDownloadPro')}
                            </Link>
                          </div>
                        )}
                      {(user.subscriptionActivate === 'activate' ||
                        user.subscriptionActivate === 'upgradeToBusiness') &&
                        user.subscription === 'business' && (
                          <div className="card-title">
                            <Link
                              to="/business-documents"
                              className="btn-style btn-info mr-2"
                            >
                              {t('dDownloadBusiness')}
                            </Link>
                          </div>
                        )}
                    </div>
                    <div className="card-body">
                      <div className="large-screen">
                        {documentBank.length ? (
                          <table
                            id="example2"
                            className="table table-bordered table-hover"
                          >
                            <thead className="headerTable">
                              <tr>
                                <th> {t('name')}</th>
                                <th> {t('dCategory')}</th>
                                <th>{t('dSubCategories')}</th>
                                <th>{t('dDocument')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {documentBank
                                .slice(pageVisited, pageVisited + page.perPage)
                                .map((detail) => (
                                  <tr key={detail._id}>
                                    <td>{detail.documentBank.name}</td>
                                    <td>
                                      {
                                        detail.documentBank.subCategory.category
                                          .name
                                      }
                                    </td>
                                    <td>
                                      {detail.documentBank.subCategory.name}
                                    </td>
                                    <td>
                                      <a
                                        onClick={() =>
                                          addDocumentDownload(
                                            detail.documentBank._id
                                          )
                                        }
                                        className="doc-download"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={`${baseURL}/images/${detail.documentBank.document}`}
                                      >
                                        {t('dDownload')}
                                      </a>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <p className="no-record">{t('noRecordMatch')}</p>
                        )}
                        <Pagination
                          pageCount={pageCount}
                          changePage={changePage}
                        />
                      </div>

                      <div className="small-screen">
                        {documentBank.length ? (
                          <div className="doc-small-screen">
                            {documentBank
                              .slice(pageVisited, pageVisited + page.perPage)
                              .map((details) => (
                                <ul
                                  key={details._id}
                                  className="findExpertList col-md-12"
                                >
                                  <li>
                                    <div className="col-12">
                                      <div className="row">
                                        <div className="col-12 detail_style">
                                          <p>
                                            <b>Name</b>:{' '}
                                            {details.documentBank.name}
                                          </p>
                                          <p>
                                            <b>Category</b>:{' '}
                                            {
                                              details.documentBank.subCategory
                                                .category.name
                                            }
                                          </p>
                                          <p>
                                            <b>Sub category</b>:{' '}
                                            {
                                              details.documentBank.subCategory
                                                .name
                                            }
                                          </p>
                                        </div>
                                        <div className="col-lg-3 col-12">
                                          <div className="row">
                                            <div className="col-12 view_connect viewDoc">
                                              <span>
                                                <a
                                                  onClick={() =>
                                                    addDocumentDownload(
                                                      details.documentBank._id
                                                    )
                                                  }
                                                  className="doc-download"
                                                  rel="noopener noreferrer"
                                                  target="_blank"
                                                  href={`${baseURL}/images/${details.documentBank.document}`}
                                                >
                                                  <button className="dow-buy">
                                                    Download{' '}
                                                    <span className="icon-started">
                                                      <BsBoxArrowInDown />
                                                    </span>
                                                  </button>
                                                </a>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              ))}
                            <Pagination
                              pageCount={pageCount}
                              changePage={changePage}
                            />
                          </div>
                        ) : (
                          <p className="no-record">{t('noRecordMatch')}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(DocumentBank);
