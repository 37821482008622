import React from 'react';
import { Link } from 'react-router-dom';
import client from '../../utils/client';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

export function AnnualFree() {
  const { t } = useTranslation();
  return (
    <div
      className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0"
      data-aos="fade-right"
    >
      <p className="pricing-save-free">{t('pFiveFree')}</p>
      <div className="box">
        <h3>{t('pFree')}</h3>
        <h4 dangerouslySetInnerHTML={{ __html: t('pFreePriceYear') }} />
        <ul>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pFreeFoever')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pSecurePAccount')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pFiveFree')}
          </li>

          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pdownloadEdit')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pAccess247')}
          </li>
        </ul>
        <div className="btn-wrap">
          {client.token !== undefined ? (
            <Link
              className="btn-buy"
              to={{
                pathname: `/free-documents`,
              }}
            >
              {t('pStartNow')}
            </Link>
          ) : (
            <Link
              className="btn-buy"
              to={{
                pathname: 'sign-in',
                search: `?redirectTo=free-documents`,
              }}
            >
              {t('pStartNow')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export function AnnualPro() {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0" data-aos="fade-up">
      <p className="pricing-save">{t('pSavePro')}</p>
      <div className="box featured">
        <h3>{t('pPro')}</h3>
        <h4 dangerouslySetInnerHTML={{ __html: t('pProPriceYear') }} />
        <ul>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('p2WeekFreeTrial')}
          </li>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('pSecurePAccount')}
          </li>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('pUnlimetedAccessPersonal')}
          </li>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('pdownloadEdit')}
          </li>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('pAccess247')}
          </li>
        </ul>
        <div className="btn-wrap">
          {client.token !== undefined ? (
            <Link
              className="btn-buy"
              to={{
                pathname: `/billing`,
                search: `?billing=annual billing pro`,
              }}
            >
              {t('pStartNow')}
            </Link>
          ) : (
            <Link
              className="btn-buy"
              to={{
                pathname: 'sign-in',
                search: `?redirectTo=billing?billing=annual billing pro`,
              }}
            >
              {t('pStartNow')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export function AnnualBusiness() {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0" data-aos="fade-down">
      <p className="pricing-save"> {t('pSaveBusiness')}</p>
      <div className="box">
        <h3> {t('pBusiness')}</h3>
        <h4 dangerouslySetInnerHTML={{ __html: t('pBuPriceYear') }} />
        <ul>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('p2WeekFreeTrial')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pSecurePAccount')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pUnlimetedAccessBussiness')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pdownloadEdit')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pAccess247')}
          </li>
        </ul>
        <div className="btn-wrap">
          {client.token !== undefined ? (
            <Link
              className="btn-buy"
              to={{
                pathname: `/billing`,
                search: `?billing=annual billing business`,
              }}
            >
              {t('pStartNow')}
            </Link>
          ) : (
            <Link
              className="btn-buy"
              to={{
                //pathname: '',
                pathname: 'sign-in',
                search: `?redirectTo=billing?billing=annual billing business`,
              }}
              //onClick={() => sendAnnualBusiness()}
            >
              {t('pStartNow')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export function MonthlyFree() {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0">
      <div className="box" data-aos="fade-right">
        <h3>{t('pFree')}</h3>
        <h4 dangerouslySetInnerHTML={{ __html: t('pFreePriceMonth') }} />
        <ul>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pFreeFoever')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pSecurePAccount')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pFiveFree')}
          </li>

          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pdownloadEdit')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pAccess247')}
          </li>
        </ul>
        <div className="btn-wrap">
          {client.token !== undefined ? (
            <Link
              className="btn-buy"
              to={{
                pathname: `/free-documents`,
              }}
            >
              {t('pStartNow')}
            </Link>
          ) : (
            <Link
              className="btn-buy"
              to={{
                //pathname: '',
                pathname: 'sign-in',
                search: `?redirectTo=free-documents`,
              }}
              //onClick={() => sendData()}
            >
              {t('pStartNow')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export function MonthlyPro() {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0">
      <div className="box featured" data-aos="fade-up">
        <h3> {t('pPro')}</h3>
        <h4 dangerouslySetInnerHTML={{ __html: t('pProPriceMonth') }} />
        <ul>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('p2WeekFreeTrial')}
          </li>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('pSecurePAccount')}
          </li>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('pUnlimetedAccessPersonal')}
          </li>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('pdownloadEdit')}
          </li>
          <li>
            <i className="fas fa-check icon-tick-white"></i>
            {t('pAccess247')}
          </li>
        </ul>
        <div className="btn-wrap">
          {client.token !== undefined ? (
            <Link
              className="btn-buy"
              to={{
                pathname: `/billing`,
                search: `?billing=monthly billing pro`,
              }}
            >
              {t('pStartNow')}
            </Link>
          ) : (
            <Link
              className="btn-buy"
              to={{
                //pathname: '',
                pathname: 'sign-in',
                search: `?redirectTo=billing?billing=monthly billing pro`,
              }}
              //onClick={() => sendMonthlyPro()}
            >
              {t('pStartNow')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export function MonthlyBusiness() {
  const { t } = useTranslation();
  return (
    <div className="col-lg-4 col-md-6 col-12 mt-4 mt-md-0">
      <div className="box" data-aos="fade-down">
        <h3> {t('pBusiness')}</h3>
        <h4 dangerouslySetInnerHTML={{ __html: t('pBuPriceMonth') }} />
        <ul>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('p2WeekFreeTrial')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pSecurePAccount')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pUnlimetedAccessBussiness')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pdownloadEdit')}
          </li>
          <li>
            <i className="fas fa-check icon-tick"></i>
            {t('pAccess247')}
          </li>
        </ul>
        <div className="btn-wrap">
          {client.token !== undefined ? (
            <Link
              className="btn-buy"
              to={{
                pathname: `/billing`,
                search: `?billing=monthly billing business`,
              }}
            >
              {t('pStartNow')}
            </Link>
          ) : (
            <Link
              className="btn-buy"
              to={{
                //pathname: '',
                pathname: 'sign-in',
                search: `?redirectTo=billing?billing=monthly billing business`,
              }}
              //onClick={() => sendMonthlyBusiness()}
            >
              {t('pStartNow')}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
