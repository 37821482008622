import client from '../utils/client';

/**
 * Add service
 */
export async function addRecord(name) {
  return await client.post(`/areaOfLaw/`, {
    name: name,
  });
}

/**
 * View  service
 */
export async function viewAllRecord() {
  return await client.get(`/areaOfLaw/`, {});
}

/**
 * View each service
 */
export async function viewEachRecord(id) {
  return await client.get(`/areaOfLaw/${id}`, {});
}

/**
 * Update service
 */
export async function updateRecord(name, id) {
  return await client.put(`/areaOfLaw/${id}`, {
    name: name,
  });
}

/**
 * Delete service
 */
export async function deleteRecord(id) {
  return await client.remove(`/areaOfLaw/${id}`, {});
}
