import React from 'react';
import TopSection from '../TopSection';

function LegalTechnology() {
  return (
    <>
      <TopSection heading="Legal Technology" />
      <div className="main_container about-us view-terms">
        <div className="container">
          <div className="row">
            <div className="col-12 main-headers">
              <p>
                We provide access to advanced legal technology solutions
                enabling you to streamline business processes and help your SME
                or practice navigate current regulatory environment and ensure
                successful contractual relationships.
              </p>
              <p>
                Keeping your business secure is more challenging than ever
                before. Cyber attacks are continually evolving becoming more
                sophisticated, targeted and frequent. The number of firms
                reporting cyber incidents reflects this change rising from{' '}
                <span className="redColor">45% in 2018 to 61% in 2019</span>.
              </p>
              <p>
                When it comes to optimising and securing your organisation's IT
                environment we can help. With a network of partners in both
                legal technology and cybersecurity we facilitate access to
                expertise in optimisation of practice and case management as
                well as protecting, backing up and securing your IT
                environments, both on-site and mobile.{' '}
              </p>
              <p>
                Benefit from our partners’ solutions including industry leading
                firewalls, anti-virus and ransomware protection, multi-factor
                authentication, email and network protection, all deployed to
                ensure you have everything you need for a resilient, reliable
                and secure environment.
              </p>
              <p>
                We are also able to provide{' '}
                <span className="redColor">Solution Development support</span>,
                enabling you to achieve your technology objectives with complete
                confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LegalTechnology;
