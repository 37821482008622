import React from 'react';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function Clients() {
  const { t } = useTranslation();
  return (
    <div className="main_container clients">
      <div className="container">
        <div className="section-title" data-aos="fade-up">
          <h2>
            <strong>{t('ourPartners')}</strong>
          </h2>
          <p>{t('ourPartnersMessage')}</p>
        </div>
        <div
          className="row no-gutters clients-wrap clearfix"
          data-aos="fade-up"
        >
          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img src="/images/clients/aws.png" className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img
                src="/images/clients/godaddy.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img
                src="/images/clients/starling-bank.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img src="/images/clients/ibm.png" className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img src="/images/clients/ms.png" className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img
                src="/images/clients/google.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img src="/images/clients/ico.png" className="img-fluid" alt="" />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <div className="client-logo">
              <img
                src="/images/clients/stripe.png"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
