import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import TopSection from '../../components/TopSection';
import { viewAllBusinessRecord } from '../../services/documentBankServives';
import { viewUserProfile } from '../../services/usersServives';
import ViewDocumentBank from '../../components/document/documentBank';
import SideDocumentFilter from '../../components/document/SideDocumentFilter';
import TopSearchFilter from '../../components/document/TopSearchFilter';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function BusinessDocuments(props) {
  const [documentBank, setDocumentBank] = useState([]);
  const [user, setUser] = useState({});
  const [filter, setFilter] = useState('');
  const [status, setStatus] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const [page, setPage] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    async function getAll() {
      const filter = props.location.search;
      viewAllBusinessRecord(filter)
        .then((response) => {
          setDocumentBank(response.data.documentBank);
          setPage(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting document record
      socket.on('document', (ms) => {
        getAll();
      });
    }
    clientSocket();

    getAll();
    getData();
  }, [baseURL, props.location.search]);

  async function getData() {
    viewUserProfile()
      .then((response) => {
        setUser(response.data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSubmit(values) {
    props.history.push(
      `/business-documents?filter=${values.filter}&status=${
        values.status ? values.status : ''
      }`
    );
  }

  return (
    <Page
      title={t('dAcccessBusiness')}
      content={t('dDocumentContent')}
      href="/"
    >
      <TopSection heading={t('dAcccessBusiness')} />
      <div className="main_container">
        <div className="container">
          {user && user.subscription === 'business' ? (
            <>
              <div className="row search-space">
                <TopSearchFilter
                  classNameInvisible="remove-clear-inpu"
                  to="/business-documents"
                  filter={filter}
                  setFilter={setFilter}
                  onSubmit={handleSubmit}
                />
              </div>
              <div className="row">
                <div className="col-md-8 col-sm-12">
                  <ViewDocumentBank
                    documentBank={documentBank}
                    perPage={page.perPage}
                    total={page.total}
                  />
                </div>

                <div className="col-md-4 col-12 down-side">
                  <SideDocumentFilter
                    className="doc-side-billing"
                    filter={filter}
                    setFilter={setFilter}
                    status={status}
                    setStatus={setStatus}
                    onSubmit={handleSubmit}
                    to="/business-documents"
                  ></SideDocumentFilter>
                </div>
              </div>
            </>
          ) : (
            <p className="no-record">{t('dCannotAccess')}</p>
          )}
        </div>
      </div>
    </Page>
  );
}

export default BusinessDocuments;
