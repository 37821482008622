import client from '../utils/client';

/**
 * Add service
 */
export async function addRecord(name, email, number, subject, message, type) {
  return await client.post(`/contacts/`, {
    name: name,
    email: email,
    number: number,
    subject: subject,
    message: message,
    type: type,
  });
}

/**
 * View  service
 */
export async function viewAllRecord(filter) {
  return await client.get(`/contacts/${filter}`, {});
}

export async function countSubscriber() {
  return await client.get(`/contacts/count`, {});
}

/**
 * View each service
 */
export async function viewEachRecord(id) {
  return await client.get(`/contacts/${id}`, {});
}

/**
 * Update service
 */
export async function updateRecord(status, id) {
  return await client.put(`/contacts/${id}`, {
    status: status,
  });
}

/**
 * Delete service
 */
export async function deleteRecord(id) {
  return await client.remove(`/contacts/${id}`, {});
}
