import React, { useState, useEffect, useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import ScrollToTop from '../scrollToTop/ScrollToTop';
import DashboardHeader from '../../cms/Header';
import DashboardSideBar from '../../cms/SideBar';
import client from '../../utils/client';
import { viewUserProfile, logout } from '../../services/usersServives';

function DashboardLayout(props) {
  const [state, setState] = useState({ user: '', role: '' });
  if (client.token === undefined) {
    props.history.push('/');
  }

  useEffect(() => {
    async function getData() {
      viewUserProfile()
        .then((response) => {
          setState({
            user: response.data.user,
            role: response.data.user.role._id,
          });
          if (response.data.user.role._id !== '6044119e0079f15a6cd050f7') {
            props.history.push('/');
          }
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    getData();
  }, [props.history]);

  const idleTimerRef = useRef(null);
  const onIdle = () => {
    logout()
      .then((response) => {
        Cookies.remove('token');
        Cookies.remove('creadUseDetail');
        props.history.push('/');
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  };

  return (
    state.role === '6044119e0079f15a6cd050f7' && (
      <>
        {client.token && (
          <IdleTimer
            ref={idleTimerRef}
            timeout={1000 * 60 * 60 * 24}
            onIdle={onIdle}
          ></IdleTimer>
        )}
        <ScrollToTop />
        <DashboardHeader />
        <DashboardSideBar />
        {props.children}
      </>
    )
  );
}

export default withRouter(DashboardLayout);
