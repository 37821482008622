import React from 'react';

function TopSection({ className = null, ...props }) {
  return (
    <div className="top-container">
      <h1 className={className}>{props.heading}</h1>
    </div>
  );
}

export default TopSection;
