import client from '../utils/client';

/**
 * Add user service
 */
export async function addUser(firstName, lastName, email, password, role) {
  const response = await client.post(`/users/`, {
    firstName: firstName,
    lastName: lastName,
    email: email,
    password: password,
    role: role,
  });

  return response;
}

/**
 * View users service
 */
export async function viewUsers(filter) {
  return await client.get(`/users/${filter}`, {});
}

export async function viewUsersBilling(filter) {
  return await client.get(`/users/billing/${filter}`, {});
}
export async function viewUsersBillingCount() {
  return await client.get(`/users/billingCount`, {});
}
export async function viewUsersBillingCancelledCount() {
  return await client.get(`/users/billingCancelledCount`, {});
}

export async function viewUsersAnnualChart() {
  return await client.get(`/users/annualChart`, {});
}

export async function viewLawyers(filter) {
  return await client.get(`/users/lawyers${filter}`, {});
}

export async function countUser() {
  return await client.get(`/users/count`, {});
}
/**
 * View each user service
 */
export async function viewEachUser(id) {
  return await client.get(`/users/${id}/`, {});
}

export async function checkForgotPasswordConfirm(email, validationCode) {
  return await client.get(`/users/validation/${email}/${validationCode}/`, {});
}

export async function viewEachLawyer(id) {
  return await client.get(`/users/lawyer/${id}/`, {});
}

/**
 * View user profile service
 */
export async function viewUserProfile() {
  return await client.get(`/users/me/`, {});
}
export async function viewImage() {
  return await client.get(`/users/image/me/`, {});
}

export async function updateUserProfile(userDetail) {
  return await client.put(`/users/profile/me`, {
    userDetail,
  });
}

/**
 * Update user service
 */
export async function updateUsers(userDetail, id) {
  return await client.put(`/users/${id}`, {
    userDetail,
  });
}

export async function updateUserProfileImage(data) {
  return await client.put(`/users/profile/photo/me/`, data);
}
export async function updateUserCompanyProfileImage(data) {
  return await client.put(`/users/profile/photo/companyLogo/`, data);
}
/**
 * Login service
 */
export async function login(email, password) {
  const response = await client.post('/users/login/', {
    email: email,
    password: password,
  });

  return response;
}

/**
 * Change password service
 */

export async function sendEmail(email, validationCode, validationCodeDate) {
  return await client.post(`/users/sendEmail`, {
    email: email,
    validationCode: validationCode,
    validationCodeDate: validationCodeDate,
  });
}

export async function resetPassword(email, validationCode, password) {
  return await client.post(`/users/changeForgotPassword`, {
    email: email,
    validationCode: validationCode,
    password: password,
  });
}
/**
 * Login service
 */
export async function changeUserPassword(oldPassword, password) {
  return await client.post(`/users/changeUserProfilePassword`, {
    oldPassword: oldPassword,
    password: password,
  });
}

export async function changePasswordByUser(password, id) {
  return await client.post(`/users/changeUserPassword/${id}`, {
    password: password,
  });
}

/**
 * Logout userservice
 */
export async function logout() {
  return await client.post(`/users/logout`, {});
}

/**
 * Delete user service
 */
export async function deleteUser(id) {
  return await client.remove(`/users/${id}`, {});
}

export async function deleteUserProfile() {
  return await client.remove(`/users/profile/me`, {});
}

export async function deleteImage() {
  return await client.remove(`/users/image/me`);
}

export async function deleteImageLogo() {
  return await client.remove(`/users/imageLogo/me`);
}

/**
 * Contact service
 */

export async function contactUs(name, email, number, subject, message) {
  return await client.post(`/users/contact-us/`, {
    name: name,
    email: email,
    number: number,
    subject: subject,
    message: message,
  });
}

export async function connectWithUs(
  name,
  email,
  number,
  subject,
  message,
  userEmail
) {
  return await client.post(`/users/connect/`, {
    name: name,
    email: email,
    number: number,
    subject: subject,
    message: message,
    userEmail: userEmail,
  });
}
