const formList = {
  signInTitle: 'Sign in',
  signInContent: 'Sign content',
  signUpTitle: 'Sign up',
  signUpContent: 'Sign up content',
  password: 'Password',
  forgotPassword: 'Forgot password?',
  createAccount: 'Create an account',
  fSignForFree:
    'Sign up for a free account and get secure access to our services.',
  signUp: 'Sign up',
  emailRequired: 'Email is required',
  passwordRequired: 'Password is required',
  passwordLenght: 'Minimum 8 characters',
  wrongEmail: 'Email format is incorrect',
  wrongCredential: 'Your credential does not match to our record',
  firstName: 'First Name',
  lastName: 'Last Name',
  fullName: 'Full Name',
  telNumber: 'Phone Number',
  name: 'Name',
  mobileNumber: 'Mobile Number',
  subject: 'Subject',
  fullNameRequired: 'Full name is required',
  telNumberRequired: 'Phone number is required',
  subjectRequired: 'Subject is required',
  messageRequired: 'Message is required',
  enterMessage: 'Enter Message...',
  sendMessage: 'Send Message',
  successMessage: 'Your message has been successfully submitted!',
  accountType: 'Account Type',
  passwordConfirm: 'Password Verification',
  passwordConfirmRequired: 'Password verification is required',
  passwordConfirmIncorrect: 'Password confirmation is incorrect',
  submit: 'Submit',
  customer: 'Customer',
  lawyer: 'Lawyer',
  selectAccountType: 'Select account type',
  selectGender: 'Select Gender',
  firstNameRequired: 'First name is required',
  lastNameRequired: 'Last name is required',
  accountTypeRequired: 'Account type is required',
  contactUsTitle: 'Contact us',
  contactUsContent: 'Contact us for more information',
  connectTitle: 'Connect',
  connectContent: 'Connect content',
  connectHeading: 'Tell us how we can help you.',
  connectMessage:
    'Please complete the form below and we will be in touch shortly.',
  userDetails: 'User details',
  contactAddress: 'Contact address',
  contactDetails: 'Contact details',
  companyName: 'Company Name',
  gender: 'Gender',
  status: 'Status',
  action: 'Action',
  companyLogo: 'Company Logo',
  changePassword: ' Change password',
  oldPassword: ' Old password',
  newPassword: ' New password',
  confirmPassword: ' Confirm password',
};

export default formList;
