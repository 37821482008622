import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { viewAllRecord, deleteRecord } from '../../services/rolesServives';

function Role(props) {
  const [role, setRole] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting role record
      socket.on('role', (ms) => {
        getAll();
      });
    }
    clientSocket();
    getAll();
  }, []);

  async function getAll() {
    viewAllRecord()
      .then((response) => {
        setRole(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  async function deleteData(id) {
    if (window.confirm('Are you sure you want to delete this role record?')) {
      deleteRecord(id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('role', `${userId}`);
          socket.emit('deleteRole', `${id}`);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
  }

  return (
    <div className="col-md-6 col-12">
      <div className="card">
        {/* /.card-header */}
        <div className="card-body">
          <table id="example2" className="table table-bordered table-hover">
            <thead className="headerTable">
              <tr>
                <th>Name</th>
                <th colSpan="2" className="col-span">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {role.map((detail) => (
                <tr key={detail._id}>
                  <td>{detail.name}</td>
                  <td className="col-span">
                    <Link
                      className="btn btn-primary mr-2"
                      to={{ pathname: `/cms/role/${detail._id}/edit` }}
                      onClick={() => {
                        window.location.href = `/cms/role/${detail._id}/edit`;
                      }}
                    >
                      Edit
                    </Link>{' '}
                  </td>
                  <td className="col-span">
                    <button
                      type="button"
                      onClick={() => deleteData(detail._id)}
                      className="btn btn-danger mr-2 disabled-link"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* /.card-body */}
      </div>
      {/* /.card */}
      {/* /.card */}
    </div>
  );
}

export default withRouter(Role);
