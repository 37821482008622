import navBar from '../lists/navbar/en';
import footer from '../lists/footer/en';
import general from '../lists/general/en';
import aboutUs from '../lists/aboutUs/en';
import home from '../lists/home/en';
import formList from '../lists/form/en';
import lawyers from '../lists/lawyers/en';
import pricing from '../lists/pricing/en';
import sideBar from '../lists/sideBar/en';
import documentBank from '../lists/documentBank/en';

const data = {
  ...navBar,
  ...footer,
  ...general,
  ...aboutUs,
  ...home,
  ...formList,
  ...lawyers,
  ...pricing,
  ...sideBar,
  ...documentBank,
};
export default data;
