import React from 'react';
import SidebarProfile from '../users/SideBarProfile';
import TopSection from '../../components/TopSection';
import VuewEachDocumentBank from '../../components/document/view-each-document';

export default function EditUser() {
  return (
    <>
      <TopSection heading="View each document bank" />
      <div className="main_container about-us about-top">
        <div className="container">
          <div className="row">
            <SidebarProfile />

            <div className="col-md-8 col-12">
              <VuewEachDocumentBank
                ui="/main-view-documents"
                ui2="/main-view-document-add"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
