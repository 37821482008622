import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TopSection from '../../components/TopSection';
import {
  AnnualFree,
  AnnualPro,
  AnnualBusiness,
  MonthlyFree,
  MonthlyPro,
  MonthlyBusiness,
} from './PackageList';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

const TagButton = ({ name, tagActive, onClick }) => {
  return (
    <button className={`tag ${tagActive ? 'active' : null}`} onClick={onClick}>
      {name.toUpperCase()}
    </button>
  );
};

function Package(props) {
  const { t } = useTranslation();
  const [tag, setTag] = useState('Annual');
  //let confirmData = 'You need to login before you have access to our documents';

  function monthly() {
    setTag('Monthly');
  }
  function annual() {
    setTag('Annual');
  }
  // function sendData() {
  //   if (window.confirm(confirmData)) {
  //     props.history.push({
  //       pathname: '/sign-in?redirectTo=/free-documents',
  //     });
  //     window.location.reload();
  //   }
  // }
  // function sendAnnualPro() {
  //   if (window.confirm(confirmData)) {
  //     props.history.push({
  //       pathname: '/sign-in?redirectTo=billing?billing=annual billing pro',
  //     });
  //     window.location.reload();
  //   }
  // }
  // function sendMonthlyPro() {
  //   if (window.confirm(confirmData)) {
  //     props.history.push({
  //       pathname: '/sign-in?redirectTo=billing?billing=monthly billing pro',
  //     });
  //     window.location.reload();
  //   }
  // }
  // function sendAnnualBusiness() {
  //   if (window.confirm(confirmData)) {
  //     props.history.push({
  //       pathname: '/sign-in?redirectTo=billing?billing=annual billing business',
  //     });
  //     window.location.reload();
  //   }
  // }
  // function sendMonthlyBusiness() {
  //   if (window.confirm(confirmData)) {
  //     props.history.push({
  //       pathname:
  //         '/sign-in?redirectTo=billing?billing=monthly billing business',
  //     });
  //     window.location.reload();
  //   }
  // }

  return (
    <Page title={t('pricing')} content={t('pContent')} href="/who-we-are">
      <TopSection heading={t('pricing')} />
      <div className="main_container pricing">
        <div className="container">
          <div className="pricing-toggle">
            <div className="document">
              <div className="tags">
                <TagButton
                  name="Annual"
                  tagActive={tag === 'Annual' ? true : false}
                  onClick={() => {
                    annual();
                  }}
                />
                <TagButton
                  name="Monthly"
                  tagActive={tag === 'Monthly' ? true : false}
                  onClick={() => {
                    monthly();
                  }}
                />
              </div>
            </div>
          </div>
          {tag === 'Annual' ? (
            <div className="row">
              <AnnualFree />
              <AnnualPro />
              <AnnualBusiness />
            </div>
          ) : (
            <div className="row">
              <MonthlyFree />
              <MonthlyPro />
              <MonthlyBusiness />
            </div>
          )}
        </div>
      </div>
      <div className="cta-pricing">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3> {t('pQuestions')}</h3>
              <p>{t('pQuestionsText')}</p>

              <Link to="/contact">
                <button className="btn-global">
                  {t('pAskNow')} <i className="fas fa-angle-right"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default Package;
