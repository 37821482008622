import React from 'react';
import VuewEachDocumentBank from '../../components/document/view-each-document';

export default function ViewDocument() {
  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <VuewEachDocumentBank
              ui="/cms-document-bank-view"
              ui2="/cms-document-bank-add"
            />
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}
