import React from 'react';
import TopSection from '../TopSection';

function PrivacyPolicy() {
  return (
    <>
      <TopSection heading="Privacy Policy" />
      <div className="main_container about-us view-terms">
        <div className="container">
          <div className="row">
            <div className="col-12 main-headers">
              <p>
                <b>INTRODUCTION</b>
              </p>
              <p>
                This document sets out the privacy policy of LawyerExpert Ltd (a
                limited company incorporated in England & Wales, registered
                number 13493090) (referred to in this privacy policy as ‘we’,
                ‘us’, or ‘our’). For the purposes of applicable data protection
                law, (in particular, the General Data Protection Regulation (EU)
                2016/679 (the “GDPR”) and the UK Data Protection Act 2018), your
                data will be controlled by LawyerExpert Ltd.
              </p>
              <p>
                This privacy policy applies whenever we collect your personal
                information (or personal data). This includes between you, the
                visitor to this website (whether directly as our customer or as
                personnel of our customer), and us, the owner and provider of
                this website and also where we are directed by a third party to
                process your personal information. This privacy policy applies
                to our use of any and all data collected by us or provided by
                you in relation to your use of the website and the provision of
                our services to you.
              </p>
              <p>
                We take our privacy obligations seriously and we’ve created this
                privacy policy to explain how we collect and treat your personal
                information. Personal information is any information which could
                identify you.
              </p>
              <p>
                <b>TYPES OF PERSONAL INFORMATION WE COLLECT</b>
              </p>
              <p>
                The personal information we collect may include the following:
              </p>
              <ul className="first-privacy-list">
                <li>name;</li>
                <li>mailing or street address;</li>
                <li>email address;</li>
                <li>social media information;</li>
                <li>telephone number and other contact details;</li>
                <li>age;</li>
                <li>date of birth;</li>
                <li>credit card or other payment information;</li>
                <li>
                  information about your business or personal circumstances;
                </li>
                <li>
                  information in connection with client surveys, questionnaires
                  and promotions;
                </li>
                <li>
                  when we use analytical cookies, your device identity and type,
                  I.P. address, geo-location information, page view statistics,
                  advertising data and standard web log information;
                </li>
                <li>information about third parties; and</li>
                <li>
                  any other information provided by you to us via this website
                  or our online presence, or otherwise required by us or
                  provided by you.
                </li>
              </ul>
              <p>
                <b>HOW WE COLLECT PERSONAL INFORMATION</b>
              </p>
              <p>
                We endeavour to ensure that information we collect is complete,
                accurate, accessible and not subject to unauthorised access.
              </p>
              <p>
                We may collect personal information either directly from you, or
                from third parties, including where you:
              </p>
              <ul className="first-privacy-list">
                <li>contact us through on our website;</li>
                <li>
                  communicate with us via email, telephone, SMS, social
                  applications (such as LinkedIn, Facebook or Twitter) or
                  otherwise;
                </li>
                <li>
                  use our automated referral system or manual referral service;
                </li>
                <li>when you attend our events;</li>
                <li>
                  when you or your organisation offer to provide, or provides,
                  services to us;
                </li>
                <li>
                  interact with our website, social applications, services,
                  content and advertising; and
                </li>
                <li>
                  invest in our business or enquire as to a potential purchase
                  in our business.
                </li>
              </ul>
              <p>
                We may also collect personal information from you when you use
                or access our website or our social media pages. This may be
                done through use of web analytics tools, ‘cookies’ or other
                similar tracking technologies that allow us to track and analyse
                your website usage. For more information, please see our Cookie
                Policy.
              </p>
              <p>
                <b>USE OF YOUR PERSONAL INFORMATION</b>
              </p>
              <p>
                We collect and use personal information for the following
                purposes:{' '}
              </p>
              <ul className="first-privacy-list">
                <li>to provide services or information to you;</li>
                <li>for record keeping and administrative purposes; </li>
                <li>
                  to comply with our legal obligations, resolve disputes or
                  enforce our agreements with third parties;{' '}
                </li>
                <li>
                  where we have your consent including: to send you marketing
                  and promotional messages and other information that may be of
                  interest to you. In this regard, we may use email, SMS, social
                  media or mail to send you direct marketing communications. You
                  can opt-out of receiving marketing materials from us by using
                  the opt-out facility provided (e.g. an unsubscribe link);{' '}
                </li>
                <li>
                  for our legitimate interests including: to develop and carry
                  out marketing activities and to conduct market research and
                  analysis and develop statistics;{' '}
                </li>
                <li>
                  to improve and optimise our service offering and customer
                  experience;{' '}
                </li>
                <li>
                  to send you administrative messages, reminders, notices,
                  updates and other information requested by you;{' '}
                </li>
                <li>to consider an application of employment from you; and</li>
                <li>the delivery of our services.</li>
              </ul>
              <p>
                <b>SHARING YOUR DATA</b>
              </p>
              <p>
                We may share your personal information in certain circumstances,
                as follows: where there is a change of control in our business
                or a sale or transfer of business assets, we reserve the right
                to transfer to the extent permissible at law our user databases,
                together with any personal information and non-personal
                information contained in those databases.{' '}
              </p>
              <p>
                This information may be disclosed to a potential purchaser under
                an agreement to maintain confidentiality. We would seek to only
                disclose information in good faith and where required by any of
                the above circumstances; credit-checking agencies for credit
                control reasons; disclosures required by law or regulation; and
                service providers and other affiliated third parties to enable
                us to provide our services to you including other professional
                advisers such as accountants, disaster recovery service
                providers or auditors and/or overseas counsel.
              </p>
              <p>
                <b>SECURITY</b>
              </p>
              <p>
                We take reasonable steps to ensure your personal information is
                secure and protected from misuse or unauthorised access. Our
                information technology systems are password protected, and we
                use a range of administrative and technical measures to protect
                these systems. However, we cannot guarantee the security of your
                personal information.
              </p>
              <p>
                <b>LINKS</b>
              </p>
              <p>
                Our website may contain links to other websites. Those links are
                provided for convenience and may not remain current or be
                maintained. We are not responsible for the privacy practices of
                those linked websites and we suggest you review the privacy
                policies of those websites before using them.
              </p>
              <p>
                <b>YOUR RIGHTS</b>
              </p>
              <p>
                You have various rights with respect to our use of your personal
                information:
              </p>
              <p>
                <b>ACCESS: </b>
              </p>
              <p>
                You have the right to obtain access to your information (if
                we’re processing it) and certain other information (similar to
                that provided in this privacy notice). This is so that you’re
                aware and can check that we’re using your information in
                accordance with data protection law.
              </p>
              <p>
                <b>BE INFORMED: </b>
              </p>
              <p>
                You have the right to be provided with clear, transparent and
                easily understandable information about how we use your
                information and your rights. This is why we’re providing you
                with the information in this privacy policy.
              </p>
              <p>
                <b>RECTIFICATION:</b>
              </p>
              <p>
                We aim to keep your personal data accurate, current, and
                complete. We encourage you to contact us using our contact form
                to let us know if any of your personal data is not accurate or
                changes, so that we can keep your personal data up-to-date.
              </p>
              <p>
                <b>OBJECTING: </b>
              </p>
              <p>
                You also have the right to object to processing of your personal
                data in certain circumstances, including processing for direct
                marketing.
              </p>
              <p>
                Restricting: You have the right to ‘block’ or suppress further
                use of your information. When processing is restricted, we can
                still store your information, but may not use it further.
              </p>
              <p>
                <b>ERASURE: </b>
              </p>
              <p>
                You have the right to ask us to erase your personal data when
                the personal data is no longer necessary for the purposes for
                which it was collected, or when, among other things, your
                personal data have been unlawfully processed.
              </p>
              <p>
                <b>PORTABILITY:</b>
              </p>
              <p>
                You have the right to request that some of your personal data is
                provided to you, or to another data controller, in a commonly
                used, machine-readable format.
              </p>
              <p>
                <b>COMPLAINTS: </b>
              </p>
              <p>
                If you believe that your data protection rights may have been
                breached, you have the right to lodge a complaint with the
                applicable supervisory authority. In the UK, the supervisory
                authority is the Information Commissioner’s Office.
              </p>
              <p>
                <b>WITHDRAW CONSENT: </b>
              </p>
              <p>
                If you have given your consent to anything we do with your
                personal information, you have the right to withdraw your
                consent at any time. This includes your right to withdraw
                consent to us using your personal information for marketing
                purposes.
              </p>
              <p>
                You may, at any time, exercise any of the above rights, by
                contacting <b>info@lawyerexpert.com</b> or using our contact
                form together with a proof of your identity.
              </p>
              <p>
                <b>HOW LONG WE KEEP DATA</b>
              </p>
              <p>
                We will only retain your personal data for as long as necessary
                to fulfil the purposes we collected it for, including for the
                purposes of satisfying any legal, accounting, or reporting
                requirements. We will securely destroy your personal data in
                accordance with applicable laws and regulations.
              </p>
              <p>
                If you would like further information about our specific
                retention periods for your personal information, please contact
                us at <b>info@lawyerexpert.com</b>.
              </p>
              <p>
                <b>TRANSFERS OUTSIDE THE EUROPEAN ECONOMIC AREA (‘EEA’)</b>
              </p>
              <p>
                To provide our services, we may transfer the personal
                information we collect to countries outside of the UK or EEA
                which do not provide the same level of data protection as the
                country in which you reside and are not recognised by the
                European Commission as providing an adequate level of data
                protection.
              </p>
              <p>
                When we do this, we will make sure that it is protected to the
                same extent as in the EEA and UK as we will put in place
                appropriate safeguards to protect your personal information,
                which may include standard contractual clauses.
              </p>
              <p>
                For more information, please contact us at
                <b> info@lawyerexpert.com</b>.
              </p>
              <p>
                <b>CONTACT US</b>
              </p>
              <p>
                For further information about our privacy policy or practices,
                or to access or correct your personal information, or make a
                complaint, please contact us using the following email address:{' '}
                <b>info@lawyerexpert.com</b>.
              </p>
              <p>
                We may change this privacy policy from time to time by posting
                an updated copy on our website and we encourage you to check our
                website regularly to ensure that you are aware of our most
                current privacy policy. Where we make any significant changes,
                we will endeavour to notify you by email.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
