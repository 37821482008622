import client from '../utils/client';

/**
 * Add service
 */
export async function addRecord(lawName) {
  return await client.post(`/userAreaOfLaw/`, {
    areaOfLaw: lawName,
  });
}

/**
 * View  service
 */
export async function viewAllRecord() {
  return await client.get(`/userAreaOfLaw/`, {});
}

export async function viewAllRecordDisplay() {
  return await client.get(`/userAreaOfLaw/display`, {});
}

export async function viewAllRecordUser() {
  return await client.get(`/userAreaOfLaw/me`, {});
}

export async function viewAllRecordUserDisplay(id) {
  return await client.get(`/userAreaOfLaw/eachUser/${id}`, {});
}
/**
 * View each service
 */
export async function viewEachRecord(id) {
  return await client.get(`/userAreaOfLaw/${id}`, {});
}

/**
 * Update service
 */
export async function updateRecord(name, id) {
  return await client.put(`/userAreaOfLaw/${id}`, {
    areaOfLaw: name,
  });
}

/**
 * Delete service
 */
export async function deleteRecord(id) {
  return await client.remove(`/userAreaOfLaw/me/${id}`, {});
}
