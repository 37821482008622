import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import TopSection from '../../components/TopSection';
import { viewSubRecord } from '../../services/documentBankServives';
import client from '../../utils/client';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function SubDocuments(props) {
  const [documentBank, setDocumentBank] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getAll() {
      const filter = props.location.search;
      viewSubRecord(filter)
        .then((response) => {
          setDocumentBank(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting document record
      socket.on('document', (ms) => {
        getAll();
      });
    }
    clientSocket();
    getAll();
  }, [baseURL, props.location.search]);

  return (
    <Page title={t('dSearchDocument')} content={t('dDocumentContent')} href="/">
      <TopSection heading={props.match.params.subcategory} />
      <div className="main_container">
        <div className="container">
          <div className="row">
            {documentBank.length ? (
              <>
                {documentBank.map((details) => (
                  <div key={details._id} className="doc_main col-md-6 col-12">
                    <Link
                      to={{
                        pathname: client.token
                          ? `/document-download/${details._id}/${details.subCategory.name}`
                          : '/sign-in',
                        search: client.token
                          ? `?status=${details.subCategory._id}`
                          : `?redirectTo=document-download/${details._id}/${details.subCategory.name}?status=${details.subCategory._id}`,
                        SubCategory: details.subCategory.name,
                      }}
                    >
                      <div className="row doc_sub_main">
                        <div className="col-2">
                          <i className="fas fa-file-alt"></i>
                          <h5>
                            {details.price === 'Free' ? (
                              <span>{details.price}</span>
                            ) : (
                              <span>
                                {details.price ? (
                                  <>
                                    {' '}
                                    <sup>{t('dCurrency')}</sup>
                                    {details.price}
                                  </>
                                ) : (
                                  <span className="doc_free">{t('pFree')}</span>
                                )}
                              </span>
                            )}
                          </h5>
                        </div>
                        <div className="col-10">
                          <h4 className="doc_title">{details.name}</h4>
                          {details.description
                            ?.split('\n\n')
                            .map((paragraph) => (
                              <p key={paragraph} className="doc_down">
                                {paragraph
                                  .split('\n')
                                  .reduce((total, line) => [
                                    total,
                                    <br />,
                                    line,
                                  ])}
                              </p>
                            ))}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </>
            ) : (
              <p className="no-record">{t('noRecordMatch')}</p>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(SubDocuments);
