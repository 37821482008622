import React from 'react';
import TopSection from '../TopSection';

function TermsAndService() {
  return (
    <>
      <TopSection heading="Terms of Service" />
      <div className="main_container about-us view-terms">
        <div className="container">
          <div className="row">
            <div className="col-12 main-headers">
              <p>
                <b>
                  Welcome, and thank you for your interest in the website
                  www.lawyerexpert.com
                </b>{' '}
                (the “Website”) and any mobile application that we may make
                available to you now or in the future (the “App”), together
                hereafter referred to in these Terms of Service as
                “LawyerExpert”, “us”, “our” or “we”. LawyerExpert is owned and
                operated by LawyerExpert Ltd, and for the purposes of this
                Agreement and our Privacy Policy any use of the terms
                “LawyerExpert”, “us”, “our” or “we” includes LawyerExpert Ltd,
                without limitation. Unless otherwise specified, all references
                to our services (the “Service” or “Services”) include the
                content, products and services available through the
                LawyerExpert Website or App, as well as any software that
                LawyerExpert provides to you that allows you to access the
                Services. The term “you” or “your” refers to the user of the
                Service, including visitors that do not register for an account
                or purchase a subscription to our Services. The following Terms
                of Service are a legally binding contract between you and
                LawyerExpert and LawyerExpert Ltd regarding your use of the
                Service. Please read the following Terms of Service (“Terms”)
                carefully before accessing or using any of the Services. Each
                time you access or use our Services, subscribe to a plan or
                purchase something from us, you, and if you are acting on behalf
                of a third party or your employer, such third party or employer,
                agree to be bound by these Terms of Service and our Privacy
                Policy whether or not you register with us. If you do not agree
                to be bound by all of these Terms, you may not access or use our
                Service. LawyerExpert may change this Agreement at any time by
                posting an updated Terms of Service on this site. If any
                amendment to these Terms is unacceptable to you, you shall cease
                using this Site or App. If you continue using the Site or the
                App, you will be conclusively deemed to have accepted the
                changes. In addition, certain areas of the Service may be
                subject to additional Terms of Service that we make available
                for your review. By using such areas, or any part thereof, you
                are expressly indicating that you have read and agree to be
                bound by the additional Terms of Service applicable to such
                areas. In the event that any of the additional Terms of Service
                governing such area conflict with these Terms, the additional
                terms will control. PLEASE READ THE BINDING ARBITRATION CLAUSE
                AND CLASS ACTION WAIVER PROVISIONS IN THE DISPUTE RESOLUTION
                SECTION OF THESE TERMS. IT AFFECTS HOW DISPUTES ARE RESOLVED. BY
                ENTERING INTO THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU
                UNDERSTAND THIS AGREEMENT, INCLUDING THE DISPUTE RESOLUTION,
                ARBITRATION PROVISIONS AND CLASS ACTION WAIVER AND ACCEPT ALL OF
                THE TERMS. YOU MAY NOT USE OR ACCESS OUR PLATFORM IF YOU DO NOT
                AGREE TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT.
              </p>
              <div className="list-headers">
                <ol className="first-list">
                  <span>
                    <li>Eligibility for Our Service</li>
                    <p className="service-top-space">
                      By using our Services, you represent that you are a person
                      and not a robot, and have attained the age of majority
                      where you reside and are otherwise capable of entering
                      into binding contracts including this Agreement. If you
                      are using our Services on behalf of a company or other
                      organisation, you represent and warrant that you have
                      authority to act on behalf of that entity and to bind that
                      entity to this Agreement.
                    </p>
                  </span>
                  <span>
                    <li>Description of Service</li>
                    <ol className="second-list">
                      <li>
                        LawyerExpert is a membership based documents database
                        and legal leads generation service utilising surveys,
                        forms and interactive content to provide relevant and
                        valuable data for legal service providers and consumers.
                      </li>
                      <li>
                        The Service does not include any software application or
                        service that is provided by you or a third party, which
                        you use in connection with the Service.
                      </li>
                      <li>
                        Any modifications and new features added to the Service
                        are also subject to this Agreement.
                      </li>
                      <li>
                        LawyerExpert reserves the right to modify or discontinue
                        the Service or any feature or functionality thereof at
                        any time without notice to you. All rights, title and
                        interest in and to the Service and its components
                        (including all intellectual property rights) will remain
                        with and belong exclusively to LawyerExpert.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Accounts and Registration</li>
                    <ol className="second-list">
                      <li>
                        To access some features of the Service, including our
                        subscription services plan, you may be required to
                        register for an account. When you register for an
                        account, you may be required to provide us with some
                        information about yourself (such as your name, date of
                        birth, e-mail address, physical address, phone number,
                        company name or other personal information). Some of
                        this information may be of a confidential nature and may
                        include personal identifying information (all “Your
                        Information”).
                      </li>
                      <li>
                        If you provide Your Information to us then you agree to
                        provide true, current, complete and accurate
                        information, and not to misrepresent your identity. You
                        also agree to keep Your Information current and to
                        update Your Information if any of Your Information
                        changes.
                      </li>
                      <li>
                        Any modifications and new features added to the Service
                        are also subject to this Agreement.
                      </li>
                      <li>
                        Our collection, use and disclosure of Your Information
                        is governed by this Agreement and our Privacy Policy
                        which you may access {''}
                        <a href="https://www.lawyerexpert.com/privacy-policy">
                          here
                        </a>
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Account Management</li>
                    <ol className="second-list">
                      <li>
                        <b>Keek Your Password Secure.</b> If you have been
                        issued an account by LawyerExpert in connection with
                        your use of the Services, you are responsible for
                        safeguarding your password and any other credentials
                        used to access that account, even if you authorise other
                        parties in your organisation to access your account.
                        You, and not LawyerExpert, are responsible for any
                        activity occurring in your account, whether or not you
                        authorised that activity. If you become aware of any
                        unauthorised access to your account, you should notify
                        LawyerExpert immediately.
                      </li>
                      <li>
                        <b>Keek Your Details Accurate.</b> LawyerExpert may send
                        notices to the email address or text messages to your
                        mobile phone registered with your account. You must keep
                        your email address, mobile phone number and, where
                        applicable, your contact details and payment details
                        associated with your account current and accurate.
                      </li>
                      <li>
                        We reserve the right to modify, suspend or terminate the
                        Service, any user account or your access to the Service
                        for any reason, without notice, at any time, and without
                        liability to you.
                      </li>
                      <li>
                        You can cancel your account at anytime. Upon termination
                        or cancellation, all licenses and other rights granted
                        to you in these Terms will immediately cease.
                      </li>
                      <li>
                        We reserve the right to refuse to issue an account to
                        anyone or permit access to the Service to anyone for any
                        reason at any time.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Subscription Plans and Terms</li>
                    <p>
                      LawyerExpert offers several tiers of automatically
                      renewable and payable Subscription Plans for our users
                      including a recurring monthly payable subscription
                      (“Monthly Subscription”), and a prepaid annual
                      subscription (“Annual Subscription”). The features,
                      subscriber rights, terms and prices of these tiered
                      Subscription Plans may change from time to time and the
                      most current descriptions and prices may be found on our
                      Subscription Plans pricing page{' '}
                      <a href=" https://www.lawyerexpert.com/packages">here</a>{' '}
                      By completing your registration for a Subscription Plan,
                      you authorise LawyerExpert or its third party payment
                      processor to charge your payment method on a recurring
                      basis (e.g. monthly or yearly) for: (a) the applicable
                      subscription plan charges; (b) any and all applicable
                      taxes; and (c) any other charges incurred in connection
                      with customer’s use of the LawyerExpert services. The
                      authorisation continues through the subscription term and
                      any renewal term until the subscriber cancels the
                      Subscription Plan. You may select one of the following
                      subscription service plan billing options:
                    </p>
                    <p>
                      <b>
                        Monthly subscription billed every month (“Monthly
                        Subscription”).
                      </b>
                    </p>
                    <ol className="second-list">
                      <li>
                        By purchasing a Monthly Subscription, you expressly
                        acknowledge and agree that (A) your subscription has an
                        initial and recurring payment feature, and LawyerExpert
                        (or our third party payment processor) is authorized to
                        automatically charge your payment method monthly at the
                        then-current subscription rate for your subscription as
                        long as your subscription continues, and (B) your
                        subscription is continuous and will be automatically
                        extended for successive monthly periods until you cancel
                        it.
                      </li>
                      <li>
                        You may cancel your Monthly subscription at any time by
                        emailing us at <b>info@lawyerexpert.com</b> and
                        following the instructions, if any, we provide you in
                        response to your cancellation request. If you cancel,
                        you will not be entitled to a refund for your last
                        payment and you may use your subscription until the end
                        of your then-current paid-up subscription term.
                        Nevertheless, we reserve the right to deny access to
                        your subscription at any time after you cancel your
                        subscription. You must cancel your subscription within
                        the 1st 15 days of the current monthly subscription
                        period to avoid an automatic and non-refundable monthly
                        charge for the next billing cycle. LawyerExpert may
                        submit periodic charges without further authorization
                        from you, until you provide prior notice that you have
                        terminated this authorisation or wish to change your
                        payment method. Such notice will not affect charges
                        submitted before LawyerExpert reasonably could act.
                      </li>
                      <li>
                        In the event you cancel your Monthly subscription,
                        please note that we may still send you promotional
                        communications about LawyerExpert, unless you opt out of
                        receiving those communications by following the
                        unsubscribe instructions provided therein.
                      </li>
                      <p></p>
                    </ol>
                    <p>
                      <b>Annual Subscription (Billed annually).</b>
                    </p>
                    <ol className="second-list">
                      <li>
                        By purchasing an annual subscription, you expressly
                        acknowledge and agree that (A) your subscription has an
                        initial and recurring payment feature for one year of
                        service and LawyerExpert (or our third party payment
                        processor) is authorised to automatically charge your
                        payment method for each successive year at the
                        then-current subscription rate for your subscription as
                        long as your subscription continues, and (B) your
                        subscription is continuous and will be automatically
                        extended at the end of the subscription term for each
                        successive year until you cancel it.
                      </li>
                      <li>
                        You may cancel your Annual subscription at any time, by
                        logging into your account or emailing us at
                        info@lawyerexpert.com and following the instructions, if
                        any, we provide you in response to your cancellation
                        request. If you cancel, you will not be entitled to a
                        refund for your last payment and you may use your
                        subscription until the end of your then-current paid-up
                        subscription term. Nevertheless, we reserve the right to
                        deny access to your subscription at any time after you
                        cancel your subscription. LawyerExpert may submit
                        periodic charges without further authorisation from you,
                        until you provide prior notice that you have terminated
                        this authorisation or wish to change your payment
                        method. Such notice will not affect charges submitted
                        before LawyerExpert reasonably could act.
                      </li>
                      <li>
                        If we cancel your Annual Subscription for any reason,
                        <b>
                          {' '}
                          with the exception of your violation of any of the
                          terms of this Agreement or our Privacy Policy,
                        </b>{' '}
                        you will be eligible for a prorated refund of any
                        un-used portion of the subscription fee paid for the
                        then-current subscription period.
                      </li>
                      <li>
                        In the event you cancel your Annual subscription, please
                        note that we may still send you promotional
                        communications about LawyerExpert, unless you opt out of
                        receiving those communications by following the
                        unsubscribe instructions provided therein.
                      </li>
                      <p></p>
                    </ol>
                    <p>
                      <b>
                        WE RESERVE THE RIGHT TO REVOKE YOUR SUBSCRIPTION AT ANY
                        TIME AS A RESULT OF A VIOLATION OF THESE TERMS OF
                        SERVICE OR THE PRIVACY POLICY. SUBSCRIPTION MEMBERSHIP
                        IS VOID WHERE PROHIBITED BY LAW.
                      </b>
                    </p>
                  </span>
                  <span>
                    <li>Trial Subscriptions</li>
                    <p>
                      LawyerExpert may offer you a free trial subscription
                      period. You may cancel your free trial at any time during
                      the trial period. If you fail to cancel your free trial
                      you agree that LawyerExpert may automatically charge your
                      selected method of payment for a Monthly Subscription
                      under the terms set forth in the section of these terms
                      entitled “Subscription Plans and Terms”. A Monthly
                      Subscription may be upgraded to an Annual Subscription at
                      any time. An Annual Subscription may be reduced to a
                      Monthly Subscription at any time. No refund of the Annual
                      Subscription fee will be paid to you if you reduce it to a
                      Monthly Subscription, however, any unused balance will be
                      credited to your account and applied to your Monthly
                      Subscription fee.
                    </p>
                  </span>
                  <span>
                    <li>Orders</li>
                    <p>
                      Your subscription order constitutes an offer to purchase
                      the Monthly or Annual Subscription and Services available
                      on our platform. We reserve the right at any time to
                      accept or decline any order or partial order. We reserve
                      the right to cancel your order at any time before it has
                      been accepted and we may rescind the acceptance and cancel
                      your order where there has been an obvious error in price
                      or where the Monthly or Annual Subscription or Services
                      are no longer available.
                    </p>
                  </span>
                  <span>
                    <li>Prices</li>
                    <ol className="second-list">
                      <li>
                        All prices displayed in our Services are in British
                        pounds unless otherwise indicated.
                      </li>
                      <li>
                        All applicable taxes and other charges are additional
                        and your responsibility.
                      </li>
                      <li>
                        We reserve the right in our sole discretion to change
                        prices at any time and without notice.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Payment</li>
                    <ol className="second-list">
                      <li>
                        By providing LawyerExpert with your method of payment
                        information, you authorise us to charge you for
                        subscription purchases using the secure third party
                        payment processor we make available to you. Our payment
                        gateway partners use secure servers with
                        state-of-the-art encryption and secure sockets layer
                        (SSL) technology for the transfer of credit card
                        information. Additionally, we have security measures in
                        place to protect our customer database and access to
                        this database is restricted internally.
                      </li>
                      <li>
                        You must keep all information about your payment method
                        current. If you tell us to stop using your payment
                        method and we no longer receive payment from you, we may
                        cancel your account and subscription. Your notice to us
                        will not affect charges to your account before we
                        reasonably could act on your request.
                      </li>
                      <li>
                        You agree that we may charge you, and you will pay to
                        LawyerExpert, any fee or penalty that is assessed or
                        charged to us for a “Chargeback” resulting from a
                        failure or refusal of your selected method of payment to
                        make a required payment. This payment shall be refunded
                        to you in the event that your selected method of payment
                        resolves this dispute in your favour.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Marketing Campaign Management</li>
                    <p>
                      We may now, or in the future, offer marketing campaign
                      management. All prices for marketing campaign management
                      shall be quoted at the time you request information
                      regarding these services. Although we shall agree to
                      deliver the quoted services for the term of the agreement,
                      we cannot guarantee results of any marketing campaign.
                      Therefore LawyerExpert shall not be liable for any
                      unsuccessful campaigns. No refunds shall be given in the
                      event of termination.
                    </p>
                  </span>
                  <span>
                    <li>Your Access and Use of our Services</li>
                    <ol className="second-list">
                      <li>
                        Your right to access and use our Services is personal to
                        you and is not transferable by you to any other person
                        or entity. Access to our Services may not be available
                        in all locations. You are only entitled to access and
                        use our Services for lawful purposes and pursuant to the
                        terms and conditions of this Agreement and our Privacy
                        Policy. Any action by you that, in our sole discretion:
                        (i) violates the terms and conditions of this Agreement
                        and/or the Privacy Policy; (ii) restricts, inhibits or
                        prevents any access, use or enjoyment of our Services;
                        or (iii) through the use of our Services, defames,
                        abuses, harasses, offends or threatens others, shall not
                        be permitted, and may result in your loss of the right
                        to access and use our Services.
                      </li>
                      <li>
                        The rights granted to you in these Terms are subject to
                        the following restrictions: (i) you shall not license,
                        sell, rent, lease, transfer, assign, distribute, host,
                        or otherwise commercially exploit the Services; (ii) you
                        shall not modify, make derivative works of, disassemble,
                        reverse compile or reverse engineer any part of the
                        Services; (iii) you shall not access the Services in
                        order to build a similar or competitive Service; and
                        (iv) except as expressly stated herein, no part of the
                        Services may be copied, reproduced, distributed,
                        republished, downloaded, displayed, posted or
                        transmitted in any form or by any means. Any future
                        release, update, or other addition to functionality of
                        the Services shall be subject to these Terms.
                      </li>
                      <li>
                        Furthermore, you agree that you will not use any robot,
                        spider, scraper, deep link or other similar automated
                        data gathering or extraction tools, program, algorithm
                        or methodology to access, acquire, copy or monitor our
                        Services or any portion of our Services or for any other
                        purpose, without our prior written permission.
                        Additionally, you agree that you will not: (i) take any
                        action that imposes, or may impose in our sole
                        discretion an unreasonable or disproportionately large
                        load on our infrastructure; (ii) copy, reproduce,
                        modify, create derivative works from, distribute or
                        publicly display any content (except for your personal
                        information) from our Services without our prior written
                        permission and the appropriate third party, as
                        applicable; (iii) interfere or attempt to interfere with
                        the proper working of our Services or any activities
                        conducted on our Services; (iv) bypass any robot
                        exclusion headers or other measures we may use to
                        prevent or restrict access to our Services, or (v)
                        interfere or disrupt the Service or servers or networks
                        connected to the Service, including by transmitting any
                        worms, viruses, spyware, malware or any other code of a
                        destructive or disruptive nature.
                      </li>
                      <li>
                        Except as expressly permitted in this Agreement, you
                        shall not collect or harvest any personally identifiable
                        information, including account names, from our Services.
                      </li>
                      <li>
                        Our Services may now, or in the future, have “publicly
                        accessible areas” that allow users to post User Content
                        (hereafter defined) that will be accessible by the
                        public or the user population generally. As a user of
                        the Services, you acknowledge and affirmatively agree
                        that in the course of using the Services you may be
                        exposed to User Content that might be offensive,
                        harmful, inaccurate or otherwise inappropriate. You
                        further agree that LawyerExpert shall not, under any
                        circumstances, be liable in any way for any User
                        Content.
                      </li>
                      <li>
                        You understand that LawyerExpert may issue upgrade
                        versions of any mobile App and you consent to receiving
                        automatic upgrades on your mobile device. You also
                        acknowledge and agree that standard carrier data charges
                        may apply to your use of the Service including, without
                        limitation, text messages.
                      </li>
                      <li>
                        You shall not use any communication systems provided on
                        our Services including, without limitation email, for
                        any commercial or solicitation purposes. You shall not
                        solicit for commercial purposes any users of our
                        Services without our prior written permission.
                      </li>
                      <li>
                        You understand and agree that you are solely responsible
                        for compliance with any and all laws, rules,
                        regulations, and Tax obligations that may apply to your
                        purchase of documents and,or use of the Services.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Suspension and Termination of Services</li>
                    <ol className="second-list">
                      <li>
                        LawyerExpert may limit or suspend or terminate the
                        Services to you (including your subscription) if you
                        fail to comply with these Terms, the Privacy Policy or
                        if you use the Services in a way that causes legal
                        liability to us or disrupts use of the Service by other
                        users.
                      </li>
                      <li>
                        LawyerExpert may also suspend providing the Services to
                        you (including your subscription) if we are
                        investigating suspected misconduct by you. LawyerExpert
                        will use commercially reasonable efforts to narrow the
                        scope and duration of any limitation or suspension under
                        this Section as is needed to resolve the issue that
                        prompted such action.
                      </li>
                      <li>
                        Users who have been terminated or suspended will not be
                        eligible for a refund of any kind for the termination or
                        the period of suspension.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Information Accuracy</li>
                    <ol className="second-list">
                      <li>
                        We attempt to ensure that information on this Service is
                        complete, accurate and current. Despite our best
                        efforts, the information on our Service may occasionally
                        be inaccurate, incomplete or out of date. We make no
                        representation as to the completeness, accuracy, or
                        currency of any information on the Service.
                      </li>
                      <li>
                        Furthermore, information on the Service may contain
                        typographical errors, inaccuracies, or omissions. We
                        reserve the right to correct or make changes in such
                        information without notice and cancel orders placed for
                        products, services or subscriptions listed incorrectly
                        or at the wrong price.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Proprietary Rights</li>
                    <p>
                      As between LawyerExpert and you, LawyerExpert or its
                      licensors own and reserve all right, title and interest in
                      and to the Service and all hardware, software and other
                      items used to provide the Service, other than the rights
                      explicitly granted to you to use the Service in accordance
                      with these Terms. No title to or ownership of any
                      proprietary rights related to the Service is transferred
                      to you pursuant to these Terms. All rights not explicitly
                      granted to you are reserved by LawyerExpert.
                    </p>
                  </span>
                  <span>
                    <li>Intellectual Property Rights</li>
                    <ol className="second-list">
                      <li>
                        Our names, graphics, videos, logos, page headers, button
                        icons, scripts, and service names are our trademarks or
                        trade dress in the United Kingdom and/or other countries
                        (collectively the “Proprietary Marks”), and are owned by
                        LawyerExpert and LawyerExpert Ltd. You may not use the
                        Proprietary Marks without our prior written permission.
                      </li>
                      <li>
                        We make no proprietary claim to any third-party names,
                        trademarks or service marks appearing on our Services.
                        Any third-party names, trademarks, and service marks are
                        property of their respective owners.
                      </li>
                      <li>
                        The information, content, documentation, guides,
                        descriptions, data, software and any other content
                        viewable on, contained in, or downloadable from our
                        Services (collectively, “Our Content”), including,
                        without limitation, all document files, audio, text,
                        graphics, charts, pictures, photographs, images, videos,
                        line art, icons and renditions, are copyrighted by, or
                        otherwise licensed to us or Our Content suppliers.
                      </li>
                      <li>
                        We also own a copyright of a collective work in the
                        selection, coordination, arrangement, presentation,
                        display and enhancement of Our Content (the “Collective
                        Work”).
                      </li>
                      <li>
                        All software used on, or within our Services is our
                        property or the property of our software vendors and is
                        protected by United Kingdom and international copyright
                        laws. Listening, viewing, reading, printing, downloading
                        or otherwise using Our Content and/or the Collective
                        Work does not entitle you to any ownership or
                        intellectual property rights to Our Content, the
                        Collective Work, or the Software.
                      </li>
                      <li>
                        You are solely responsible for any damages resulting
                        from your infringement of our, or any third-parties,
                        intellectual property rights regarding the Trademarks,
                        Our Content, the Collective Work, the Software and/or
                        any other harm incurred by us or our affiliates as a,
                        direct or indirect, result of your copying,
                        distributing, redistributing, transmitting, publishing
                        or using the same for purposes that are contrary to the
                        terms and conditions of this Agreement.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Use of Our Content</li>
                    <ol className="second-list">
                      <li>
                        We grant you a limited license to access, print,
                        download or otherwise make personal use of Our Content
                        and the Collective Work for your non-commercial personal
                        use provided, however, that you shall not delete any
                        proprietary notices or materials with regard to the
                        foregoing.
                      </li>
                      <li>
                        We grant you a limited license to access, print,
                        download, modify or otherwise make use of any templates
                        that we make available for your personal business use,
                        provided however that you do not resell, market or
                        otherwise commercially exploit these templates in any
                        manner not consistent with the Services we provide to
                        you.
                      </li>
                      <li>
                        You may not modify Our Content or the Collective Work or
                        utilise them for any commercial purpose or any other
                        public display, performance, sale, or rental, decompile,
                        reverse engineer, or disassemble Our Content and the
                        Collective Work, or transfer Our Content or the
                        Collective Work to another person or entity.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>
                      User Content Rights and Related Responsibilities; License
                    </li>
                    <ol className="second-list">
                      <li>
                        “User Content” means, without limitation, any messages,
                        texts, reviews, digital files, images, photos, personal
                        profile (including your photo), artwork, videos, audio,
                        comments, feedback, suggestions, reviews and documents,
                        or any other content you upload, transmit or otherwise
                        make available to LawyerExpert and its users via the
                        Services. You represent and warrant that you own or
                        otherwise control the rights to your User Content and
                        agree to indemnify LawyerExpert and its affiliates for
                        all claims arising from or in connection with any claims
                        to any rights in your User Content or any damages
                        arising from your User Content.
                      </li>
                      <li>
                        By submitting User Content on or through the Service,
                        you grant LawyerExpert a worldwide, non-exclusive,
                        royalty-free license (with the right to sublicense) to
                        use, copy, reproduce, process, adapt, modify, publish,
                        transmit, display and distribute such User Content
                        without attribution, and without the requirement of any
                        permission from or payment to you or to any other person
                        or entity, in any manner including, without limitation,
                        for commercial, publicity, trade, promotional, or
                        advertising purposes, and in any and all media now known
                        or hereafter devised, and to prepare derivative works
                        of, or incorporate into other works, such User Content.
                      </li>
                      <li>
                        In order for us to provide the Service to you, we
                        require that you grant us certain rights with respect to
                        User Content, including the ability to transmit,
                        manipulate, process, store and copy User Content in
                        order to provide our Services. Your acceptance of this
                        Agreement gives us the permission to do so and grants us
                        any such rights necessary to provide the Service to you.
                      </li>
                      <li>
                        You agree that any User Content you submit to our
                        Service may be viewed by other users, any person
                        visiting or participating in the Service and by the
                        public in general.
                      </li>
                      <li>
                        LawyerExpert expressly disclaims any liability for the
                        loss or damage to any User Content or any losses or
                        damages you incur as a result of the loss or damage of
                        any User Content. It is your responsibility to back-up
                        any User Content to prevent its loss.
                      </li>
                      <li>
                        You are solely responsible for your User Content,
                        including, without limitation, reviews, comments and
                        feedback, and any damages suffered by LawyerExpert
                        resulting therefrom.
                      </li>
                      <li>
                        LawyerExpert may block, remove or return any User
                        Content at any time for any reason whatsoever, or for no
                        reason at all. We are not responsible for the
                        authenticity, accuracy, completeness, appropriateness,
                        or legality of User Content.
                      </li>
                      <li>
                        You represent and warrant that all information that you
                        submit is authentic, accurate and truthful and that you
                        will promptly update any information provided by you
                        that subsequently becomes inaccurate, misleading or
                        false.
                      </li>
                      <li>
                        User Content is not considered to be confidential. You
                        agree not to submit User Content in which you have any
                        expectation of privacy.
                      </li>
                      <li>
                        LawyerExpert has no control over User Content once
                        posted, and it is possible that visitors to the Site or
                        App may copy User Content and repost it elsewhere.
                      </li>
                      <li>
                        You agree not to post as part of the Service any
                        offensive, inaccurate, incomplete, abusive, obscene,
                        profane, threatening, intimidating, harassing, racially
                        offensive, or illegal material. The following includes,
                        without limitation, examples of the things you may not
                        do:
                      </li>
                      <ol className="third-list">
                        <li>Impersonate any person or entity.</li>
                        <li>
                          Stalk, harass, defame, abuse, bully, threaten or
                          otherwise violate the legal rights of others.
                        </li>
                        <li>
                          Advocate for or harass or intimidate another person.
                        </li>
                        <li>
                          Promote information that is false or misleading.
                        </li>
                        <li>
                          Promote illegal activities or conduct that is
                          defamatory, libelous or otherwise objectionable.
                        </li>
                        <li>
                          Promote violence, racism, bigotry, hatred or physical
                          harm of any kind against any group or individual.
                        </li>
                        <li>
                          Transmit anything that exploits children or minors or
                          that depicts cruelty to animals.
                        </li>
                        <li>
                          Solicit personal information from anyone under the age
                          of 18.
                        </li>
                        <li>
                          Use the service in an illegal manner or to commit an
                          illegal act.
                        </li>
                        <li>
                          Transmit any material that contains software viruses
                          or any other computer code, files or programs designed
                          to interrupt, destroy or limit the functionality of
                          any computer software or hardware.
                        </li>
                        <li>
                          Transmit any content that contains video, audio, or
                          images of another person without his or her permission
                          or that of their legal guardian.
                        </li>
                        <li>
                          Promote material that exploits people in a sexual,
                          pornographic or violent manner.
                        </li>
                        <li>
                          Provide instructional information about illegal
                          activities.
                        </li>
                        <li>
                          Infringe upon someone else’s trademark, copyright or
                          other intellectual property or other rights.
                        </li>
                        <li>
                          Promote commercial activities including without
                          limitation sales, contests, sweepstakes, barter,
                          advertising, and business offers.
                        </li>
                        <li>
                          Transmit anything that violates the Children’s Online
                          Privacy Protection Act (“COPPA”) and Controlling the
                          Assault of Non-Solicited Pornography and Marketing Act
                          of 2003 (“CAN-SPAM”).
                        </li>
                      </ol>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Spam and E-mail Marketing</li>
                    <p>
                      LawyerExpert does not tolerate the use of its Services to
                      facilitate Spam, misleading, unsolicited or fraudulent use
                      of marketing services. You must also adhere to all email
                      marketing laws to the regions you operate in. You must
                      also adhere to the obligations of CAN-SPAM and EU
                      Directive 2002/58/EC. LawyerExpert takes no responsibility
                      for your interpretation of the respective laws. However,
                      if you receive any emails that breach this clause, please
                      contact us at <b>info@lawyerexpert.com.</b>
                    </p>
                  </span>
                  <span>
                    <li>Interruption of Service</li>
                    <ol className="second-list">
                      <li>
                        Your access and use of our Services may be interrupted
                        from time to time for any of several reasons, including,
                        without limitation, the malfunction of equipment,
                        periodic updating, maintenance or repair of our Services
                        or other actions that we, in our sole discretion, may
                        elect to take.
                      </li>
                      <li>
                        You agree that we will not be liable to you or to any
                        third party for any interruption of the Services or any
                        part thereof.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Third Party Links, Services and Content</li>
                    <p>
                      The Service may contain features, services and
                      functionalities linking you to, or providing you with
                      access to third party services and content, websites,
                      directories, servers, networks, systems, information,
                      databases, applications, software, programs, courses,
                      services, and the Internet as a whole. Because we have no
                      control over such sites and resources, we are not
                      responsible for the availability of such external sites or
                      resources, and do not endorse and are not responsible or
                      liable for any content, advertising, courses or other
                      materials on or available from such sites or resources.
                      When you visit or use a third party’s website you agree to
                      read and consent to the third party’s Terms of Service and
                      Privacy Policy and you release us from any liability.
                    </p>
                  </span>
                  <span>
                    <li>Software</li>
                    <ol className="second-list">
                      <li>
                        If you receive software from us, its use is governed in
                        one of two ways: If you’re presented with license terms
                        that you must accept in order to use the software, those
                        terms apply; if no license is presented to you, these
                        Terms apply. We reserve all other rights to the
                        software.
                      </li>
                      <li>
                        Any license is for the sole purpose of enabling you to
                        use and enjoy the benefit of the Services as provided by
                        LawyerExpert, in the manner permitted by these terms.
                        You may not copy, modify, distribute, sell, or lease any
                        part of our Services or included software, nor may you
                        reverse engineer or attempt to extract the source code
                        of that software.
                      </li>
                      <li>
                        We may automatically check your version of the software.
                        We may also automatically download to your device or
                        computer new versions of the software.
                      </li>
                      <li>
                        Any software is licensed, not sold. Unless we notify you
                        otherwise, the software license ends when your Service
                        ends. You must then promptly uninstall the software, or
                        we may disable it. You must not work around any
                        technical limitations in the software.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Electronic Communications</li>
                    <ol className="second-list">
                      <li>
                        Although we may choose to communicate with you by
                        regular mail, we may also choose to communicate with you
                        by electronic means including, without limitation,
                        email, telephone, text, SMS or by posting notices on our
                        Services. When you use our Services, you consent to
                        communicating with us electronically.
                      </li>
                      <li>
                        You agree that all agreements, notices, disclosures and
                        other communications that we provide to you
                        electronically satisfy any legal requirement that such
                        communications be in writing.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Electronic Transactions</li>
                    <ol className="second-list">
                      <li>
                        Your use of the Services includes the ability to enter
                        into agreements, including these Terms, and to make
                        transactions electronically, including financial
                        transactions and purchases. You acknowledge that your
                        electronic submissions constitute your agreement and
                        intent to be bound by such agreements, financial
                        transactions and purchases.
                      </li>
                      <li>
                        Your agreement and intent to be bound by electronic
                        submissions applies to all records relating to all
                        transactions you enter into on this site, including
                        purchases, financial transactions, notices of
                        cancellation, policies, contracts, and applications.
                      </li>
                      <li>
                        In order to access and retain your electronic records,
                        you may be required to have certain hardware and
                        software, which are your sole responsibility.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Third Party Social Networking</li>
                    <p>
                      If you access our Services through a third party social
                      networking site or application including, but not limited
                      to, Facebook, LinkedIn or Twitter, you authorise
                      LawyerExpert to access certain information about you that
                      is made available through that third party social
                      networking site and further authorise us to collect,
                      store, retain and use, your information in accordance with
                      our Privacy Policy.
                    </p>
                  </span>
                  <span>
                    <li>Security</li>
                    <p>
                      Violating the security of our Services is prohibited and
                      may result in criminal and civil liability. LawyerExpert
                      may investigate incidents involving such violations and
                      may involve, and will cooperate with law enforcement if a
                      criminal violation is suspected. Security violations
                      include, without limitation, unauthorised access to or use
                      of data or systems including any attempt to probe, scan,
                      or test the vulnerability of the Service or to breach
                      security or authentication measures, unauthorised
                      monitoring of data or traffic and interference with
                      service to any user, host, or network.
                    </p>
                  </span>
                  <span>
                    <li>Privacy and Your Personal Information</li>
                    <p>
                      For information about the LawyerExpert data protection
                      practices and privacy policies, please read our Privacy
                      Policy where you accessed these Terms of Service or{' '}
                      <a href="https://www.lawyerexpert.com/privacy-policy">
                        here
                      </a>
                      {''}. This policy explains how we treat your personal
                      information, and protect your privacy when you use the
                      Services. You agree to the use of your data in accordance
                      with LawyerExpert Privacy Policy.
                    </p>
                  </span>
                  <span>
                    <li>Copyright and Intellectual Property Policy</li>
                    <p>
                      We respect the intellectual property rights of others. We
                      reserve the right to remove any User Content on the
                      Services which allegedly infringe upon another person’s
                      copyright, trademark or other intellectual property right,
                      and/or terminate, discontinue, suspend and/or restrict the
                      account or ability to visit and/or use the Services or
                      remove, edit, or disable any User Content on the Services
                      which allegedly infringe upon another person’s
                      intellectual property rights. A valid complaint must
                      provide the following information in writing:
                    </p>
                    <ol className="second-list">
                      <li>
                        An electronic or physical signature of a person
                        authorised to act on behalf of the copyright owner.
                      </li>
                      <li>
                        Identification of the copyrighted work that you claim
                        has been infringed.
                      </li>
                      <li>
                        Identification of the material that is claimed to be
                        infringing and where it is located on the Service.
                      </li>
                      <li>
                        Information reasonably sufficient to permit us to
                        contact you, such as your address, telephone number,
                        and, email address.
                      </li>
                      <li>
                        A statement that you have a good faith belief that use
                        of the material in the manner complained of is not
                        authorised by the copyright owner, its agent, or law.
                      </li>
                      <li>
                        A statement, made under penalty of perjury, that the
                        above information is accurate, and that you are the
                        copyright owner or are authorised to act on behalf of
                        the owner.
                      </li>
                      <p></p>
                    </ol>
                    <p>
                      Federal law provides that if you knowingly misrepresent
                      that online material is infringing, you may be subject to
                      criminal prosecution for perjury and civil penalties,
                      including monetary damages, court costs, and attorneys’
                      fees. The above information must be submitted to
                      LawyerExpert at: <b>info@lawyerexpert.com</b>{' '}
                    </p>
                    <p>
                      <b>Counter-Notice:</b> If you receive notification that
                      your User Content has been removed from the Services, you
                      have an opportunity to appeal this removal if you believe
                      that your User Content was removed in error. If you
                      believe that the removed User Content does not violate any
                      third-party rights, or you have authorisation from the
                      rights-holder or the rights-holder’s authorised agent to
                      use the material in question, you may appeal the removal
                      by sending an email notice to the Copyright Agent at:{' '}
                      <b>info@LawyerExpert.com</b> with the following
                      information:
                    </p>
                    <ol className="second-list">
                      <li>Your physical or electronic signature.</li>
                      <li>
                        Identification of the material that has been removed or
                        to which access has been disabled, and the location at
                        which the material appeared before it was removed or
                        access to it was disabled.
                      </li>
                      <li>
                        A statement under penalty of perjury that you have a
                        good-faith belief that the material was removed or
                        disabled as a result of mistake or misidentification of
                        the material to be removed or disabled.
                      </li>
                      <li>
                        Your name, address, and telephone number, and a signed
                        statement that you consent to the jurisdiction of the
                        courts in England and Wales, and that you will accept
                        service of process from the party who made the initial
                        infringement claim (or their authorised agent) if they
                        choose to pursue legal action.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Disclaimers: No Warranties</li>
                    <ol className="second-list">
                      <li>
                        <b>
                          ALL SERVICES AND PRODUCTS AVAILABLE FROM LAWYEREXPERT
                          ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                          TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW.
                          LAWYEREXPERT (LAWYEREXPERT LTD) AND ITS PARENTS,
                          SUBSIDIARIES, PARTNERS, AFFILIATES, OFFICERS,
                          DIRECTORS, EMPLOYEES AND AGENTS, (COLLECTIVELY, THE
                          “LAWYEREXPERT PARTIES”) DISCLAIM ALL WARRANTIES,
                          EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE
                          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                          PARTICULAR PURPOSE AND NON-INFRINGEMENT.
                        </b>
                      </li>
                      <li>
                        <b>
                          WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY
                          THAT (I) THE SERVICES OR PRODUCTS WILL MEET YOUR
                          REQUIREMENTS, (II) THE SERVICES WILL BE UNINTERRUPTED,
                          TIMELY, SECURE, OR ERROR-FREE, OR (III) THE QUALITY OF
                          ANY SERVICES, PRODUCTS OR INFORMATION PURCHASED OR
                          OBTAINED BY YOU FROM OR THROUGH US WILL MEET YOUR
                          EXPECTATIONS.
                        </b>
                      </li>
                      <li>
                        <b>
                          THE SERVICES CAN INCLUDE TECHNICAL OR OTHER MISTAKES,
                          INACCURACIES OR TYPOGRAPHICAL ERRORS. FURTHERMORE, THE
                          INFORMATION OR SERVICES ON THIS SITE OR APP MAY BE OUT
                          OF DATE. WE MAY MAKE CHANGES TO THE SERVICES, PRODUCTS
                          AND INFORMATION ON THIS SITE OR APP, INCLUDING THE
                          PRICES AND DESCRIPTIONS OF ANY SERVICES OR PRODUCTS
                          LISTED HEREIN AT ANY TIME WITHOUT NOTICE, HOWEVER WE
                          HAVE NO OBLIGATION TO DO SO.
                        </b>
                      </li>
                      <li>
                        <b>
                          THE LAWYEREXPERT PARTIES DO NOT WARRANT THAT THE
                          SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
                          DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE
                          SERVERS THAT MAKE THE SERVICE AVAILABLE WILL BE FREE
                          OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT ANY
                          PRODUCT DESCRIPTION OR OTHER CONTENT OFFERED AS PART
                          OF THE SERVICES, ARE ACCURATE, RELIABLE, CURRENT OR
                          COMPLETE.
                        </b>
                      </li>
                      <li>
                        <b>
                          YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE IS AT
                          YOUR SOLE RISK. IF YOU DOWNLOAD ANY CONTENT FROM THE
                          SERVICE, YOU DO SO AT YOUR OWN DISCRETION AND RISK.
                          YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
                          COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
                          DOWNLOAD OF ANY CONTENT THROUGH THE SERVICE.
                        </b>
                      </li>
                      <li>
                        <b>
                          WE RESERVE THE RIGHT TO RESTRICT OR TERMINATE YOUR
                          ACCESS TO THE SERVICE OR ANY FEATURE OR PART THEREOF
                          AT ANY TIME.
                        </b>
                      </li>
                      <li>
                        <b>
                          SOME STATES OR OTHER JURISDICTIONS DO NOT ALLOW THE
                          EXCLUSION OF IMPLIED WARRANTIES, SO SOME PARTS OF THE
                          ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                        </b>
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Limitation of Liability</li>
                    <ol className="second-list">
                      <li>
                        <b>
                          IN NO EVENT SHALL WE BE RESPONSIBLE TO, OR LIABLE TO
                          YOU, OR ANY THIRD PARTY, WHETHER IN CONTRACT,
                          WARRANTY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE,
                          FOR ANY DAMAGES INCLUDING, BUT NOT LIMITED TO,
                          SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES
                          THAT INCLUDE, BUT ARE NOT LIMITED TO, DAMAGES FOR ANY
                          PERSONAL INJURY TO YOU OR OTHERS, OR LOSS OF PROFIT,
                          REVENUE OR BUSINESS, AS A DIRECT OR INDIRECT RESULT
                          OF: (I) YOUR ACCESS AND USE OF OUR SERVICES AND
                          PRODUCTS; (II) YOUR BREACH OR VIOLATION OF THE TERMS
                          AND CONDITIONS OF THIS AGREEMENT; (III) YOUR DELAY IN
                          ACCESSING OR INABILITY TO ACCESS OR USE OUR SERVICES
                          FOR ANY REASON; (IV) YOUR DOWNLOADING OF ANY OF OUR
                          CONTENT OR THE COLLECTIVE WORK FOR YOUR USE; OR (V)
                          YOUR RELIANCE UPON OR USE OF OUR CONTENT OR THE
                          COLLECTIVE WORK, WHETHER RESULTING IN WHOLE OR IN
                          PART, FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR,
                          NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF WE
                          AND/OR OUR SUPPLIERS HAD BEEN ADVISED OF THE
                          POSSIBILITY OF DAMAGES.
                        </b>
                      </li>
                      <li>
                        <b>
                          THIS LIMITATION SHALL ALSO APPLY WITH RESPECT TO
                          DAMAGES INCURRED BY REASON OF OTHER SERVICES OR
                          PRODUCTS RECEIVED THROUGH OR ADVERTISED IN OUR
                          SERVICES OR RECEIVED THROUGH ANY LINKS PROVIDED IN OUR
                          SERVICES.
                        </b>
                      </li>
                      <li>
                        <b>
                          WITHOUT LIMITING THE FOREGOING, UNDER NO CIRCUMSTANCES
                          SHALL WE BE HELD LIABLE FOR ANY DELAY OR FAILURE IN
                          PERFORMANCE RESULTING DIRECTLY OR INDIRECTLY FROM ACTS
                          OF NATURE, FORCES, OR CAUSES BEYOND THEIR REASONABLE
                          CONTROL, INCLUDING, WITHOUT LIMITATION, INTERNET
                          FAILURES, COMPUTER EQUIPMENT FAILURES,
                          TELECOMMUNICATION EQUIPMENT FAILURES, OTHER EQUIPMENT
                          FAILURES, ELECTRICAL POWER FAILURES, STRIKES, LABOR
                          DISPUTES, RIOTS, INSURRECTIONS, CIVIL DISTURBANCES,
                          SHORTAGES OF LABOR OR MATERIALS, FIRES, FLOODS,
                          STORMS, EXPLOSIONS, ACTS OF GOD, WAR, GOVERNMENTAL
                          ACTIONS, ORDERS OF DOMESTIC OR FOREIGN COURTS OR
                          TRIBUNALS OR NON-PERFORMANCE OF THIRD PARTIES.
                        </b>
                      </li>
                      <li>
                        <b>
                          OUR LIABILITY AND THE LIABILITY OF OUR AFFILIATES,
                          DIRECTORS, OFFICERS, EMPLOYEES, INDEPENDENT
                          CONTRACTORS, SHAREHOLDERS, REPRESENTATIVES, AND AGENTS
                          ARISING OUT OF THIS AGREEMENT SHALL NOT EXCEED THE
                          AMOUNTS ACTUALLY PAID BY USER IN THE PURCHASE OR
                          TRANSACTION GIVING RISE TO SUCH CLAIM.
                        </b>
                      </li>
                      <li>
                        <b>
                          YOU AND WE AGREE THAT ANY CAUSE OF ACTION ARISING OUT
                          OF OR RELATED TO OUR SERVICES MUST COMMENCE WITHIN ONE
                          (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE,
                          SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.
                        </b>
                      </li>
                      <li>
                        <b>
                          CERTAIN STATE OR JURISDICTIONAL LAWS DO NOT ALLOW
                          LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                          LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO
                          YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS
                          OR LIMITATIONS MAY NOT APPLY TO YOU.
                        </b>
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>
                      Third Party Interference and Damage to Access Devices
                    </li>
                    <ol className="second-list">
                      <li>
                        Although LawyerExpert has security measures in place,
                        you hereby accept that LawyerExpert are not responsible
                        for any third party interference with the Site. This
                        includes any access to your account details by third
                        parties, any software implemented into the website that
                        causes any damage to the device used to access the site
                        or anything that causes any damage to you whatsoever
                        caused by third party interference.
                      </li>
                      <li>
                        You hereby agree that you are responsible for the
                        devices you use to access the Site and its compatibility
                        required to access the site. LawyerExpert cannot be held
                        responsible for any virus, bug or improper use of
                        equipment used to access the Site whether through a web
                        browser or device application.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Website Interruptions and Data Retention</li>
                    <p>
                      LawyerExpert shall attempt to keep the site and its
                      servers operational 24 hours a day, 365 days per year,
                      however we make no warranty to do so as server and website
                      interruption may be the result of external factors outside
                      of the control of LawyerExpert. As a result, LawyerExpert
                      shall not be responsible for any loss caused to your
                      business because of server or Site downtime. LawyerExpert
                      shall store the data that we collect from users using the
                      Services. In the unlikely event that something does go
                      wrong and your data is lost LawyerExpert shall not be
                      responsible for any damage to you, your business or any
                      third party as a result. You hereby warrant that you
                      understand that the storage of your data is not guaranteed
                      or 100% secure and that you shall hold LawyerExpert
                      harmless from all liability.
                    </p>
                  </span>
                  <span>
                    <li>API Integration </li>
                    <p>
                      Our software can be implemented with third party software
                      by means of API integration. This means that you can
                      transfer data between LawyerExpert and the respected
                      integration. Because API integration involves two
                      separately made software suites, sometimes the code can
                      conflict with each other and cause one or the other to
                      stop working. Although LawyerExpert shall attempt to
                      ensure that all integrations are up to date and reliable,
                      our software is provided “as is” and we shall not be
                      responsible for your website, or the integration service
                      ceasing to operate as a result of integration. This
                      includes any loss of operation as a result of alteration
                      to your software such as an upgrade.
                    </p>
                  </span>
                  <span>
                    <li>Indemnity </li>
                    <ol className="second-list">
                      <li>
                        You agree that you will be personally responsible for
                        your use of the Service, products or subscriptions you
                        purchase from us; and you further agree to defend,
                        indemnify and hold harmless LawyerExpert and its
                        officers, directors, employees, consultants, affiliates,
                        subsidiaries and agents from and against any and all
                        claims, liabilities, damages, losses and expenses,
                        including reasonable attorneys’ and accounting fees and
                        costs, arising out of or in any way connected with (i)
                        your access to, use of or alleged use of the Service,
                        subscription or the products you purchase through the
                        Service; (ii) your violation of these Terms of Service
                        or any representation, warranty, or agreements
                        referenced herein, or any applicable law or regulation;
                        (iii) your infringement upon the property rights,
                        intellectual property rights (copyrights and trademarks)
                        or other rights of others; (iv) your violation of any
                        third party right, including without limitation
                        publicity, confidentiality, or privacy right; (v) any
                        death, or serious physical or serious emotional harm, to
                        you or any third party resulting from your use of the
                        Services, products or subscriptions purchased though the
                        Service.
                      </li>
                      <li>
                        We reserve the right, at our own expense, to assume the
                        exclusive defence and control of any matter otherwise
                        subject to indemnification by you, and in such case, you
                        agree to cooperate with our defence of such claim.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Release</li>
                    <ol className="second-list">
                      <li>
                        By using the Services, you release, to the maximum
                        extent allowed by law, LawyerExpert, its officers,
                        directors, employees, affiliates, and agents from
                        claims, demands and damages of every kind and nature,
                        known and unknown, suspected and unsuspected, disclosed
                        and undisclosed, arising out of, or in any way connected
                        with, your use of our Services, products or
                        subscriptions, including without limitation, any death
                        or serious emotional or serious physical harm.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Governing Law</li>
                    <p>
                      This Agreement, and any separate agreements whereby we
                      provide you Services, shall be governed by and construed
                      in accordance with the laws of England and Wales, without
                      regard to its conflicts of laws principles. The parties
                      hereby consent to the exclusive jurisdiction of the courts
                      of England, where LawyerExpert LTD has established its
                      principal office.
                    </p>
                  </span>
                  <span>
                    <li>Our Remedies</li>
                    <ol className="second-list">
                      <li>
                        You acknowledge that we may be irreparably damaged if
                        this Agreement is not specifically enforced, and damages
                        at law would be an inadequate remedy. Therefore, in the
                        event of a breach or threatened breach of any provision
                        of this Agreement by you, we shall be entitled, in
                        addition to all rights and remedies, to an injunction
                        restraining such breach or threatened breach, without
                        being required to show any actual damage or to post an
                        injunction bond, and/or to a decree for specific
                        performance of the provisions of this Agreement.
                      </li>
                      <li>
                        For purposes of this Section, you agree that any action
                        or proceeding with regard to such injunction restraining
                        such breach or threatened breach shall be brought in the
                        courts of England. You consent to the jurisdiction of
                        such court and waive any objection to the venue of any
                        such action or proceeding in such court.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Dispute Resolution</li>
                    <p>
                      <b>
                        THIS AGREEMENT CONTAINS AN AGREEMENT TO ARBITRATE, WHICH
                        WILL, WITH LIMITED EXCEPTION, REQUIRE YOU TO SUBMIT
                        CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL
                        ARBITRATION. THIS AGREEMENT ALSO CONTAINS AN AGREEMENT
                        THAT YOU WILL ONLY BE PERMITTED TO PURSUE CLAIMS AGAINST
                        US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS
                        MEMBER IN ANY CLASS OR REPRESENTATIVE ACTION OR
                        PROCEEDING. PLEASE READ THE FOLLOWING TERMS CAREFULLY
                        BEFORE ACCESSING OR USING ANY OF THE SERVICE. EACH TIME
                        YOU ACCESS OR USE THE SERVICE, YOU AGREE TO BE BOUND BY
                        THESE TERMS. IF YOU DO NOT AGREE TO BE BOUND BY THE
                        MANDATORY ARBITRATION PROVISION AND THE CLASS ACTION
                        WAIVER YOU MAY NOT ACCESS OR USE THE SERVICE.
                      </b>
                    </p>
                    <p>
                      For any dispute you have with LawyerExpert, you agree to
                      first contact us at info@LawyerExpert.com and attempt to
                      resolve the dispute with us informally. If LawyerExpert
                      has not been able to resolve the dispute with you
                      informally, we each agree to resolve any claim, dispute,
                      or controversy as follows:
                    </p>
                    <ol className="second-list">
                      <ol className="third-list">
                        <li>
                          You and we agree that any claim or dispute at law or
                          equity between us relating in any way to or arising
                          out of this or previous versions of this Agreement,
                          your use of or access to the Services will be resolved
                          in accordance with the provisions set forth in this
                          Legal Disputes section. Please read this section
                          carefully. It affects your rights and will have a
                          substantial impact on how claims you and we have
                          against each other are resolved.
                        </li>
                        <li>
                          Applicable Law. You agree that the laws of England and
                          Wales, without regard to principles of conflict of
                          laws, will govern this Agreement and any claim or
                          dispute that has arisen or may arise between you and
                          us, except as otherwise stated in this Agreement.
                        </li>
                        <li>
                          Agreement to Arbitrate. You and we each agree that any
                          and all disputes or claims between you and us relating
                          in any way to or arising out of this or previous
                          versions of this Agreement, your use of or access to
                          our Services, or any courses or services delivered,
                          sold, offered, or purchased through our Services shall
                          be resolved exclusively through final and binding
                          arbitration, rather than in court, except that you may
                          assert claims in small claims court, if your claims
                          qualify.
                        </li>
                        <li>
                          <b>
                            Prohibition of Class and Representative Actions and
                            Non-Individualised Relief. YOU AND WE AGREE THAT
                            EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY
                            ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR
                            CLASS MEMBER IN ANY PURPORTED CLASS OR
                            REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU
                            AND WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT
                            CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR
                            PARTY’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER
                            ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
                            PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
                            (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY
                            RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY
                            SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
                            PROVIDE RELIEF NECESSITATED BY THAT PARTY’S
                            INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT
                            AFFECT OUR OTHER USERS.
                          </b>
                        </li>
                        <li>
                          Arbitration Procedures. Arbitration is more informal
                          than a lawsuit in court. There is no judge or jury in
                          arbitration, and court review of an arbitration award
                          is very limited. However, an arbitrator can award the
                          same damages and relief on an individual basis that a
                          court can award to an individual. An arbitrator should
                          apply the terms of this Agreement as a court would.
                          The arbitrator, and not any federal, state, or local
                          court or agency, shall have exclusive authority to
                          resolve any dispute arising out of or relating to the
                          interpretation, applicability, enforceability or
                          formation of this Agreement to Arbitrate, any part of
                          it, or of this Agreement including, but not limited
                          to, any claim that all or any part of the Agreement to
                          Arbitrate or this Agreement is void or voidable. The
                          arbitration will be conducted by the London Court of
                          International Arbitration (“LCIA”) under its rules and
                          procedures. The arbitrator will decide the substance
                          of all claims in accordance with the laws of England
                          and Wales, including recognised principles of equity,
                          and will honour all claims of privilege recognised by
                          law. The arbitrator shall not be bound by rulings in
                          prior arbitrations involving our other users but is
                          bound by rulings in prior arbitrations involving the
                          same user to the extent required by applicable law.
                          The arbitrator’s award shall be final and binding and
                          judgment on the award rendered by the arbitrator may
                          be entered in any court having jurisdiction thereof.
                        </li>
                        <p></p>
                      </ol>
                    </ol>
                  </span>
                  <span>
                    <li>Law Enforcement</li>
                    <ol className="second-list">
                      <li>
                        LawyerExpert is committed to cooperating with law
                        enforcement while respecting each individual’s right to
                        privacy. If LawyerExpert receives a request for user
                        account information from a government agency
                        investigating criminal activity, we will review the
                        request to be certain that it satisfies all legal
                        requirements before releasing information to the
                        requesting agency.
                      </li>
                      <li>
                        Furthermore, LawyerExpert may disclose user account
                        information to law enforcement, without a subpoena,
                        court order, or search warrant, in response to a valid
                        emergency when we believe that doing so is necessary to
                        prevent death or serious physical harm to someone.
                        LawyerExpert will not release more information than it
                        prudently believes is necessary to prevent harm in an
                        emergency situation.
                      </li>
                      <p></p>
                    </ol>
                  </span>
                  <span>
                    <li>Amendments to this Agreement</li>
                    <p>
                      We reserve the right to update, amend and/or change this
                      Agreement at any time in our sole discretion and without
                      notice. Updates to this Agreement will be posted here.
                      Amendments will take effect immediately upon us posting
                      the updated Agreement on our Services. You are encouraged
                      to revisit this Agreement from time to time in order to
                      review any changes that have been made. The date on which
                      this Agreement was last updated will be noted immediately
                      below this Agreement. Your continued access and use of our
                      Services following the posting of any such changes shall
                      automatically be deemed your acceptance of all changes.
                    </p>
                  </span>
                  <span>
                    <li>Severability</li>
                    <p>
                      If any portion of this Agreement is deemed unlawful, void
                      or unenforceable by any arbitrator or court of competent
                      jurisdiction, this Agreement as a whole shall not be
                      deemed unlawful, void or unenforceable, but only that
                      portion of this Agreement that is unlawful, void or
                      unenforceable shall be stricken from this Agreement.
                    </p>
                  </span>
                  <span>
                    <li>No Waiver</li>
                    <p>
                      Our failure to enforce any right or provision in these
                      Terms shall not constitute a waiver of such right or
                      provision unless acknowledged and agreed to by us in
                      writing.
                    </p>
                  </span>
                  <span>
                    <li>Entire Understanding</li>
                    <p>
                      This Agreement and the Privacy Policy represent the entire
                      understanding and agreement between you and us regarding
                      the subject matter of the same, and supersede all other
                      previous agreements, understandings and/or representations
                      regarding the same. If you have questions, comments,
                      concerns or feedback regarding this Agreement or our
                      Services, please contact us at{' '}
                      <b>info@LawyerExpert.com</b>.
                    </p>
                  </span>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TermsAndService;
