import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { CountryDropdown } from 'react-country-region-selector';
import { Link, withRouter } from 'react-router-dom';
import Cookies from 'js-cookie';
import { viewAllRecord } from '../../services/subCategoriesServives';
import {
  viewEachRecord,
  updateRecord,
  updateRecordUser,
  viewEachRecordUser,
} from '../../services/documentBankServives';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function EditDocumentBank({ children, ui, ui2, ...props }) {
  const [name, setName] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [description, setDescription] = useState('');
  const [document, setDocument] = useState('');
  const [price, setPrice] = useState('');
  const [packagePlan, setPackagePlan] = useState('');
  const [country, setCountry] = useState('');
  const [status, setStatus] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const { t } = useTranslation();

  const [nameError, setNameError] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [packagePlanError, setPackagePlanError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [countryError, setCountryError] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  useEffect(() => {
    async function getEach() {
      const id = props.match.params.id;
      if (ui === '/main-view-documents') {
        viewEachRecordUser(id)
          .then((response) => {
            setName(response.data.name);
            setDescription(response.data.description);
            setSubCategory(response.data.subCategory);
            setPackagePlan(response.data.packagePlan);
            setCountry(response.data.country);
            setPrice(response.data.price);
            setStatus(response.data.status);
          })
          .catch((error) => {
            if (error.response.data.userError) {
              Cookies.remove('token');
              Cookies.remove('creadUseDetail');
              props.history.push('/');
              window.location.reload();
            }
          });
      } else if (ui === '/cms-document-bank-view') {
        viewEachRecord(id)
          .then((response) => {
            setName(response.data.name);
            setDescription(response.data.description);
            setSubCategory(response.data.subCategory);
            setPackagePlan(response.data.packagePlan);
            setCountry(response.data.country);
            setPrice(response.data.price);
            setStatus(response.data.status);
          })
          .catch((error) => {
            if (error.response.data.userError) {
              Cookies.remove('token');
              Cookies.remove('creadUseDetail');
              props.history.push('/');
              window.location.reload();
            }
          });
      }
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for update document record
      socket.on('updateDocument', (ms) => {
        if (props.match.params.id === ms) {
          getEach();
        }
      });

      // Socket for delete document record
      socket.on('deleteDocument', (ms) => {
        if (props.match.params.id === ms) {
          props.history.push(ui);
        }
      });

      // Socket for update sub category record
      socket.on('updateSubCategory', (ms) => {
        getEach();
      });
    }
    clientSocket();
    getEach();
    getSubCat();
  }, [baseURL, props.history, props.match.params.id, ui]);

  const selectCountry = (val) => {
    setCountry(val);
  };

  const handleChange = (e) => {
    const document = e.target.files[0];
    setDocument(document);
  };

  async function getSubCat() {
    viewAllRecord()
      .then((response) => {
        setSubCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function handleSubmit() {
    if (
      name === '' ||
      subCategory === '' ||
      description === '' ||
      country === '' ||
      packagePlan === '' ||
      ((price === '' || price < 0) &&
        (packagePlan === 'pro' || packagePlan === 'business')) ||
      (price > 0 && packagePlan === 'free') ||
      (price !== '' && price < 0)
    ) {
      if (name === '') {
        setNameError('Document bank name is required');
      } else {
        setNameError(null);
      }

      if (country === '') {
        setCountryError('Country is required');
      } else {
        setCountryError(null);
      }

      if (price !== '' && price < 0) {
        setPriceError('Incorrect value');
      } else {
        setPriceError(null);
      }
      if (packagePlan === '') {
        setPackagePlanError('Plan is required');
      } else if (
        (price === '' || price < 0) &&
        (packagePlan === 'pro' || packagePlan === 'business')
      ) {
        setPriceError('Price  is required');
      } else if (price > 0 && packagePlan === 'free') {
        setPackagePlanError('Change plan to pro or business');
      } else {
        setPackagePlanError(null);
      }
      if (description === '') {
        setDescriptionError('Description is required');
      } else {
        setDescriptionError(null);
      }
    } else {
      setNameError(null);
      setDocumentError(null);
      setDescriptionError(null);
      setPackagePlanError(null);
      setCountryError(null);
      editData();
    }
  }

  async function editData() {
    const data = new FormData();
    if (typeof subCategory === 'object') {
      data.append('subCategory', subCategory._id);
    } else {
      data.append('subCategory', subCategory);
    }
    if (document !== '') {
      data.append('document', document);
    }
    data.append('name', name);
    data.append('description', description);
    data.append('price', price);
    data.append('packagePlan', packagePlan);
    data.append('country', country);
    data.append('status', status);
    const id = props.match.params.id;
    if (ui === '/cms-document-bank-view') {
      updateRecord(data, id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('document', `${userId}`);
          socket.emit('updateDocument', `${props.match.params.id}`);
          props.history.push(ui);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
          if (error.response.data.error) {
            setDocumentError(error.response.data.error);
          }
        });
    } else if (ui === '/main-view-documents') {
      updateRecordUser(data, id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('document', `${userId}`);
          socket.emit('updateDocument', `${props.match.params.id}`);
          props.history.push(ui);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
          if (error.response.data.error) {
            setDocumentError(error.response.data.error);
          }
        });
    }
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <Link to={ui2} className="btn-style btn-info mr-2">
                {t('dViewDocumentBank')}
              </Link>
            </div>
          </div>
          {/* /.card-header */}
          <div className="card-body">
            <div className="form-row">
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('dSubCategoryList')}
                  <i className="required-detail">*</i>
                </label>
                <select
                  className="form-control"
                  name={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option value={subCategory._id}>{subCategory.name}</option>
                  {subCategories.map((detail) => (
                    <option key={detail._id} value={detail._id}>
                      {detail.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('dEditDocumentBank')}
                  <i className="required-detail">*</i>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name={name}
                  placeholder={t('dDocumentBankName')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameError ? (
                  <p className="errorMessage"> {nameError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('dUpdateFile')}
                  <i className="required-detail">*</i>
                </label>

                <input
                  className="form-control"
                  type="file"
                  name={document}
                  accept="application/pdf, .docx, .doc"
                  //value={document}
                  //onChange={(e) => setDocument(e.target.files[0])}
                  onChange={handleChange}
                />
                {documentError ? (
                  <p className="errorMessage"> {documentError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label> {t('dPrice')}</label>
                <input
                  className="form-control"
                  type="number"
                  name={price}
                  placeholder={t('dPrice')}
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                {priceError ? (
                  <p className="errorMessage"> {priceError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('dPlan')}
                  <i className="required-detail">*</i>
                </label>
                <select
                  className="form-control"
                  name={packagePlan}
                  value={packagePlan}
                  onChange={(e) => setPackagePlan(e.target.value)}
                >
                  <option value="free">{t('pFree')}</option>
                  <option value="pro">{t('pPro')}</option>
                  <option value="business">{t('pBussiness')}</option>
                </select>
                {packagePlanError ? (
                  <p className="errorMessage"> {packagePlanError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('country')}
                  <i className="required-detail">*</i>
                </label>
                <CountryDropdown
                  className="form-control"
                  name={country}
                  value={country}
                  onChange={(val) => selectCountry(val)}
                />

                {countryError ? (
                  <p className="errorMessage"> {countryError}</p>
                ) : null}
              </div>

              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('status')}
                  <i className="required-detail">*</i>
                </label>
                <select
                  className="form-control"
                  name={status}
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="approved">{t('dApproved')}</option>
                  <option value="unapproved">{t('dUnapproved')}</option>
                </select>
              </div>

              <div className="col-12 form-group">
                <label>
                  {t('description')}
                  <i className="required-detail">*</i>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  name={description}
                  placeholder={t('description')}
                  rows="10"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {descriptionError ? (
                  <p className="errorMessage"> {descriptionError}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="card-header">
            <div className="card-title">
              <Link
                to="#"
                onClick={() => handleSubmit()}
                className="btn-style btn-info mr-2"
              >
                {t('dUpdateRecord')}
              </Link>
            </div>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
        {/* /.card */}
      </div>
      {/* /.col */}
    </div>
  );
}

export default withRouter(EditDocumentBank);
