import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { addRecord } from '../../services/documentBankServives';

function AddDocumentBank({ children, ui, ui2, ui3, ...props }) {
  const [subject, setSubject] = useState('');
  //const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  const [subjectError, setSubjectError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  useEffect(() => {
    // getSubCat();
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  // const onEditorStateChange = async (editorState) => {
  //   console.log('working');
  //   await setEditorState(editorState);
  // };

  async function handleSubmit() {
    if (subject === '' || editorState === '') {
      if (subject === '') {
        setSubjectError('Subject is required');
      } else {
        setSubjectError(null);
      }

      if (editorState === '') {
        setDescriptionError('Description is required');
      } else {
        setDescriptionError(null);
      }
    } else {
      setSubjectError(null);
      setDescriptionError(null);
      addData();
    }
  }

  async function addData() {
    const output = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    console.log(output);
    addRecord(subject, output)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('document', `${userId}`);
        props.history.push(ui);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <Link to={ui2} className="btn-style btn-info mr-2">
                View contact list
              </Link>
              <Link to={ui3} className="btn-style btn-info mr-2">
                View newsletter list
              </Link>
            </div>
          </div>
          {/* /.card-header */}
          <div className="card-body">
            <div className="form-row">
              <div className="col-md-6 col-12 form-group">
                <label>
                  Subject
                  <i className="required-detail">*</i>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name={subject}
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                {subjectError ? (
                  <p className="errorMessage"> {subjectError}</p>
                ) : null}
              </div>

              <div className="col-10 form-group">
                <label>
                  Message
                  <i className="required-detail">*</i>
                </label>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="form-control"
                  onEditorStateChange={onEditorStateChange}
                />
                {descriptionError ? (
                  <p className="errorMessage"> {descriptionError}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="card-header">
            <div className="card-title">
              <Link
                to="#"
                onClick={() => handleSubmit()}
                className="btn-style btn-info mr-2"
              >
                Add New Record
              </Link>
            </div>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
        {/* /.card */}
      </div>
      {/* /.col */}
    </div>
  );
}

export default withRouter(AddDocumentBank);
