import React from 'react';
import { NavLink } from 'react-router-dom';

export default function UserProfile() {
  return (
    <div className="col-md-3 col-12 profile-side-bar">
      <ul>
        <li>
          <NavLink
            activeClassName="selected"
            to={{ pathname: '/cms/user/profile' }}
          >
            Profile
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="selected"
            to={{ pathname: '/cms-view-user-area-of-law' }}
          >
            Areas of expertise
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="selected"
            to={{ pathname: '/cms-view-user-documents' }}
          >
            My documents
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            activeClassName="selected"
            to={{ pathname: '/user-billing' }}
          >
            Billing{' '}
          </NavLink>
        </li> */}
        <li>
          <NavLink
            activeClassName="selected"
            to={{ pathname: '/cms-view-user-change-password' }}
          >
            Change password
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
