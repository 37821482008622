import React from 'react';
import AddNewsletter from '../../components/newsletter/add-newsletter';

function Newsletter() {
  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">{/* /.container-fluid */}</section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <AddNewsletter
              ui="/cms/contact-list"
              ui2="/cms/contact-list"
              ui3="/cms/contact-list"
            />

            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default Newsletter;
