import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { BsBoxArrowInDown, BsPencil } from 'react-icons/bs';
import io from 'socket.io-client';
import TopSection from '../../components/TopSection';
import StripeCheckout from 'react-stripe-checkout';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import { postCheckout } from '../../services/documentBankServives';
import {
  updateUserProfile,
  viewUserProfile,
} from '../../services/usersServives';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function CookiesPolicy(props) {
  const [user, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const [product, setProduct] = useState({
    name: '',
    price: '',
    description: '',
    plan: '',
    billing: '',
    subscription: '',
  });
  const [userDetail, setUserDetail] = useState({
    subscriptionActivate: '',
    subscription: '',
  });
  const { t } = useTranslation();

  let parsed = queryString.parse(props.location.search);

  useEffect(() => {
    async function getData() {
      if (parsed.billing === 'annual billing pro') {
        setProduct({
          price: 490,
          name: 'annual billing pro',
          plan: 'subscription',
          billing: 'annual',
          subscription: 'pro',
          description:
            'Pro package provides access to all personal documents in our document bank and on demand guidance on how to navigate your issues that require advice from qualified solicitors. ',
        });
      } else if (parsed.billing === 'annual billing business') {
        setProduct({
          price: 990,
          name: 'annual billing business',
          plan: 'subscription',
          billing: 'annual',
          subscription: 'business',
          description:
            'Business package provides access to all documents in our document bank and on demand guidance on how to navigate your issues that require advice from qualified solicitors.',
        });
      } else if (parsed.billing === 'monthly billing pro') {
        setProduct({
          price: 49,
          plan: 'subscription',
          name: 'monthly billing pro',
          billing: 'monthly',
          subscription: 'pro',
          description:
            'Pro package provides access to all personal documents in our document bank and on demand guidance on how to navigate your issues that require advice from qualified solicitors.',
        });
      } else if (parsed.billing === 'monthly billing business') {
        setProduct({
          price: 99,
          plan: 'subscription',
          name: 'monthly billing business',
          billing: 'monthly',
          subscription: 'business',
          description:
            'Business package provides access to all documents in our document bank and on demand guidance on how to navigate your issues that require advice from qualified solicitors.',
        });
      } else {
        props.history.push('/pricing');
      }
      viewUserProfile()
        .then((response) => {
          setUsers(response.data.user);
          setUserDetail(response.data.user);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 3000);

    async function clientSocket() {
      const socket = io(baseURL);
      const userId = Cookies.get('creadUseDetail');

      // Socket for update user record
      socket.on('updateUser', (ms) => {
        if (userId === ms) {
          getData();
        }
      });
    }
    clientSocket();

    getData();
  }, [baseURL, isLoading, parsed.billing, props.history]);

  async function handleToken(token) {
    setIsLoading(true);
    postCheckout(token, product)
      .then((response) => {
        if (
          parsed.billing === 'annual billing pro' ||
          parsed.billing === 'monthly billing pro'
        ) {
          props.history.push('/pro-documents');
          window.location.reload();
        } else if (
          parsed.billing === 'annual billing business' ||
          parsed.billing === 'monthly billing business'
        ) {
          props.history.push('/business-documents');
          window.location.reload();
        } else {
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function upgradeToBusiness() {
    if (
      window.confirm(
        'Are you sure you want to upgrade your billing to business?'
      )
    ) {
      userDetail.subscriptionActivate = 'upgradeToBusiness';
      userDetail.subscription = 'business';
      updateUserProfile(userDetail)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('user', `${userId}`);
          socket.emit('updateUser', `${userId}`);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
  }

  function showCard() {
    if (
      user.billing === 'annual' &&
      user.subscription === 'business' &&
      (user.subscriptionActivate === 'activate' ||
        user.subscriptionActivate === 'upgradeToBusiness')
    ) {
      return <p>You have already annual business</p>;
    } else if (
      user.billing === 'annual' &&
      user.subscription === 'pro' &&
      user.subscriptionActivate === 'activate' &&
      (parsed.billing === 'annual billing pro' ||
        parsed.billing !== 'annual billing business')
    ) {
      return <p>You have already annual pro</p>;
    } else if (
      user.billing === 'monthly' &&
      user.subscription === 'business' &&
      (user.subscriptionActivate === 'activate' ||
        user.subscriptionActivate === 'upgradeToBusiness') &&
      (parsed.billing === 'monthly billing pro' ||
        parsed.billing === 'monthly billing business')
    ) {
      return <p>You have already monthly business</p>;
    } else if (
      user.billing === 'monthly' &&
      (user.subscription === 'pro' || user.subscription === 'business') &&
      user.subscriptionActivate === 'activate' &&
      parsed.billing === 'monthly billing pro'
    ) {
      return <p>You have already monthly pro</p>;
    } else if (
      user.billing === 'monthly' &&
      (parsed.billing === 'annual billing pro' ||
        parsed.billing === 'annual billing business')
    ) {
      return (
        <p>
          You have already monthly plan. Please contact us with the below
          details if you neeed to upgrade to Annual Billing Plan.
          <br />
          <b>Email: </b>info@lawyerexpert.com
          <br />
          <b>Tel: </b>+44 208 123 8313
        </p>
      );
    } else {
      return (
        <>
          {!isLoading && !user.billing && !user.subscription && (
            <StripeCheckout
              stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST}
              token={handleToken}
              billingAddress
              amount={product.price * 100}
              name={process.name}
              currency="GBP"
            >
              <button className="dow-buy">
                Pay and Access{' '}
                <span className="icon-started">
                  <BsBoxArrowInDown />
                </span>
              </button>
            </StripeCheckout>
          )}
          {user.billing &&
            user.subscription &&
            user.subscriptionActivate === 'activate' && (
              <button onClick={() => upgradeToBusiness()} className="dow-buy">
                Upgrade To Business{' '}
                <span className="icon-started">
                  <BsPencil />
                </span>
              </button>
            )}
        </>
      );
    }
  }

  return (
    <Page title={t('pConfirmation')} content={t('pContent')} href="/">
      <TopSection className="billingTitle" heading={parsed.billing} />
      <div className="contact billing-content">
        <div className="main_container">
          {isLoading && (
            <div className="main_spinner">
              <Button variant="primary" disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </Button>
            </div>
          )}
          <div className="container">
            <div className="row  justify-content-center" data-aos="fade-up">
              <div className="col-md-5 php-email-form" data-aos="fade-up">
                <p>{product.description}</p>
                <hr />
                <p>
                  <b>Subscription:</b>
                  <span> {product.name}</span>
                </p>
                <hr />
                <p>
                  <b>Total to pay:</b> <span>£{product.price}</span>
                </p>
                <hr />
                {showCard()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default CookiesPolicy;
