import React from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { postCheckout } from '../../services/documentBankServives';

function Text() {
  const [product] = React.useState({
    name: 'Tesla Roadster',
    price: '39.99',
    description: 'Coolcar',
  });

  async function handleToken(token) {
    postCheckout(token, product)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div className="container">
      <div className="product">
        <h1>{product.name}</h1>
        <h3>On Sale £{product.price}</h3>
      </div>
      <StripeCheckout
        stripeKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST}
        token={handleToken}
        billingAddress
        amount={product.price * 100}
        name={process.name}
      />
    </div>
  );
}

export default Text;
