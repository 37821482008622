const areaOfLaw = {
  lawFirm: 'Law Firm',
  address: 'Address',
  country: 'Country',
  city: 'City',
  county: 'County',
  postCode: 'Post Code',
  legalExpertise: 'Legal Expertise',
  viewProfile: 'View Profile',
  connect: 'Connect',
  website: 'Website',
  telephone: 'Telephone',
  back: 'Back',
  linkedin: 'LinkedIn',
  youtube: 'YouTube',
  visitYoutube: 'Visit YouTube',
  visitLinkedin: 'Visit LinkedIn',
  visitWebsite: 'Visit Website',
  linkedinProfile: 'LinkedIn profile',
  description: 'Description',
  profileSummary: 'Profile Summary',
  findALawyerContent: 'Find a lawyer content',
  searchLawyers: 'Search lawyers...',
  selectAreaOfLaw: 'Select Area of Law',
  user: 'User',
  users: 'Users',
};

export default areaOfLaw;
