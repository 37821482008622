import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import { CountryDropdown } from 'react-country-region-selector';
import { viewAllRecord } from '../../services/subCategoriesServives';
import { addRecord } from '../../services/documentBankServives';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function AddDocumentBank({ children, ui, ui2, ...props }) {
  const [name, setName] = useState('');
  const [document, setDocument] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [packagePlan, setPackagePlan] = useState('');
  const [country, setCountry] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  const [nameError, setNameError] = useState('');
  const [subCateNameError, setSubCategoryError] = useState('');
  const [documentError, setDocumentError] = useState('');
  const [priceError, setPriceError] = useState('');
  const [packagePlanError, setPackagePlanError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [countryError, setCountryError] = useState('');

  useEffect(() => {
    getSubCat();
  }, []);

  const handleChange = (e) => {
    const document = e.target.files[0];
    setDocument(document);
  };

  const selectCountry = (val) => {
    setCountry(val);
  };

  async function handleSubmit() {
    if (
      name === '' ||
      subCategory === '' ||
      document === '' ||
      country === '' ||
      description === '' ||
      packagePlan === '' ||
      ((price === '' || price < 0) &&
        (packagePlan === 'pro' || packagePlan === 'business')) ||
      (price > 0 && packagePlan === 'free') ||
      (price !== '' && price < 0)
    ) {
      if (name === '') {
        setNameError('Document bank name is required');
      } else {
        setNameError(null);
      }
      if (price !== '' && price < 0) {
        setPriceError('Incorrect value');
      } else {
        setPriceError(null);
      }

      if (packagePlan === '') {
        setPackagePlanError('Plan is required');
      } else if (
        (price === '' || price < 0) &&
        (packagePlan === 'pro' || packagePlan === 'business')
      ) {
        setPriceError('Price is required');
        setPackagePlanError(null);
      } else if (price > 0 && packagePlan === 'free') {
        setPackagePlanError('Change plan to pro or business');
      } else {
        setPackagePlanError(null);
      }

      if (document === '') {
        setDocumentError('Document is required');
      } else {
        setDocumentError(null);
      }
      if (subCategory === '') {
        setSubCategoryError('Sub category name is required');
      } else {
        setSubCategoryError(null);
      }
      if (country === '') {
        setCountryError('Country is required');
      } else {
        setCountryError(null);
      }
      if (description === '') {
        setDescriptionError('Description is required');
      } else {
        setDescriptionError(null);
      }
    } else {
      setNameError(null);
      setSubCategoryError(null);
      setDocumentError(null);
      setDescriptionError(null);
      setPackagePlanError(null);
      setCountryError(null);
      setPriceError(null);
      addData();
    }
  }

  async function addData() {
    const data = new FormData();
    data.append('name', name);
    data.append('subCategory', subCategory);
    data.append('document', document);
    data.append('price', price);
    data.append('packagePlan', packagePlan);
    data.append('country', country);
    data.append('description', description);
    addRecord(data)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('document', `${userId}`);
        props.history.push(ui);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
        if (error.response.data.error) {
          setDocumentError(error.response.data.error);
        }
      });
  }

  async function getSubCat() {
    viewAllRecord()
      .then((response) => {
        setSubCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <Link to={ui2} className="btn-style btn-info mr-2">
                {t('dViewDocumentBank')}
              </Link>
            </div>
          </div>
          {/* /.card-header */}
          <div className="card-body">
            <div className="form-row">
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('dSubCategoryList')}
                  <i className="required-detail">*</i>
                </label>
                <select
                  className="form-control"
                  name={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  <option value=""> {t('dSelectSubCategory')}</option>
                  {subCategories.map((detail) => (
                    <option key={detail._id} value={detail._id}>
                      {detail.name}
                    </option>
                  ))}
                </select>

                {subCateNameError ? (
                  <p className="errorMessage"> {subCateNameError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('dDocumentBankName')}
                  <i className="required-detail">*</i>
                </label>
                <input
                  className="form-control"
                  type="text"
                  name={name}
                  placeholder={t('dDocumentBankName')}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {nameError ? (
                  <p className="errorMessage"> {nameError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('dFile')}
                  <i className="required-detail">*</i>
                </label>

                <input
                  className="form-control"
                  type="file"
                  name={document}
                  accept="application/pdf, .docx, .doc"
                  //onChange={(e) => setDocument(e.target.files[0])}
                  onChange={handleChange}
                />
                {documentError ? (
                  <p className="errorMessage"> {documentError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>{t('dPrice')}</label>
                <input
                  className="form-control"
                  type="number"
                  name={price}
                  placeholder={t('dPrice')}
                  onChange={(e) => setPrice(e.target.value)}
                />
                {priceError ? (
                  <p className="errorMessage"> {priceError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('dPlan')}
                  <i className="required-detail">*</i>
                </label>
                <select
                  className="form-control"
                  name={packagePlan}
                  onChange={(e) => setPackagePlan(e.target.value)}
                >
                  <option value=""> {t('dSelectPlan')}</option>
                  <option value="free">{t('pFree')}</option>
                  <option value="pro">{t('pPro')}</option>
                  <option value="business">{t('pBussiness')}</option>
                </select>
                {packagePlanError ? (
                  <p className="errorMessage"> {packagePlanError}</p>
                ) : null}
              </div>
              <div className="col-md-6 col-12 form-group">
                <label>
                  {t('country')}
                  <i className="required-detail">*</i>
                </label>
                <CountryDropdown
                  className="form-control"
                  name={country}
                  value={country}
                  onChange={(val) => selectCountry(val)}
                />

                {countryError ? (
                  <p className="errorMessage"> {countryError}</p>
                ) : null}
              </div>

              <div className="col-12 form-group">
                <label>
                  {t('description')}
                  <i className="required-detail">*</i>
                </label>
                <textarea
                  className="form-control"
                  type="text"
                  name={description}
                  placeholder={t('description')}
                  rows="10"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                {descriptionError ? (
                  <p className="errorMessage"> {descriptionError}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="card-header">
            <div className="card-title">
              <Link
                to="#"
                onClick={() => handleSubmit()}
                className="btn-style btn-info mr-2"
              >
                {t('dAddNewRecord')}
              </Link>
            </div>
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
        {/* /.card */}
      </div>
      {/* /.col */}
    </div>
  );
}

export default withRouter(AddDocumentBank);
