import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import moment from 'moment';
import Cookies from 'js-cookie';
import io from 'socket.io-client';
import { Pagination } from '../../components/pagination/index';

import { viewAllRecord, deleteRecord } from '../../services/contactServives';

function DocumentBank(props) {
  const [documentBank, setDocumentBank] = useState([]);
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * page.perPage;
  const pageCount = Math.ceil(page.total / page.perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting contact record
      socket.on('contact', (ms) => {
        getAll();
      });
    }
    clientSocket();
    getAll();
  }, [props.location.search]);

  async function getAll() {
    const filter = props.location.search;
    viewAllRecord(filter)
      .then((response) => {
        setDocumentBank(response.data.contacts);
        setPage(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  function handleSubmit(values) {
    props.history.push(
      `/cms/contact-list?type=${values.type}&status=${values.status}`
    );
    getAll();
  }

  async function deleteData(id) {
    if (
      window.confirm('Are you sure you want to delete this contact record?')
    ) {
      deleteRecord(id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('contact', `${userId}`);
          socket.emit('deleteContact', `${id}`);
        })
        .catch((error) => {
          if (error.response.data.userError) {
            Cookies.remove('token');
            Cookies.remove('creadUseDetail');
            props.history.push('/');
            window.location.reload();
          }
        });
    }
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-3">
                <Link to="/cms-newsletter-add" className="btn btn-primary mr-2">
                  Send newsletter
                </Link>
              </div>
              <div className="col-9 search-side">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    type: type || '',
                    status: status || '',
                  }}
                  enableReinitialize={true}
                >
                  {({ submitForm }) => (
                    <Form className="searchForm">
                      <select
                        className="input"
                        name="type"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        onChangeCapture={submitForm}
                      >
                        <option value="">Select type</option>
                        <option value="contact">Contact</option>
                        <option value="connect">Connect</option>
                        <option value="subscribe">Subscribe</option>
                      </select>
                      <select
                        className="input"
                        name="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        onChangeCapture={submitForm}
                      >
                        <option value="">Select status</option>

                        <option value="checked">checked</option>
                        <option value="unchecked">unchecked</option>
                      </select>

                      <Link
                        className="clear"
                        to="/cms/contact-list"
                        onClick={() => {
                          window.location.href = `/cms/contact-list`;
                        }}
                      >
                        Clear Filter
                      </Link>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-hover"
                    >
                      <thead className="headerTable">
                        <tr>
                          <th>Date and Time</th>
                          <th>Name</th>
                          <th>Subject</th>
                          <th>email</th>
                          <th>Number</th>
                          <th>Type</th>
                          <th>Status</th>
                          <th>Message</th>
                          <th colSpan="3" className="col-span">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentBank
                          .slice(pageVisited, pageVisited + page.perPage)
                          .map((detail) => (
                            <tr key={detail._id}>
                              <td>
                                {moment(detail.createdAt).format(
                                  'DD/MM/YYYY HH:mm'
                                )}
                              </td>
                              <td>{detail.name}</td>
                              <td>{detail.subject}</td>
                              <td>{detail.email}</td>
                              <td>{detail.number}</td>
                              <td>{detail.type}</td>
                              <td>
                                {detail.status == 'checked' ? (
                                  <span className="checked">
                                    {detail.status}
                                  </span>
                                ) : (
                                  <span className="unchecked">
                                    {detail.status}
                                  </span>
                                )}
                              </td>
                              <td>
                                {detail.message ? (
                                  <>{detail.message.substring(0, 15)}...</>
                                ) : null}
                              </td>
                              <td className="col-span">
                                <Link
                                  className="btn btn-secondary mr-2"
                                  to={{
                                    pathname: `/cms/contact/${detail._id}`,
                                  }}
                                >
                                  View
                                </Link>
                              </td>
                              <td className="col-span">
                                <Link
                                  className="btn btn-primary mr-2"
                                  to={{
                                    pathname: `/cms/contact/${detail._id}/edit`,
                                  }}
                                >
                                  Edit
                                </Link>
                              </td>
                              <td className="col-span">
                                <Link
                                  to="#"
                                  onClick={() => deleteData(detail._id)}
                                  className="btn btn-danger mr-2 disabled-lin"
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination pageCount={pageCount} changePage={changePage} />
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default DocumentBank;
