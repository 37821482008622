export default [
	{ id: '1', imageName: 'img1.jpeg', tag: 'free', name:'name 1' },
	{ id: '2', imageName: 'img2.jpeg', tag: 'new', name:'name 2' },
	{ id: '3', imageName: 'img3.jpeg', tag: 'pro', name:'name 3' },
	{ id: '4', imageName: 'img4.jpeg', tag: 'pro', name:'name 4' },
	{ id: '5', imageName: 'img5.jpeg', tag: 'free', name:'name 5' },
	{ id: '6', imageName: 'img6.jpeg', tag: 'new', name:'name 6' },
	{ id: '7', imageName: 'img6.jpeg', tag: 'pro', name:'name 7' },
	{ id: '8', imageName: 'img8.jpeg', tag: 'free', name:'name 8' },
	{ id: '9', imageName: 'img9.jpeg', tag: 'new', name:'name 9' },
	{ id: '10', imageName: 'img10.jpeg', tag: 'new', name:'name 10' },
	{ id: '11', imageName: 'img11.jpeg', tag: 'new', name:'name 11' },
	{ id: '12', imageName: 'img12.jpeg', tag: 'new', name:'name 12' },
	{ id: '13', imageName: 'img13.jpeg', tag: 'free', name:'name 21' },
	{ id: '14', imageName: 'img14.jpeg', tag: 'pro', name:'name 18' },
	{ id: '15', imageName: 'img15.jpeg', tag: 'free', name:'name 133' },
	{ id: '16', imageName: 'img16.jpeg', tag: 'new', name:'name 87' }
];
