import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import io from 'socket.io-client';
import moment from 'moment';
import Cookies from 'js-cookie';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Form, Formik } from 'formik';
import { Pagination } from '../../components/pagination/index';
import {
  viewAllDocumentDownloaded,
  deleteRecordDocumentDownloaded,
} from '../../services/documentBankServives';
const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;

function DocumentBank(props) {
  const [documentBank, setDocumentBank] = useState([]);
  const [status, setStatus] = useState('');
  const [page, setPage] = useState({});
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * page.perPage;
  const pageCount = Math.ceil(page.total / page.perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting document download record
      socket.on('documentDownload', (ms) => {
        getAll();
      });
    }
    clientSocket();
    getAll();
  }, [props.location.search]);

  async function getAll() {
    const filter = props.location.search;
    viewAllDocumentDownloaded(filter)
      .then((response) => {
        setDocumentBank(response.data.documentDownload);
        setPage(response.data);
      })
      .catch((error) => {
        if (error.response.data.userError) {
          Cookies.remove('token');
          Cookies.remove('creadUseDetail');
          props.history.push('/');
          window.location.reload();
        }
      });
  }

  function handleSubmit(values) {
    props.history.push(
      `/cms-document-bank-view-download?status=${values.status}`
    );
    getAll();
  }

  async function deleteData(id) {
    if (window.confirm('Are you sure you want to delete this record?')) {
      deleteRecordDocumentDownloaded(id)
        .then((response) => {
          const socket = io(baseURL);
          const userId = Cookies.get('creadUseDetail');
          socket.emit('documentDownload', `${userId}`);
        })
        .catch((error) => {
          alert('error');
        });
    }
  }

  return (
    <div className="content-wrapper">
      <div>
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 search-side">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    status: status || '',
                  }}
                  enableReinitialize={true}
                >
                  {({ submitForm }) => (
                    <Form className="searchForm">
                      <select
                        className="input"
                        name="status"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        onChangeCapture={submitForm}
                      >
                        <option value="">Select device</option>
                        <option value="Mobile">Mobile</option>
                        <option value="Browser">Browser</option>
                      </select>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  {/* <div className="card-header">
                    <div className="card-title">
                      <Link
                        to="/cms-document-bank-add"
                        className="btn btn-primary mr-2"
                      >
                        Upload Document
                      </Link>
                    </div>
                  </div> */}
                  {/* /.card-header */}
                  <div className="card-body">
                    <table
                      id="example2"
                      className="table table-bordered table-hover"
                    >
                      <thead className="headerTable">
                        <tr>
                          <th>Created At</th>
                          <th>Document Added By</th>
                          <th>Document Name</th>
                          <th> Downloaded By</th>
                          <th>Device</th>
                          <th className="col-span">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {documentBank
                          .slice(pageVisited, pageVisited + page.perPage)
                          .map((detail) => (
                            <tr key={detail._id}>
                              <td>
                                {moment(detail.createdAt).format(
                                  'DD/MM/YYYY HH:mm'
                                )}
                              </td>
                              <td>{`${detail.documentBank.user.firstName}  ${detail.documentBank.user.lastName}`}</td>
                              <td>{detail.documentBank.name}</td>
                              <td>{`${detail.user.firstName}  ${detail.user.lastName}`}</td>
                              <td>{detail.device}</td>
                              <td className="col-span">
                                <Link
                                  to="#"
                                  onClick={() => deleteData(detail._id)}
                                  className="btn btn-danger mr-2 disabled-lin"
                                >
                                  Delete
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <Pagination pageCount={pageCount} changePage={changePage} />
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </div>
  );
}

export default DocumentBank;
