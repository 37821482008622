import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import io from 'socket.io-client';
import TopSection from '../../components/TopSection';
import { viewAllRecordCategory } from '../../services/categoriesServives';
import { viewAllRecordFrontend } from '../../services/subCategoriesServives';
import SideDocument from '../../components/document/SideDocument';
import TopSearch from '../../components/document/TopSearch';
import { Page } from '../../components/page';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

const TagButton = ({ name, tagActive, onClick }) => {
  return (
    <button className={`tag ${tagActive ? 'active' : null}`} onClick={onClick}>
      {name.toUpperCase()}
    </button>
  );
};

function Documents(props) {
  const [tag, setTag] = useState('all');
  const [documentBank, setDocumentBank] = useState([]);
  const [categories, setCategories] = useState([]);
  const [countDocuments, setCountDocument] = useState([]);
  const [filter, setFilter] = useState('');
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();

  useEffect(() => {
    async function getAll() {
      const filter = props.location.search;
      viewAllRecordFrontend(filter)
        .then((response) => {
          setDocumentBank(response.data.subCategories);
          setCountDocument(response.data.countDocument);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    async function clientSocket() {
      const socket = io(baseURL);

      // Socket for getting document record
      socket.on('document', (ms) => {
        getAll();
      });

      // Socket for getting sub category record
      socket.on('subCategory', (ms) => {
        getAll();
      });

      // Socket for getting category record
      socket.on('category', (ms) => {
        getAll();
        getCategories();
      });
    }
    clientSocket();
    getAll();
    getCategories();
  }, [baseURL, props.location.search]);

  function handleSubmitFilter(values) {
    if (values.filter) {
      props.history.push(
        `/search-documents/${values.filter}?filter=${values.filter}`
      );
    }
  }

  function handleSubmit(id, name) {
    props.history.push(`/document-bank?filter=${id}`);
    setTag(name);
    //getAll();
  }
  function reset() {
    props.history.push(`/document-bank`);
    setTag('all');
    //getAll();
  }

  async function getCategories() {
    viewAllRecordCategory()
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <Page
      title={t('dDocumentTitle')}
      content={t('dDocumentContent')}
      href="/document-bank"
    >
      <TopSection heading={t('dDocumentTitle')} />
      <div className="main_container portfolio">
        <div className="container">
          <div className="row search-space">
            <TopSearch
              onSubmit={handleSubmitFilter}
              placeholder="Document name"
              classNameInvisible="remove-clear-input"
              initialValues={{
                filter: filter || '',
              }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onClick={() => {
                window.location.href = `/document-bank`;
              }}
              to="/document-bank"
            />
          </div>
          <div className="row">
            <div className="col-md-8 col-12">
              <div className="document">
                <div className="tags">
                  <TagButton
                    name="all"
                    tagActive={tag === 'all' ? true : false}
                    onClick={() => {
                      reset();
                    }}
                  />
                  {categories.map((detail) => (
                    <span key={detail._id}>
                      <TagButton
                        name={detail.name}
                        tagActive={tag === detail.name ? true : false}
                        onClick={() => {
                          handleSubmit(detail._id, detail.name);
                        }}
                      />
                    </span>
                  ))}
                </div>
              </div>
              <div className="row portfolio-container document">
                <div className="container">
                  {documentBank.map((details) => (
                    <Link
                      to={{
                        pathname: `/sub-documents/${details.name}`,
                        search: `?status=${details._id}`,
                      }}
                    >
                      <div
                        key={details._id}
                        className="col-12 portfolio-item image-card doc_bank doc_main"
                      >
                        <p className="top-name">{details.name}</p>
                        <Link
                          to={{
                            pathname: `/sub-documents/${details.name}`,
                            search: `?status=${details._id}`,
                          }}
                        >
                          {/* <img
                          className="image"
                          src={`/images/document-bank.png`}
                          alt=""
                        /> */}
                        </Link>
                        <div className="portfolio-info">
                          {/* <h4>{details.name}</h4> */}
                          <p>{details.category.name}</p>
                          <Link
                            to={{
                              pathname: `/sub-documents/${details.name}`,
                              search: `?status=${details._id}`,
                            }}
                            className="details-link"
                            title="More Details"
                          >
                            <i className="fas fa-link"></i>
                          </Link>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            {/* Document side  */}
            <SideDocument
              className="col-md-4 col-12 doc-side"
              onSubmit={handleSubmitFilter}
              placeholder="Document name"
              classNameInvisible="remove-clear-input"
              initialValues={{
                filter: filter || '',
              }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              onClick={() => {
                window.location.href = `/document-bank`;
              }}
              to="/document-bank"
              countDocuments={countDocuments}
            />
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withRouter(Documents);
