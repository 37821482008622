import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';
import { BsBoxArrowInDown } from 'react-icons/bs';
import io from 'socket.io-client';
import Cookies from 'js-cookie';
import { addDocumentDownloaded } from '../../services/documentBankServives';
import { Pagination } from '../../components/pagination/index';
import { useTranslation } from 'react-i18next';
import { localisations } from '../../localisations';
localisations();

function ViewEachDocumentBank({
  children,
  ui,
  ui2,
  documentBank,
  perPage,
  total,
}) {
  const baseURL = process.env.REACT_APP_BASE_URL_DOCUMENT;
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(0);
  const pageVisited = pageNumber * perPage;
  const pageCount = Math.ceil(total / perPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  async function addDocumentDownload(id) {
    const documentBank = id;
    let device = null;
    const user = '';
    if (isMobile) {
      device = 'Mobile';
    } else if (isBrowser) {
      device = 'Browser';
    }
    addDocumentDownloaded(device, documentBank, user)
      .then((response) => {
        const socket = io(baseURL);
        const userId = Cookies.get('creadUseDetail');
        socket.emit('documentDownload', `${userId}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <div className="col-sm-12">
      <div className="row">
        {documentBank.length ? (
          <>
            {documentBank
              .slice(pageVisited, pageVisited + perPage)
              .map((details) => (
                <div
                  key={details._id}
                  className="doc_main pgBtSp col-md-12 col-12"
                >
                  <div className="row doc_sub_main">
                    <div className="col-2">
                      <i className="fas fa-file-alt"></i>
                    </div>
                    <div className="col-10">
                      <h4 className="doc_title">{details.name}</h4>
                    </div>
                    <div className="col-12">
                      {details.description?.split('\n\n').map((paragraph) => (
                        <p key={paragraph} className="doc_down">
                          {paragraph
                            .split('\n')
                            .reduce((total, line) => [total, <br />, line])}
                        </p>
                      ))}
                    </div>
                    <div className="col-3">
                      <h5>
                        {details.price === 'Free' ? (
                          <span>{details.price}</span>
                        ) : (
                          <span>
                            {details.price ? (
                              <>
                                {' '}
                                <sup>{t('dCurrency')}</sup>
                                {details.price}
                              </>
                            ) : (
                              <span className="doc_free">{t('pFree')}</span>
                            )}
                          </span>
                        )}
                      </h5>
                    </div>

                    <div className="col-9 btn-doc-right">
                      <a
                        onClick={() => addDocumentDownload(details._id)}
                        className="doc-download"
                        rel="noopener noreferrer"
                        target="_blank"
                        href={`${baseURL}/images/${details.document}`}
                      >
                        <button className="dow-buy">
                          {t('dDownload')}
                          <span className="icon-started">
                            <BsBoxArrowInDown />
                          </span>
                        </button>
                      </a>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              ))}
            <Pagination pageCount={pageCount} changePage={changePage} />
          </>
        ) : (
          <p className="no-record">{t('noRecordMatch')}</p>
        )}
      </div>
    </div>
  );
}

export default withRouter(ViewEachDocumentBank);
